import { User, user_interface } from '../User';
import * as moment from 'moment';

export interface DepartmentType {
  vehicle: string;
  department: string;
  clockInCheck: string;
  clockOutCheck: string;
  state: string;
  shiftDefinition: string;
  regional: string;
  paid: string;
  checked: string;
  tolls: string;
  actions: string;
  isChecked: boolean;
  isVisible: boolean;
}

export interface JobShiftOfferResponse {
  id: number;
  shift_id?: number;
  humanforce_id?: string;
  assignee_id?: number;
  date: string;
  start_time: string;
  start_time_anywhere: string;
  state: string;
  department: string;
  vehicles: string[];
  allocated_vehicle: string;
  interested_drivers: InterestedDriver[];
  clocked_in: boolean;
  created_at: string;
  attachment?: string;
  allocated_at?: string;
  users?: User;
  //   job_history: ShiftDataType;
  updated_at: string;
  job_comments?: string;
  charging_comments?: string;
}

export interface InterestedDriver {
  id: number;
  name: string;
  lname: string;
  vehicle: string;
}

export interface OfferFormat {
  id: number;
  date: string;
  start_time: string;
  start_date_time: Date;
  state: string;
  department: string;
  vehicles: string[];
  allocated_vehicle: string;
  start_time_anywhere: string;
  attachment?: string;
  job_comments?: string;
  charging_comments?: string;
}

export interface SortObj {
  date: boolean;
  start_time: boolean;
  state: boolean;
  department: boolean;
  vehicles: boolean;
  assignedTo: boolean;
  assignTo: boolean;
}

export interface Timzone {
  state: string;
  timezone: string;
}

export class JobShiftOffer {
  public id: number = 0;
  public shift_id: number = 0;
  public humanforce_id: string = '';
  public assignee_id: number = 0;
  public date: string = '';
  public start_time: string = '';
  public start_time_anywhere: string = '';
  public start_date_time: Date = new Date();
  public state: string = '';
  public department: string = '';
  public vehicles: string[] = [];
  public allocated_vehicle: string = '';
  public interested_drivers: InterestedDriver[] = [];
  public created_at: string = '';
  public allocated_at: string = '';
  public clocked_in: boolean = false;
  public assignee: string = '';
  public job_comments: string = '';
  public charging_comments: string = '';
  public attachment: string = '';

  public map(data: JobShiftOfferResponse) {
    this.id = data.id;

    // this.date = data?.date
    //   ? moment.utc(new Date(data.date)).local().format('DD/MM/YY')
    //   : '';
    // this.start_time = data?.start_time
    //   ? moment.utc(new Date(data.start_time)).local().format('h:mm A')
    //   : '';
    this.date = data?.start_time_anywhere
      ? moment(data.start_time_anywhere).format('DD/MM/YY')
      : '';
    this.start_time = data?.start_time_anywhere
      ? moment(data.start_time_anywhere).format('h:mm A')
      : '';
    this.start_date_time = new Date(data.start_time);
    this.state = data.state;
    this.department = data.department;
    this.vehicles = data.vehicles;
    this.allocated_vehicle = data.allocated_vehicle;

    this.job_comments = data?.job_comments ?? '';
    this.charging_comments = data?.charging_comments ?? '';
    this.clocked_in = data.clocked_in;
    this.start_time_anywhere = data.start_time_anywhere;
    this.attachment = data?.attachment ?? '';
    this.interested_drivers = data.interested_drivers ?? [];
    this.assignee = `${data.users?.name ?? ''} ${data.users?.lname ?? ''}`;
    return this;
  }

  public static create(data: JobShiftOfferResponse) {
    return new this().map(data);
  }
}

export const DriverPaymentModel = [
  { v: 'ContactName' },
  { v: 'DriverName' },
  { v: 'InvoiceNumber' },
  { v: 'InvoiceDate' },
  { v: 'DueDate' },
  { v: 'Description' },
  { v: 'Quantity' },
  { v: 'UnitAmount' },
  { v: 'AccountCode' },
  { v: 'TaxType' },
  { v: 'Currency' },
  { v: 'TrackingName' },
  { v: 'TrackingOption' }
];

export const topBorder = {
  top: { style: "thin", color: "black"}, bottom: { style: "thin", color: "black"}, left: { style: "thin", color: "black"}, right: { style: "thin", color: "black"}
}

export const dataBorder = {
  top: { style: "thin", color: "black"}, bottom: { style: "thin", color: "black"}, left: { style: "thin", color: "black"}, right: { style: "thin", color: "black"}
}

export const DriverPaymentModelTitle = [
  { v: `Processed Payments  ${moment().format('YYYY-MM-DD HH:mm:ss')}`,
    s: {
      font: {
        sz: 15,
      },
      border: topBorder,
      alignment: {
        vertical: "center",
        horizontal: "center"
      }
    }
   },
  { v: '', s: { border: topBorder } },
  { v: '', s: { border: topBorder } },
  { v: '', s: { border: topBorder } },
  { v: '', s: { border: topBorder } },
  { v: '', s: { border: topBorder } },
  { v: '', s: { border: topBorder } },
  { v: '', s: { border: topBorder } },
  { v: '', s: { border: topBorder } },
  { v: '', s: { border: topBorder } },
  { v: '', s: { border: topBorder } },
  { v: '', s: { border: topBorder } },
  { v: '', s: { border: topBorder } }
];
