import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import _ from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CodedResponseModel } from 'src/app/model/CodedResponseModel';
import { Client, OperationContactType } from 'src/app/model/custm/Client';
import { MappingDataType } from 'src/app/model/custm/Setting';
import { LoaderPartial } from 'src/app/partials/loader/loader.component';
import { NotificatorPartial } from 'src/app/partials/notificator/notificator.component';
import { ClientAPIService } from 'src/app/services/custm/client.service';
import { LoginService } from 'src/app/services/login.service';
import { apiUrls } from 'src/app/settings/settings';

type hourstype =
  | 'dediTermsFormArray'
  | 'flexiTermsFormArray'
  | 'setRunTermsFormArray';

@Component({
  selector: 'app-client-form',
  templateUrl: './client-form.component.html',
  styleUrls: ['./client-form.component.scss'],
})
export class ClientFormComponent implements OnInit {
  @ViewChild('details') detailsElementRef!: ElementRef;
  @ViewChild('charging_terms') chargingTermsRef!: ElementRef;

  public client: Client = new Client();

  public editMode: boolean = false;

  public states!: MappingDataType[];
  public departments!: MappingDataType[];
  public _states!: MappingDataType[];
  public _departments!: MappingDataType[];
  public vehicles: MappingDataType[] = [];

  public clientCreationForm!: FormGroup;
  public searchCooldown!: any;

  public focusedIndex!: number;

  public isAdded = { dedi: false, flexi: false, setRun: false };

  public CHARGING_TERMS_TEXTS = {
    regional_charge: 'Regional Charge',
    weekend_loading: 'Weekend Loading',
    _weekend_loading: '_Weekend Loading',
    public_holidays: 'Public Holidays',
    tailgate: 'Tailgate',
    after_hours: 'After Hours',
  };

  public CHARGING_TERMS_CALLAPSE = {
    dedi: {
      regional_charge: true,
      weekend_loading: true,
      public_holidays: true,
      tailgate: true,
      after_hours: true,
    },
    flexi: {
      regional_charge: true,
      weekend_loading: true,
      public_holidays: true,
      tailgate: true,
      after_hours: true,
    },
    setRun: {
      regional_charge: true,
      weekend_loading: true,
      public_holidays: true,
      tailgate: true,
      after_hours: true,
    },
  };

  SIDEBAR_COLLAPSE = {
    details: false,
    chargingTerms: false,
    documents: false
  };

  selected = '';

  public AFTER_HOURS = {
    dediTermsFormArray: {
      from: '06:00 pm',
      to: '04:00 am',
    },
    flexiTermsFormArray: {
      from: '06:00 pm',
      to: '04:00 am',
    },
    setRunTermsFormArray: {
      from: '06:00 pm',
      to: '04:00 am',
    },
  };

  public CHARGING_TERMS = Object.keys(this.CHARGING_TERMS_TEXTS);

  forRemoveAttachments: any[] = [];
  public apiUrls = apiUrls;
  allowedFileUploadFormat = ["jpg","docx","pdf"];
  constructor(
    private clientApi: ClientAPIService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public authService: LoginService
  ) {
    // If this form is opened to edit a client, it initialize with saved data.
    this.route.params.subscribe((p) => {
      if (p.id) {
        this.editMode = true;

        LoaderPartial.show(true);
        this.clientApi.show(p.id).subscribe(
          (r) => {
            let res = CodedResponseModel.decode(r);
            this.client = Client.mapUser(res);

            this.initializeForm();
            if (this.client.chargingTermsCollapse ?? {})
              this.CHARGING_TERMS_CALLAPSE = this.client.chargingTermsCollapse;
            // this.addChargingTerms(
            //   this?.client?.chargingTerms ?? [],
            //   'dediTermsFormArray'
            // );
            this.vehicles = this.client?.vehicles ?? [];

            this.client.emails.forEach((item, itemIndex) => {
              if (Boolean(item)) {
                if (itemIndex === 0) this.addExtraEmail(item, true);
                else this.addExtraEmail(item);
              }
              return true;
            });

            this.client.operation_contacts.forEach((item) => {
              this.addOperationContact(item);
              return true;
            });

            this.addMinmums('dedi_minimums', this.client?.dedi?.dedi_minimums);
            this.addChargingTerms(
              'dediTermsFormArray',
              this.client?.dedi?.dedi_terms
            );
            this.addCurrencyField(
              'dediProductFormArray',
              this.client?.dedi?.dedi_product
            );

            this.addMinmums(
              'flexi_minimums',
              this.client?.flexi?.flexi_minimums
            );
            this.addChargingTerms(
              'flexiTermsFormArray',
              this.client?.flexi?.flexi_terms
            );
            this.addCurrencyField(
              'flexiProductFormArray',
              this.client?.flexi?.flexi_product
            );

            this.addChargingTerms(
              'setRunTermsFormArray',
              this.client?.setRun?.setRun_terms
            );
            this.addCurrencyField(
              'setRunProductFormArray',
              this.client?.setRun?.setRun_product
            );
            this.addAttachmentsField(this.client?.attachments ?? []);
            const found = this._departments?.find(
              (item) => item.name === this.client.name
            );
            if (found?.id)
              this.clientApi.getStates(found.id).subscribe((res) => {
                this.states = res.data;
                this._states = _.cloneDeep(this.states);
              });
            this.detectFormValueChanges();
            LoaderPartial.show(false);
          },
          (err) => {
            NotificatorPartial.push({
              type: 'error',
              message: err.error.message,
              details: err.error.message,
              dismissable: true,
            });
          }
        );
      }
    });
  }

  ngOnInit(): void {
    this.initializeForm();
    if (!this.editMode) {
      this.detectFormValueChanges();
    }
    this.clientApi.getDepartments().subscribe((res) => {
      this.departments = res.data;
      this._departments = _.cloneDeep(this.departments);
    });
  }

  initializeForm() {
    // Set up the client form with initialisation data
    this.clientCreationForm = this.formBuilder.group({
      name: [this.client?.department?.name ?? '', [Validators.required]],
      forced_pod: [this.client?.forced_pod ?? 0],
      account_code: [
        this.client?.account_code ?? '',
        [Validators.required, this.accountCodeValidator],
      ],
      invoice_code: [
        this.client?.invoice_code ?? '',
        [Validators.required, this.invoiceCodeValidator],
      ],
      point_of_contact: [
        this.client?.point_of_contact ?? '',
        [Validators.required],
      ],
      emails: this.formBuilder.array([]),
      operation_contacts: this.formBuilder.array([]),
      contact_number: [
        this.client?.contact_number ?? '',
        [Validators.required],
      ],
      address: [this.client?.address ?? '', [Validators.required]],
      suburb: [this.client?.suburb ?? '', [Validators.required]],
      postcode: [this.client?.postcode ?? '', [Validators.required]],
      state: [this.client?.location?.name ?? '', [Validators.required]],
      depot_lat: [this.client?.depot_lat ?? '', [Validators.required]],
      depot_lng: [this.client?.depot_lng ?? '', [Validators.required]],
      entity_name: [this.client?.entity_name ?? ''],
      invoice_prefix: [this.client?.invoice_prefix ?? ''],
      payment_terms: [this.client?.payment_terms ?? 0, [Validators.required]],

      // ! changed logic

      charging_dedi: [this.client?.dedi?.charging_dedi],
      charging_flexi: [this.client?.flexi?.charging_flexi],
      charging_setrun: [this.client?.setRun?.charging_setrun],

      // !

      dedi_minimums: this.formBuilder.group({}),
      flexi_minimums: this.formBuilder.group({}),

      dedi_product: this.formBuilder.array([]),
      dedi_notes: [this.client?.dedi_notes ?? ''],
      flexi_product: this.formBuilder.array([]),
      flexi_notes: [this.client?.flexi_notes ?? ''],
      setRun_product: this.formBuilder.array([]),
      setrun_notes: [this.client?.setrun_notes ?? ''],

      dedi_terms: this.formBuilder.array([]),
      flexi_terms: this.formBuilder.array([]),
      setRun_terms: this.formBuilder.array([]),

      attachments: this.formBuilder.array([])
    });


  }

  // Set up get methods especially for FormArrays to interact easily.
  get emailsFormArray(): FormArray {
    return this.clientCreationForm.get('emails') as FormArray;
  }
  get dediProductFormArray(): FormArray {
    return this.clientCreationForm.get('dedi_product') as FormArray;
  }
  get flexiProductFormArray(): FormArray {
    return this.clientCreationForm.get('flexi_product') as FormArray;
  }
  get setRunProductFormArray(): FormArray {
    return this.clientCreationForm.get('setRun_product') as FormArray;
  }

  get dediTermsFormArray(): FormArray {
    return this.clientCreationForm.get('dedi_terms') as FormArray;
  }
  get flexiTermsFormArray(): FormArray {
    return this.clientCreationForm.get('flexi_terms') as FormArray;
  }
  get setRunTermsFormArray(): FormArray {
    return this.clientCreationForm.get('setRun_terms') as FormArray;
  }
  get operationContactsFormArray(): FormArray {
    return this.clientCreationForm.get('operation_contacts') as FormArray;
  }
  get attachmentsFormArray(): FormArray {
    return this.clientCreationForm.get('attachments') as FormArray;
  }

  // Add Sprint and Marathon formControl for given FormGroup Name
  addMinmums(keyValue: string, values?: any) {
    (this.clientCreationForm.get(keyValue) as FormGroup).addControl(
      'sprint',
      this.formBuilder.control(values?.sprint ?? '0', [Validators.required])
    );
    (this.clientCreationForm.get(keyValue) as FormGroup).addControl(
      'driver_sprint',
      this.formBuilder.control(values?.driver_sprint ?? '0', [Validators.required])
    );

    (this.clientCreationForm.get(keyValue) as FormGroup).addControl(
      'marathon',
      this.formBuilder.control(values?.marathon ?? '0', [Validators.required])
    );
    (this.clientCreationForm.get(keyValue) as FormGroup).addControl(
      'driver_marathon',
      this.formBuilder.control(values?.driver_marathon ?? '0', [Validators.required])
    );
  }

  // Add Payrates to corresponding Roles for given Charging Terms by keyValue
  addCurrencyField(keyValue: string, data?: any[]): void {
    const products = data ?? this.vehicles;

    this.vehicles.forEach((item) => {
      const vehicle =
        products.find((_item) => _item?.role_id === item.id) ?? item;
      const currencyControl = this.formBuilder.group({
        role_id: item.id,
        role: item.name,
        rate: [vehicle?.rate ?? '0', [Validators.required]],
        fuel: [vehicle?.fuel ?? '0', [Validators.required]],
        ...(keyValue === 'flexiProductFormArray'
          ? {
              rate2: [vehicle?.rate2 ?? '0', [Validators.required]],
            }
          : {}),
      });

      (this?.[keyValue as keyof this] as unknown as FormArray)?.push(
        currencyControl
      );
      return true;
    });
  }

  // Add Charging Terms like percentage and fixed rate for Given option with keyValue
  addChargingTerms(keyValue: string, data?: any[]) {
    const arr = data ?? this.CHARGING_TERMS;
    arr.forEach((item: any, itemIndex: number) => {
      if (itemIndex === arr.length - 1) {
        this.AFTER_HOURS[keyValue as hourstype].from = item?.from ?? '06:00 pm';
        this.AFTER_HOURS[keyValue as hourstype].to = item?.to ?? '04:00 am';
      }

      const chargingTermControl = this.formBuilder.group({
        percentage: [item?.percentage ?? null],
        fixed_rate: [item?.fixed_rate ?? null],
        ...(itemIndex === arr.length - 1
          ? {
              from: [item?.from ?? '06:00 pm'],
              to: [item?.to ?? '04:00 am'],
            }
          : {}),
      });

      const pControl = chargingTermControl.get('percentage');
      const fControl = chargingTermControl.get('fixed_rate');

      pControl?.valueChanges.subscribe((value) => {
        if (value) {
          fControl?.setValue(null, { emitEvent: false });
          fControl?.disable({ emitEvent: false });
        } else {
          fControl?.enable({ emitEvent: false });
        }
      });

      fControl?.valueChanges.subscribe((value) => {
        if (value) {
          pControl?.setValue(null, { emitEvent: false });
          pControl?.disable({ emitEvent: false });
        } else {
          pControl?.enable({ emitEvent: false });
        }
      });

      (this?.[keyValue as keyof this] as unknown as FormArray)?.push(
        chargingTermControl
      );
    });
  }

  IsSetTerm(keyValue: string) {
    return this.clientCreationForm.get(keyValue)?.value;
  }

  GetLabel(term: string) {
    return (this.CHARGING_TERMS_TEXTS as unknown as any)[term];
  }

  IsViewable(keyValue: string) {
    return (
      (this?.[keyValue as keyof this] as unknown as FormArray)
        .at(5)
        .get('percentage')?.value !== null ||
      (this?.[keyValue as keyof this] as unknown as FormArray)
        .at(5)
        .get('fixed_rate')?.value !== null
    );
  }

  IsCollapsed(keyValue: string, term: string) {
    return (this.CHARGING_TERMS_CALLAPSE as unknown as any)[keyValue][term];
  }

  handleCollapse(keyValue: string, term: string) {
    (this.CHARGING_TERMS_CALLAPSE as unknown as any)[keyValue][term] = !(
      this.CHARGING_TERMS_CALLAPSE as unknown as any
    )[keyValue][term];
  }

  handleSidebarCollapse(keyValue: 'chargingTerms' | 'details' | 'documents') {
    this.SIDEBAR_COLLAPSE[keyValue] = !this.SIDEBAR_COLLAPSE[keyValue];
    this.selected = keyValue;
  }

  handleInvalidCheck() {
    this.clientCreationForm.get('name')?.markAsTouched();
    this.clientCreationForm.get('state')?.markAsTouched();
  }

  public handleFromTimeChange(keyValue: string, e: any) {
    (this?.[keyValue as keyof this] as unknown as FormArray)
      .at(5)
      .get('from')
      ?.setValue(this.AFTER_HOURS[keyValue as hourstype].from);
  }

  public handleToTimeChange(keyValue: string, e: any) {
    (this?.[keyValue as keyof this] as unknown as FormArray)
      .at(5)
      .get('to')
      ?.setValue(this.AFTER_HOURS[keyValue as hourstype].to);
  }

  // Handling actions when some of form fields change.
  detectFormValueChanges() {
    this.clientCreationForm.get('name')?.valueChanges.subscribe((value) => {
      this.clientCreationForm.get('state')?.setValue('');
      // this.clientCreationForm.get('vehicle')?.setValue('');
      if (this.searchCooldown) clearTimeout(this.searchCooldown);
      this.searchCooldown = setTimeout(() => {
        if (value) {
          const found = this._departments.find((item) => item.name === value);
          if (found?.id)
            this.clientApi.getStates(found.id).subscribe((res) => {
              this.states = res.data;
              this._states = _.cloneDeep(this.states);
            });
        }
      }, 500);
    });

    this.clientCreationForm.get('state')?.valueChanges.subscribe((value) => {
      const department = this.clientCreationForm.get('name')?.value;
      if (this.searchCooldown) clearTimeout(this.searchCooldown);
      this.searchCooldown = setTimeout(() => {
        if (value && department) {
          const found = this._departments?.find(
            (item) => item.name === department
          );
          const _found = this._states?.find((item) => item.name === value);
          if (!!found?.id && !!_found?.id)
            this.clientApi.getVehicles(_found.id, found.id).subscribe((r) => {
              this.vehicles = r?.data ?? [];
            });
        }
      }, 500);
    });

    if (!this.editMode) {
      this.clientCreationForm
        .get('charging_dedi')
        ?.valueChanges.subscribe((value) => {
          if (value && !this.isAdded.dedi) {
            this.isAdded.dedi = true;
            this.addMinmums('dedi_minimums');
            this.addChargingTerms('dediTermsFormArray', this.CHARGING_TERMS);
            this.addCurrencyField('dediProductFormArray', this.vehicles);
          }
        });
      this.clientCreationForm
        .get('charging_flexi')
        ?.valueChanges.subscribe((value) => {
          if (value && !this.isAdded.flexi) {
            this.isAdded.flexi = true;
            this.addMinmums('flexi_minimums');
            this.addChargingTerms('flexiTermsFormArray', this.CHARGING_TERMS);
            this.addCurrencyField('flexiProductFormArray', this.vehicles);
          }
        });
      this.clientCreationForm
        .get('charging_setrun')
        ?.valueChanges.subscribe((value) => {
          if (value && !this.isAdded.setRun) {
            this.isAdded.setRun = true;
            this.addChargingTerms('setRunTermsFormArray', this.CHARGING_TERMS);
            this.addCurrencyField('setRunProductFormArray', this.vehicles);
          }
        });
      this.emailsFormArray.push(
        this.formBuilder.group({
          email: ['', [Validators.required, Validators.email]],
        })
      );
    }
  }

  isInvalid(index: number): boolean {
    const control = this.dediProductFormArray.at(index);
    return control.invalid && (control.dirty || control.touched);
  }

  scrollToDetails(): void {
    const element = this.detailsElementRef.nativeElement;
    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  scrollToChargingTerms(): void {
    const element = this.chargingTermsRef.nativeElement;
    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  isControlInValidInGroup(
    formArray: FormArray,
    groupIndex: number,
    controlName: string
  ): boolean {
    const formGroup = formArray.at(groupIndex) as FormGroup;
    const control = formGroup.get(controlName);
    return (
      (control?.invalid ?? false) &&
      ((control?.dirty ?? false) || (control?.touched ?? false))
    );
  }

  isInvalidControl(controlName: string): boolean {
    const control = this.clientCreationForm.get(controlName) as FormControl;
    return control.invalid && (control.dirty || control.touched);
  }

  // Mark formControls as touched for validation
  markControlsAsTouched(): void {
    Object.values(this.clientCreationForm.controls).forEach((control) => {
      control.markAsTouched();
    });

    if (this.dediProductFormArray.controls?.length)
      this.dediProductFormArray.controls.forEach((formGroup) => {
        this.markGroupAsTouched(formGroup as FormGroup);
      });

    if (this.dediTermsFormArray.controls?.length)
      this.dediTermsFormArray.controls.forEach((formGroup) => {
        this.markGroupAsTouched(formGroup as FormGroup);
      });

    if (this.operationContactsFormArray.controls?.length)
      this.operationContactsFormArray.controls.forEach((formGroup) => {
        this.markGroupAsTouched(formGroup as FormGroup);
      });
  }

  // Mark formGroup as touched for validation
  markGroupAsTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      control.markAsTouched();
    });
  }
  getInvalidFormControls(): string[] {
    const invalidFormControls: string[] = [];
    const formControls = this.clientCreationForm.controls;

    for (const controlName in formControls) {
      if (formControls.hasOwnProperty(controlName)) {
        const control = formControls[controlName];
        if (control.invalid) {
          invalidFormControls.push(controlName);
        }
      }
    }

    return invalidFormControls;
  }

  public addExtraEmail(value = '', isRequired = false) {
    if (isRequired)
      this.emailsFormArray.push(
        this.formBuilder.group({
          email: [value, [Validators.required, Validators.email]],
        })
      );
    else
      this.emailsFormArray.push(
        this.formBuilder.group({
          email: [value],
        })
      );
  }

  public deleteExtraEmail(i: number) {
    this.emailsFormArray.removeAt(i);
  }

  public addOperationContact(value = {} as OperationContactType) {
    this.operationContactsFormArray.push(
      this.formBuilder.group({
        point_of_contact: [
          value?.point_of_contact ?? '',
          [Validators.required],
        ],
        position: [value?.position ?? ''],
        contact_number: [value?.contact_number ?? ''],
        email: [value?.email ?? '', [Validators.required, Validators.email]],
        send_booking: [value?.send_booking ?? false],
      })
    );
  }

  accountCodeValidator(
    control: FormControl
  ): { [key: string]: boolean } | null {
    const value = control.value as string;
    if (value && ['3', '4', '5'].includes(value[0])) {
      return null; // Valid
    } else {
      return { startsWith: true }; // Invalid
    }
  }
  invoiceCodeValidator(
    control: FormControl
  ): { [key: string]: boolean } | null {
    const value = control.value as string;
    if (value && value.startsWith('2')) {
      return null; // Valid
    } else {
      return { startsWith: true }; // Invalid
    }
  }

  public deleteOperationContact(i: number) {
    this.operationContactsFormArray.removeAt(i);
  }

  public save() {
    const formValues = this.clientCreationForm.value;
    if (
      this.clientCreationForm.valid &&
      (formValues.charging_dedi ||
        formValues.charging_flexi ||
        formValues.charging_setrun)
    ) {
      const department = this?._departments?.find(
        (item) => item.name === formValues.name
      );
      const state = this._states?.find(
        (item) => item.name === formValues.state
      );
      const formData = {
        ...formValues,
        ...(!this.editMode
          ? { department_id: department?.id, location_id: state?.id }
          : {}),
        emails: formValues.emails.map((item: any) => item.email),
        vehicles: this.vehicles.map((item) => item.id),
        chargingTermsCollapse: this.CHARGING_TERMS_CALLAPSE,
        dedi: {
          charging_dedi: formValues.charging_dedi,
          dedi_minimums: formValues.charging_dedi
            ? formValues.dedi_minimums
            : null,
          dedi_product: formValues.charging_dedi
            ? formValues.dedi_product
            : null,
          dedi_terms: formValues.charging_dedi ? formValues.dedi_terms : null,
        },
        flexi: {
          charging_flexi: formValues.charging_flexi,
          flexi_minimums: formValues.charging_flexi
            ? formValues.flexi_minimums
            : null,
          flexi_product: formValues.charging_flexi
            ? formValues.flexi_product
            : null,
          flexi_terms: formValues.charging_flexi
            ? formValues.flexi_terms
            : null,
        },
        setRun: {
          charging_setrun: formValues.charging_setrun,
          setRun_product: formValues.charging_setrun
            ? formValues.setRun_product
            : null,
          setRun_terms: formValues.charging_setrun
            ? formValues.setRun_terms
            : null,
        },
        attachments: formValues.attachments
      };
      
      /* New edit form data */
      const editFormData = new FormData();
      Object.keys(formData).forEach((key) => {
        if (key == 'attachments') {
          let fileInfo = [];
          let newFileInfo = [];
          for (let i=0;i<formValues.attachments.length;i++) {
            const fileName = typeof formValues.attachments[i].isNew !== 'undefined' && formValues.attachments[i].isNew ? formValues.attachments[i].new_file : formValues.attachments[i].file;
            let attachmentInfo = {
              title: formValues.attachments[i].title,
              notes: formValues.attachments[i].notes,
              file: fileName,
              path: formValues.attachments[i].path ?? ""
            };
            if (fileName == "") {
              continue;
            }
            if (typeof formValues.attachments[i].new_file !== 'undefined' && formValues.attachments[i].new_file) {
              editFormData.append('new_file_attachments[]', formValues.attachments[i].new_file);
              newFileInfo.push(attachmentInfo);
            } else {
              fileInfo.push(attachmentInfo);
            }
          }
          editFormData.append(key, JSON.stringify(fileInfo));
          editFormData.append('new_file_info', JSON.stringify(newFileInfo));
        } else {
          formData[key] = (key == 'force_pod') ? Number(formData[key]) : formData[key];
          const value = formData[key] instanceof Array || formData[key] instanceof Object ? JSON.stringify(formData[key]) : formData[key];
          editFormData.append(key, value);
        }
      });
      editFormData.append('for_delete_attachments', JSON.stringify(this.forRemoveAttachments));
      editFormData.append('_method', 'PUT');
      if (this.editMode) {
        this.clientApi.edit(editFormData as any, this.client.id).subscribe(
          (r) => {
            let res = CodedResponseModel.decode(r);

            NotificatorPartial.push({
              type: 'success',
              message: 'Client has been updated',
              timeout: 3000,
            });
            this.router.navigate(['/clients']);
          },
          (err) => {
            NotificatorPartial.push({
              type: 'error',
              message: err.error.message,
              details: err.error.message,
              dismissable: true,
            });
          }
        );
      } else {
        this.clientApi.create(formData as Client).subscribe(
          (r) => {
            let res = CodedResponseModel.decode(r);

            NotificatorPartial.push({
              type: 'success',
              message: 'The client has been created',
              timeout: 3000,
            });
            this.router.navigate(['/clients']);
          },
          (err) => {
            NotificatorPartial.push({
              type: 'error',
              message: err.error.message,
              details: err.error.message,
              dismissable: true,
            });
          }
        );
      }
    } else {
      this.markControlsAsTouched();
      [
        { label: 'Name', key: 'name' },
        { label: 'Point Of Contact', key: 'point_of_contact' },
        { label: 'Contact Number', key: 'contact_number' },
        { label: 'Address', key: 'address' },
        { label: 'Suburb', key: 'suburb' },
        { label: 'Postcode', key: 'postcode' },
        { label: 'State', key: 'state' },
        { label: 'Depot Lat', key: 'depot_lat' },
        { label: 'Depot Lng', key: 'depot_lng' },
        { label: 'Address', key: 'address' },
        { label: 'Cost Account Code', key: 'account_code' },
        { label: 'Invoice Code', key: 'invoice_code' },
        { label: 'Payment Terms (days)', key: 'payment_terms' },
      ].forEach((item) => {
        if (this.isInvalidControl(item.key)) {
          NotificatorPartial.push({
            type: 'error',
            message: `"${item.label}" field is invalid`,
            timeout: 3000,
          });
        }
        return true;
      });
      let isInvalid = false;
      this.operationContactsFormArray.controls.forEach((item, index) => {
        ['point_of_contact', 'email'].forEach((subItem) => {
          isInvalid =
            isInvalid ||
            this.isControlInValidInGroup(
              this.operationContactsFormArray,
              index,
              subItem
            );
        });
        return true;
      });
      if (isInvalid)
        NotificatorPartial.push({
          type: 'error',
          message: `At least one of "Operation Contacts" field is invalid`,
          timeout: 3000,
        });
    }
  }

  uploadFile($event: any, index: any): void {
    $event.target.parentElement.querySelector('.doc_file').click();
  }

  dynamicAddDocumentUpload (): void {
    const attachments = this.attachmentsFormArray;
    attachments.push(this.formBuilder.group({
      file: '',
      title: '',
      notes: '',
      new_file: '',
      isNew: true
    }));
    
  }

  handleUploadFile (event: any, index: any): void {
    const file: File = event.target.files[0];
    const fileType = (file.name.split('.')).pop();
    if ((fileType && this.allowedFileUploadFormat.indexOf(fileType) < 0) || fileType == "") {
      NotificatorPartial.push({
        type: 'error',
        message: 'Invalid file format',
        dismissable: true
      });
      event.target.value = '';
      return;
    }
    this.attachmentsFormArray.at(index).value.new_file = file;
    this.attachmentsFormArray.at(index).patchValue({new_file: file});
    this.attachmentsFormArray.at(index).patchValue({file: file.name});
  }

  onRemoveAttachments (index: number, isNew: boolean) {
    if (!isNew) {
      const data = this.attachmentsFormArray.at(index).value.path;
      this.attachmentsFormArray.removeAt(index);
      this.forRemoveAttachments.push(data);
    } else {
      this.attachmentsFormArray.removeAt(index);
    }
  }

  addAttachmentsField (attachmentData: any[] = []) {
    const attachments = this.attachmentsFormArray;
    if (attachmentData.length > 0) {
      attachmentData.forEach((attachment: any, index: number) => {
        attachments.push(this.formBuilder.group({
          file: attachment.file,
          title: attachment.title,
          notes: attachment.notes,
          path: attachment.path
        }));
      });
    }
  }
}
