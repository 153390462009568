import * as moment from 'moment';

export const EXPORT_MODEL = [
  { v: 'Consignment Number' },
  { v: 'Manifest ID' },
  { v: 'Pickup State' },
  { v: 'Despatch Date' },
  { v: 'Pickup Location' },
  { v: 'Dropoff Address' },
  { v: 'ATL' },
  { v: 'Total Price' },
  { v: 'Service Names' },
  { v: 'Driver Name' },
  { v: 'Vehicle Type' },
  { v: 'Dropoff Contact Name' },
  { v: 'Dropoff Contact Phone' },
  { v: 'Dropoff Contact Email' },
];

export const COLUMN_WIDTH_DEFS = [
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
];

export const EXPORT_COLUMNS: (keyof JobShiftFlexiOffer)[] = [
  'consignment_number',
  'manifest_id',
  'pickup_header_state',
  'despatch_date_local',
  'pickup_header_name_and_location',
  'dropoff_full_address',
  'ATL',
  'total_price',
  'service_names',
  'driver_name',
  'vehicle_type_name',
  'dropoff_contact_name',
  'dropoff_contact_phone',
  'dropoff_contact_email',
];

export interface DepartmentType {
  vehicle: string;
  department: string;
  status: string;
  clockInCheck: string;
  clockOutCheck: string;
  state: string;
  shiftDefinition: string;
  regional: string;
  paid: string;
  checked: string;
  tolls: string;
  actions: string;
  isChecked: boolean;
  isVisible: boolean;
}

export interface OfferFormat {
  id: number;
  date: string;
  start_time: string;
  start_date_time: Date;
  state: string;
  department: string;
  vehicles: string[];
  allocated_vehicle: string;
  start_time_anywhere: string;
  attachment?: string;
  job_comments?: string;
  charging_comments?: string;
}

export interface SortObj {
  date: boolean;
  start_time: boolean;
  state: boolean;
  department: boolean;
  vehicles: boolean;
  assignedTo: boolean;
  assignTo: boolean;
}

export interface Timzone {
  state: string;
  timezone: string;
}


export interface InterestedflexiDriver {
  driver_id: number;
  driver_name: string;
  driver_phone: string;
  vehicle_type_id: number;
  vehicle_type_name: string;
}

export interface JobShiftFlexiOfferResponse {
  flexi_job_header_id: number;
  interested_drivers: InterestedflexiDriver[];
  total_price: any;
  ATL: string;
  dropoff_full_address: string;
  dropoff_contact_name: string;
  dropoff_contact_phone: string;
  dropoff_contact_email: string;
  pickup_header_state: string;
  pickup_header_name_and_location: string;
  pickup_full_address: string;
  despatch_date_local: any;
  manifest_id: number;
  consignment_number: any;
  service_names: any;
  pod_receiver_name: string;
  pod_signature: any;
  pod_photo: any;
}

export class JobShiftFlexiOffer {
  public flexi_job_details_id: number = 0;
  public flexi_job_header_id  : number = 0;
  public consignment_number: any ;
  public carrier_consignment_id: any ;
  public consignment_id: any ;
  public manifest_id  : number = 0;
  public pickup_header_state: string = '';
  public despatch_date_local: any ;
  public time: string = '';
  public company_name: string = '';
  public pickup_header_name_and_location: string = '';
  public dropoff_full_address: string = '';
  public pickup_full_address: string = '';
  public ATL : string = '';
  public total_price  : any ;
  public total_sell_price: any ;
  public service_names: string = '';
  public vehicles: string[] = [];
  public allocated_vehicle: string = '';
  public interested_drivers: InterestedflexiDriver[] = [];
  public items: any[] = [];
  public created_at: string = '';
  public allocated_at: string = '';
  public clocked_in: boolean = false;
  public assignee: string = '';
  public job_comments: string = '';
  public charging_comments: string = '';
  public attachment: string = '';
  public pickup_contact_name: string = '';
  public pickup_contact_phone: string = '';
  public pickup_contact_email: string = '';
  public dropoff_contact_name: string = '';
  public dropoff_contact_phone: string = '';
  public dropoff_contact_email: string = '';
  public vehicle_type_name: string = '';
  public status_name: string = '';
  public driver_name: string = '';
  public driver_phone: string = '';
  public pod_receiver_name: string = '';
  public pod_photo: any = [];
  public pod_signature: any = [];
  public trip_started_at: string = '';
  public picked_up_at: string = '';
  public in_transit_at: string = '';
  public completed_date: string = '';
  public total_cost_price: any = '';
  public comment: string = '';
  public comment_by: any = {};
  public commented_by: any = {};
  public cancelled_by: any = {};
  public completed_by: any = {}

  public map(data: JobShiftFlexiOfferResponse, toExport = false) {
    this.flexi_job_header_id = data.flexi_job_header_id;
    this.consignment_number = data.consignment_number;
    this.manifest_id = data.manifest_id;
    this.despatch_date_local = data?.despatch_date_local
        ? moment(data.despatch_date_local).format('DD/MM/YY')
        : '';
    this.time = data?.despatch_date_local
        ? moment(data.despatch_date_local).format('h:mm A')
        : '';
    this.pickup_header_state = data.pickup_header_state;
    this.pickup_header_name_and_location = data.pickup_header_name_and_location;
    this.dropoff_full_address = data.dropoff_full_address;
    this.pickup_full_address = data.pickup_full_address;
    this.dropoff_contact_name = data.dropoff_contact_name;
    this.dropoff_contact_phone = data.dropoff_contact_phone;
    this.dropoff_contact_email = data.dropoff_contact_email;
    this.pod_receiver_name = data.pod_receiver_name ?? '';
    this.pod_photo = data.pod_photo ?? [];
    this.pod_signature = data.pod_signature ?? [];
    this.ATL = data.ATL;
    this.total_price = data.total_price;
    this.interested_drivers = data.interested_drivers ?? [];

    return this;
  }

  public static create(data: JobShiftFlexiOfferResponse) {
    return new this().map(data);
  }
}
