<div class='w-full shift-offer-height max-h-screen flex flex-col'>
     <div class="offers_drawer" [ngClass]="{
        drawer_appear: isExpanded.drawer,
        drawer_disappear: !isExpanded.drawer
      }">
        <div class="offers_drawer_content">
            <div class="offers_drawer_header">
                <div>
                    <span>Filters</span>
                    <span (click)="handleVisivility('drawer', false)">
                        <img src="/assets/icons/x-close.svg" alt="search-icon" /></span>
                </div>
                <span>Select filters to refine data.</span>
            </div>
            <div class="offers_drawer_body scroll-thin-style">
                <div class="offers_states scroll-thin-style">
                    <div class="offers_state_header" (click)="handleVisivility('state')">
                        <ng-container *ngIf="isExpanded.state">
                            <img src="/assets/icons/chevron-down.svg" alt="search-icon" />
                        </ng-container>
                        <ng-container *ngIf="!isExpanded.state">
                            <img src="/assets/icons/chevron-right.svg" alt="search-icon" />
                        </ng-container>
                        <span>State</span>
                    </div>
                    <div class="offers_state_content" *ngIf="isExpanded.state">
                        <div *ngFor="
                              let state of state_department_data.state;
                              let i = index
                            ">
                            <mat-checkbox color="primary" [checked]="isChecked(state.isChecked)"
                                          (change)="setFilters($event.checked, 'state', state)">
                                {{ state.state }}
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
                <div class="offers_states scroll-thin-style">
                    <div class="offers_state_header" (click)="handleVisivility('department')">
                        <ng-container *ngIf="isExpanded.department">
                            <img src="/assets/icons/chevron-down.svg" alt="search-icon" />
                        </ng-container>
                        <ng-container *ngIf="!isExpanded.department">
                            <img src="/assets/icons/chevron-right.svg" alt="search-icon" />
                        </ng-container>
                        <span>Status</span>
                    </div>
                    <div class="department_search" >
                        <!-- <div class="rounded_input_wrap">
                            <img src="/assets/icons/search.svg" alt="search-icon" />
                            <input class="none_border_outline" [(ngModel)]="departmentSearch"
                                   (ngModelChange)="searchFilterOption($event,'department')"
                                   style="border: none; outline: none" placeholder="Quick search" />
                        </div> -->
                    </div>
                    <div class="offers_state_content offers_departments scroll-thin-style"
                         *ngIf="isExpanded.department">
                        <div *ngFor="let status of filterStatus;" class="department_loop">
                            <mat-checkbox color="primary" [checked]="isChecked(status.isChecked)" (change)="setFilters($event.checked, 'status', status.id)">
                                {{ status.status }}
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
                <!-- <div class="offers_states scroll-thin-style">
                    <div class="offers_state_header" (click)="handleVisivility('vehicle')">
                        <ng-container *ngIf="isExpanded.vehicle">
                            <img src="/assets/icons/chevron-down.svg" alt="search-icon" />
                        </ng-container>
                        <ng-container *ngIf="!isExpanded.vehicle">
                            <img src="/assets/icons/chevron-right.svg" alt="search-icon" />
                        </ng-container>
                        <span>Vehicle</span>
                    </div>
                    <div class="offers_state_content offers_departments scroll-thin-style" *ngIf="isExpanded.vehicle">
                     <div *ngFor="let vehicle of filterVehicles;let i = index" class="department_loop">
                            <mat-checkbox color="primary" [checked]="isChecked(vehicle.isChecked)"
                                          (change)="setFilters($event.checked, 'vehicle', vehicle)">
                                {{ vehicle.vehicle }}
                            </mat-checkbox>
                        </div>
                    </div>
                </div> -->

            </div>
            <div class="offers_drawer_footer">
                <span (click)="handleClearAllChecked()">Clear Filters</span>
                <div>
                    <button class="filter_cancel" (click)="handleVisivility('drawer', false)">
                        Cancel
                    </button>
                    <button class="filter_apply" (click)="handleFilterApply()">
                        Apply
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="header_wrap">
        <div class="flex_between">
            <button class="rounded_blue enabled_color"><span class='whitespace_nowrap'>FLEXI Job Offers</span></button>
            <div class='assignment_status_wrapper'>
                <div class='assignment_status'>
                    <button class='assignment_status_btn'
                        [ngClass]="{'assignment_status_active':assignmentStatus==='ASSIGNED','assignment_status_inactive':assignmentStatus==='UNASSIGNED'}"
                        (click)="handleAssignmentStatus('ASSIGNED')">Assigned</button>
                    <button class='assignment_status_btn'
                        [ngClass]="{'assignment_status_inactive':assignmentStatus==='ASSIGNED','assignment_status_active':assignmentStatus==='UNASSIGNED'}"
                        (click)="handleAssignmentStatus('UNASSIGNED')">Unassigned &nbsp;&nbsp;
                         <button class="justify-center px-2 py-1 text-xs font-medium leading-none text-white bg-red-500 rounded-full" >
                            {{total_unassigned}} 
                        </button>
                
                    </button>
                </div>
               
            </div>
        </div>
        <div class="search_filter_wrap">
            <div class="align_center">
                <div class="rounded_input_wrap">
                    <img src="/assets/icons/search.svg" alt="search-icon" />
                    <input class="none_border_outline" [ngModel]="query.filters!.search"
                        (ngModelChange)="searcherInput($event)" style="border: none; outline: none"
                        placeholder="Search" />
                </div>
                <div class="offers_filters" type="button" mat-button (click)="handleVisivility('drawer')">
                    <img src="/assets/icons/filter-lines.svg" alt="filter-lines-icon" />
                    <span>Filters</span>
                </div>
            </div>
            <div class="search_option_wrap">
                <div class="date_range_selection">
                    <div class="date_from_selection">
                        <div class="hide_item">
                            <mat-form-field>
                                <input matInput [matDatepicker]="picker" [(ngModel)]="fromDate"
                                 
                                    (ngModelChange)="handleFromDateChange($event)" />
                                <mat-datepicker #picker [ngClass]="'my-theme-primary'">
                                    <mat-datepicker-actions>
                                        <button mat-button matDatepickerCancel>Cancel</button>
                                        <button mat-raised-button color="primary" matDatepickerApply>
                                            Apply
                                        </button>
                                    </mat-datepicker-actions>
                                </mat-datepicker>
                            </mat-form-field>
                        </div>
                        <span class="date_selection_text whitespace_nowrap">From:&nbsp;
                            <span class="date_selection_date">{{ getFormattedTime(fromDate) }}</span>
                        </span>
                        <button (click)="picker.open()" class="date_select_button">
                            <img src="/assets/icons/calendar.svg" alt="search-icon" />
                        </button>
                    </div>
                    <div class="date_from_selection">
                        <div class="hide_item">
                            <mat-form-field>
                                <input matInput [matDatepicker]="picker1" [(ngModel)]="toDate"
                                    
                                    (ngModelChange)="handleToDateChange($event)" />
                                <mat-datepicker #picker1>
                                    <mat-datepicker-actions>
                                        <button mat-button matDatepickerCancel>Cancel</button>
                                        <button mat-raised-button color="primary" matDatepickerApply (click)="fetchData()">
                                            Apply
                                        </button>
                                    </mat-datepicker-actions>
                                </mat-datepicker>
                            </mat-form-field>
                        </div>
                        <span class="date_selection_text whitespace_nowrap">To:&nbsp;
                            <span class="date_selection_date">{{ getFormattedTime(toDate) }}</span>
                        </span>
                        <button (click)="picker1.open()" class="date_select_button">
                            <img src="/assets/icons/calendar.svg" alt="search-icon" />
                        </button>
                    </div>
                </div>
                <div class="filtered_option_remove" *ngIf="
                  filteredData.state.length > 0 ||
                  filteredData.department.length > 0||
                  query.filters!.search
                ">
                    <span (click)="handleClearAllFilters()">Clear Filters</span>
                </div>
                
            </div>

            <div class="flex gap-[10px]">
                <button [ngClass]="activeQuickDateFilter === 'Yesterday' ? 'bg-[#2A6AF5] text-white' : 'text-gray-400 bg-[#ececec]'" class="flex justify-center items-center text-sm font-semibold font-Inter rounded-lg box-border px-[14px] py-2" (click)="quickDateFilter('Yesterday')">
                    <span class='whitespace_nowrap'>Yesterday</span>
                </button>
                <button [ngClass]="activeQuickDateFilter === 'Today' ? 'bg-[#2A6AF5] text-white' : 'text-gray-400 bg-[#ececec]'" class="flex justify-center items-center text-sm font-semibold font-Inter rounded-lg box-border px-[14px] py-2" (click)="quickDateFilter('Today')">
                    <span class='whitespace_nowrap'>Today</span>
                </button>
                <button [ngClass]="activeQuickDateFilter === 'Next7Days' ? 'bg-[#2A6AF5] text-white' : 'text-gray-400 bg-[#ececec]'" class="flex justify-center items-center text-sm font-semibold font-Inter rounded-lg box-border px-[14px] py-2" (click)="quickDateFilter('Next7Days')">
                    <span class='whitespace_nowrap'>Next 7 days</span>
                </button>
            </div>

            <button class="flex justify-center items-center text-sm font-semibold font-Inter border border-solid border-[#DFEAFA] bg-[#2A6AF5] text-white rounded-lg box-border px-[14px] py-2" *ngIf="assignmentStatus == 'UNASSIGNED'" (click)="machshipSync()">
                <span class='whitespace_nowrap'>Machship Sync </span>
                <span><img src='/assets/icons/sync.png' style='height: 20px;' /></span>
            </button>
            <div class="flex gap-[10px]">
                <button class="inline-flex h-100 justify-center items-center text-sm font-semibold font-Inter border border-solid border-[#DFEAFA] bg-[#2A6AF5] text-white rounded-lg box-border px-[14px] py-2" *ngIf="assignmentStatus == 'ASSIGNED'" (click)="exportToExcel()">
                    <span class='whitespace_nowrap'>Export</span>
                    <span><img src='/assets/icons/timesheet/download.png' style='height: 20px;' /></span>
                </button>
                <button class="inline-flex h-100 justify-center items-center text-sm font-semibold font-Inter border border-solid border-[#DFEAFA] bg-[#2A6AF5] text-white rounded-lg box-border px-[14px] py-2" *ngIf="assignmentStatus == 'ASSIGNED'" (click)="exportDriverPayments()">
                    <span class='whitespace_nowrap'>Process Payments</span>
                    <span><img src='/assets/icons/timesheet/download.png' style='height: 20px;' /></span>
                </button>
            </div>
        </div>
    </div>

<!-- Start page for Assigned --> 
<div *ngIf="assignmentStatus === 'ASSIGNED'">
    <div *ngIf="assignmentStatus == 'ASSIGNED'" style="margin-top:-20px !important; width: 98%; text-align: right;">
       <button class="justify-center px-2 py-1 text-xs font-medium leading-none text-white bg-red-500 rounded-full" 
            style=" display: inline-block;
              padding: 0.3em 0.7em;
              color: white;
              background-color: #3799e5; /* Tomato color */
              border-radius: 12px;
              text-align: center;">
                Manifested: {{ total_manifested.length }} 
            </button> &nbsp;
            <button class="justify-center px-2 py-1 text-xs font-medium leading-none text-white bg-red-500 rounded-full" 
            style=" display: inline-block;
              padding: 0.3em 0.7em;
              color: white;
              background-color: #efb134; /* Tomato color */
              border-radius: 12px;
              text-align: center;">
                Trip Started: {{ total_tripstarted.length }} 
            </button> &nbsp;
            <button class="justify-center px-2 py-1 text-xs font-medium leading-none text-white bg-red-500 rounded-full" 
            style=" display: inline-block;
              padding: 0.3em 0.7em;
              color: white;
              background-color: #efb134; /* Tomato color */
              border-radius: 12px;
              text-align: center;">
               Picked Up: {{ total_pickedup.length }} 
            </button> &nbsp;
            <button class="justify-center px-2 py-1 text-xs font-medium leading-none text-white bg-red-500 rounded-full" 
            style=" display: inline-block;
              padding: 0.3em 0.7em;
              color: white;
              background-color: #efb134; /* Tomato color */
              border-radius: 12px;
              text-align: center;">
                In Transit: {{ total_intransit.length }} 
            </button> &nbsp;
            <button class="justify-center px-2 py-1 text-xs font-medium leading-none text-white bg-red-500 rounded-full"
            style=" display: inline-block;
              padding: 0.3em 0.7em;
              color: white;
              background-color: #1bd659; /* Tomato color */
              border-radius: 12px;
              text-align: center;">
                Delivered: {{ total_delivered.length }} 
            </button> &nbsp;
            <button class="justify-center px-2 py-1 text-xs font-medium leading-none text-white bg-red-500 rounded-full"
            style=" display: inline-block;
              padding: 0.3em 0.7em;
              color: white;
              background-color: #ff735a; 
              border-radius: 12px;
              text-align: center;">
                Cancelled: {{ total_cancelled.length }} 
            </button>
    </div>
    <div class='w-full box-border px-[105px] py-5 custom-height' style="min-height: 100%;">
        <div class="w-full h-full flex flex-col gap-y-2 justify-center items-center font-Inter"
            *ngIf="!(dataAssigned.length > 0)">
            <img src="/assets/icons/Illustration.svg" alt="Illustration-icon" />
            <span class='text-base font-Inter font-medium'>No Data Found</span>
            <div class="w-full flex justify-center gap-x-2">
                <button class="custom-outlined-btn !w-fit" (click)="fetchData()">Try again</button>
                <button class="custom-solid-btn !w-fit" (click)="clearSearch()">
                    Clear Search
                </button>
            </div>
        </div>

        <div class='h-full flex flex-col border border-solid border-[#EAECF0] rounded-xl overflow-hidden shadow-lg'
            [ngClass]="{'hidden':dataAssigned.length<1}" style="margin-top: -10px; margin-left: -90px !important; margin-right: -90px !important">
    
            <div id='scroll-container' class='h-full overflow-y-scroll overflow-x scroll-thin-style' (scroll)='onScroll($event)'>
                <!-- Table header -->
                <div class=' flex bg-[#F9FAFB] border border-solid border-[#EAECF0] text-xs font-medium text-[#475467]' style="width: 250% !important; text-align: center;">
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width:250px !important;">
                        <span><strong></strong></span>
                    </div>
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width:150px !important;" >
                        <span class="flex items-center gap-2">
                            <strong>ManifestID</strong>
                            <div>
                                <img class="cursor-pointer" *ngIf="(newSort[tempAssignmentStatus].col == 'manifest_id' && newSort[tempAssignmentStatus].dir != 'asc') || (newSort[tempAssignmentStatus].col != 'manifest_id')" (click)="handleSort2('asc', 'manifest_id', 'assigned')" src="/assets/icons/offers/chevron-vertical-up.svg" alt="">
                                <img class="cursor-pointer" *ngIf="(newSort[tempAssignmentStatus].col == 'manifest_id' && newSort[tempAssignmentStatus].dir != 'desc') || (newSort[tempAssignmentStatus].col != 'manifest_id')" (click)="handleSort2('desc', 'manifest_id', 'assigned')" src="/assets/icons/offers/chevron-vertical-down.svg" alt="">
                            </div>
                        </span>
                    </div>
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width:150px !important;">
                        <span class="flex items-center gap-2">
                            <strong>Consignment#</strong>
                            <div>
                                <img class="cursor-pointer" *ngIf="(newSort[tempAssignmentStatus].col == 'consignment_number' && newSort[tempAssignmentStatus].dir != 'asc') || (newSort[tempAssignmentStatus].col != 'consignment_number')" (click)="handleSort2('asc', 'consignment_number', 'assigned')" src="/assets/icons/offers/chevron-vertical-up.svg" alt="">
                                <img class="cursor-pointer" *ngIf="(newSort[tempAssignmentStatus].col == 'consignment_number' && newSort[tempAssignmentStatus].dir != 'desc') || (newSort[tempAssignmentStatus].col != 'consignment_number')" (click)="handleSort2('desc', 'consignment_number', 'assigned')" src="/assets/icons/offers/chevron-vertical-down.svg" alt="">
                            </div>
                        </span>
                    </div>
                    
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width:200px !important;">
                        <span class="flex items-center gap-2">
                            <span>Despatch Date</span>
                            <div>
                                <img class="cursor-pointer" *ngIf="(newSort[tempAssignmentStatus].col == 'despatch_date' && newSort[tempAssignmentStatus].dir != 'asc') || (newSort[tempAssignmentStatus].col != 'despatch_date')" (click)="handleSort2('asc', 'despatch_date', 'assigned')" src="/assets/icons/offers/chevron-vertical-up.svg" alt="">
                                <img class="cursor-pointer" *ngIf="(newSort[tempAssignmentStatus].col == 'despatch_date' && newSort[tempAssignmentStatus].dir != 'desc') || (newSort[tempAssignmentStatus].col != 'despatch_date')" (click)="handleSort2('desc', 'despatch_date', 'assigned')" src="/assets/icons/offers/chevron-vertical-down.svg" alt="">
                            </div>
                        </span>
                    </div>
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width:100px !important;">
                        <span class="flex items-center gap-2">
                            <span>State</span>
                            <div>
                                <img class="cursor-pointer" *ngIf="(newSort[tempAssignmentStatus].col == 'pickup_header_state' && newSort[tempAssignmentStatus].dir != 'asc') || (newSort[tempAssignmentStatus].col != 'pickup_header_state')" (click)="handleSort2('asc', 'pickup_header_state', 'assigned')" src="/assets/icons/offers/chevron-vertical-up.svg" alt="">
                                <img class="cursor-pointer" *ngIf="(newSort[tempAssignmentStatus].col == 'pickup_header_state' && newSort[tempAssignmentStatus].dir != 'desc') || (newSort[tempAssignmentStatus].col != 'pickup_header_state')" (click)="handleSort2('desc', 'pickup_header_state', 'assigned')" src="/assets/icons/offers/chevron-vertical-down.svg" alt="">
                            </div>
                        </span>
                    </div>
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width: 150px;">
                        <span class="flex items-center gap-2">
                            <span>Status</span>
                            <div>
                                <img class="cursor-pointer" *ngIf="(newSort[tempAssignmentStatus].col == 'status_name' && newSort[tempAssignmentStatus].dir != 'asc') || (newSort[tempAssignmentStatus].col != 'status_name')" (click)="handleSort2('asc', 'status_name', 'assigned')" src="/assets/icons/offers/chevron-vertical-up.svg" alt="">
                                <img class="cursor-pointer" *ngIf="(newSort[tempAssignmentStatus].col == 'status_name' && newSort[tempAssignmentStatus].dir != 'desc') || (newSort[tempAssignmentStatus].col != 'status_name')" (click)="handleSort2('desc', 'status_name', 'assigned')" src="/assets/icons/offers/chevron-vertical-down.svg" alt="">
                            </div>
                        </span>
                    </div>
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width:200px !important;">
                        <span class="flex items-center gap-2">
                            <span>Driver</span>
                            <div>
                                <img class="cursor-pointer" *ngIf="(newSort[tempAssignmentStatus].col == 'driver_name' && newSort[tempAssignmentStatus].dir != 'asc') || (newSort[tempAssignmentStatus].col != 'driver_name')" (click)="handleSort2('asc', 'driver_name', 'assigned')" src="/assets/icons/offers/chevron-vertical-up.svg" alt="">
                                <img class="cursor-pointer" *ngIf="(newSort[tempAssignmentStatus].col == 'driver_name' && newSort[tempAssignmentStatus].dir != 'desc') || (newSort[tempAssignmentStatus].col != 'driver_name')" (click)="handleSort2('desc', 'driver_name', 'assigned')" src="/assets/icons/offers/chevron-vertical-down.svg" alt="">
                            </div>
                        </span>
                    </div>
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width:200px !important;">
                        <span class="flex items-center gap-2">
                            <span>Client</span>
                            <div>
                                <img class="cursor-pointer" *ngIf="(newSort[tempAssignmentStatus].col == 'company_name' && newSort[tempAssignmentStatus].dir != 'asc') || (newSort[tempAssignmentStatus].col != 'company_name')" (click)="handleSort2('asc', 'company_name', 'assigned')" src="/assets/icons/offers/chevron-vertical-up.svg" alt="">
                                <img class="cursor-pointer" *ngIf="(newSort[tempAssignmentStatus].col == 'company_name' && newSort[tempAssignmentStatus].dir != 'desc') || (newSort[tempAssignmentStatus].col != 'company_name')" (click)="handleSort2('desc', 'company_name', 'assigned')" src="/assets/icons/offers/chevron-vertical-down.svg" alt="">
                            </div>
                        </span>
                    </div>
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width:200px !important;">
                        <span class="flex items-center gap-2">
                            <span>Pickup Address</span>
                            <div>
                                <img class="cursor-pointer" *ngIf="(newSort[tempAssignmentStatus].col == 'pickup_full_address' && newSort[tempAssignmentStatus].dir != 'asc') || (newSort[tempAssignmentStatus].col != 'pickup_full_address')" (click)="handleSort2('asc', 'pickup_full_address', 'assigned')" src="/assets/icons/offers/chevron-vertical-up.svg" alt="">
                                <img class="cursor-pointer" *ngIf="(newSort[tempAssignmentStatus].col == 'pickup_full_address' && newSort[tempAssignmentStatus].dir != 'desc') || (newSort[tempAssignmentStatus].col != 'pickup_full_address')" (click)="handleSort2('desc', 'pickup_full_address', 'assigned')" src="/assets/icons/offers/chevron-vertical-down.svg" alt="">
                            </div>
                        </span>
                    </div>
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width:250px !important;">
                        <span>Pickup Contact Information</span>
                    </div>
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width:200px !important;">
                        <span class="flex items-center gap-2">
                            <span>Dropoff Address</span>
                            <div>
                                <img class="cursor-pointer" *ngIf="(newSort[tempAssignmentStatus].col == 'dropoff_full_address' && newSort[tempAssignmentStatus].dir != 'asc') || (newSort[tempAssignmentStatus].col != 'dropoff_full_address')" (click)="handleSort2('asc', 'dropoff_full_address', 'assigned')" src="/assets/icons/offers/chevron-vertical-up.svg" alt="">
                                <img class="cursor-pointer" *ngIf="(newSort[tempAssignmentStatus].col == 'dropoff_full_address' && newSort[tempAssignmentStatus].dir != 'desc') || (newSort[tempAssignmentStatus].col != 'dropoff_full_address')" (click)="handleSort2('desc', 'dropoff_full_address', 'assigned')" src="/assets/icons/offers/chevron-vertical-down.svg" alt="">
                            </div>
                        </span>
                    </div>
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width:250px !important;">
                        <span>Dropoff Contact Information</span>
                    </div>
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width:300px !important;">
                        <span>Timestamps</span>
                    </div>
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width:150px !important;">
                        <span class="flex items-center gap-2">
                            <span>Cost</span>
                            <div>
                                <img class="cursor-pointer" *ngIf="(newSort[tempAssignmentStatus].col == 'total_cost_price' && newSort[tempAssignmentStatus].dir != 'asc') || (newSort[tempAssignmentStatus].col != 'total_cost_price')" (click)="handleSort2('asc', 'total_cost_price', 'assigned')" src="/assets/icons/offers/chevron-vertical-up.svg" alt="">
                                <img class="cursor-pointer" *ngIf="(newSort[tempAssignmentStatus].col == 'total_cost_price' && newSort[tempAssignmentStatus].dir != 'desc') || (newSort[tempAssignmentStatus].col != 'total_cost_price')" (click)="handleSort2('desc', 'total_cost_price', 'assigned')" src="/assets/icons/offers/chevron-vertical-down.svg" alt="">
                            </div>
                        </span>
                    </div>
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width:150px !important;">
                        <span class="flex items-center gap-2">
                            <span>Sell Price</span>
                            <div>
                                <img class="cursor-pointer" *ngIf="(newSort[tempAssignmentStatus].col == 'total_sell_price' && newSort[tempAssignmentStatus].dir != 'asc') || (newSort[tempAssignmentStatus].col != 'total_sell_price')" (click)="handleSort2('asc', 'total_sell_price', 'assigned')" src="/assets/icons/offers/chevron-vertical-up.svg" alt="">
                                <img class="cursor-pointer" *ngIf="(newSort[tempAssignmentStatus].col == 'total_sell_price' && newSort[tempAssignmentStatus].dir != 'desc') || (newSort[tempAssignmentStatus].col != 'total_sell_price')" (click)="handleSort2('desc', 'total_sell_price', 'assigned')" src="/assets/icons/offers/chevron-vertical-down.svg" alt="">
                            </div>
                        </span>
                    </div>
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width:150px !important;">
                        <span class="flex items-center gap-2">
                            <span>Gross Profit</span>
                            <div>
                                <img class="cursor-pointer" *ngIf="(newSort[tempAssignmentStatus].col == 'tmp_gross_profit' && newSort[tempAssignmentStatus].dir != 'asc') || (newSort[tempAssignmentStatus].col != 'tmp_gross_profit')" (click)="handleSort2('asc', 'tmp_gross_profit', 'assigned')" src="/assets/icons/offers/chevron-vertical-up.svg" alt="">
                                <img class="cursor-pointer" *ngIf="(newSort[tempAssignmentStatus].col == 'tmp_gross_profit' && newSort[tempAssignmentStatus].dir != 'desc') || (newSort[tempAssignmentStatus].col != 'tmp_gross_profit')" (click)="handleSort2('desc', 'tmp_gross_profit', 'assigned')" src="/assets/icons/offers/chevron-vertical-down.svg" alt="">
                            </div>
                        </span>
                    </div>
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width:200px !important;">
                        <span class="flex items-center gap-2">
                            <span>Service Type</span>
                            <div>
                                <img class="cursor-pointer" *ngIf="(newSort[tempAssignmentStatus].col == 'service_names' && newSort[tempAssignmentStatus].dir != 'asc') || (newSort[tempAssignmentStatus].col != 'service_names')" (click)="handleSort2('asc', 'service_names', 'assigned')" src="/assets/icons/offers/chevron-vertical-up.svg" alt="">
                                <img class="cursor-pointer" *ngIf="(newSort[tempAssignmentStatus].col == 'service_names' && newSort[tempAssignmentStatus].dir != 'desc') || (newSort[assignmentStatus.toLowerCase()].col != 'service_names')" (click)="handleSort2('desc', 'service_names', 'assigned')" src="/assets/icons/offers/chevron-vertical-down.svg" alt="">
                            </div>
                        </span>
                    </div>
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width:300px !important;">
                        <span>Items</span>
                    </div>
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width:300px !important;">
                        <span>Proof-of-delivery</span>
                    </div>
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width:300px !important;">
                        <span>Comment</span>
                    </div>
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width:300px !important;">
                        <span>Comment by</span>
                    </div>
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width:300px !important;">
                        <span>Cancelled by</span>
                    </div>
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width:300px !important;">
                        <span>Completed by</span>
                    </div>
                    
                    
                </div>
<!-- End column for Assigned -->
<!-- if page is Assigned -->
                <div id='scroll-content' class='flex bg-white border border-solid border-[#EAECF0] text-sm font-normal text-[#475467] scroll-thin-style' style="width: 250% !important; min-height: 90px !important;"
                    *ngFor="let offer of dataAssigned; let i = index">
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width: 250px !important;">
                        <!-- Action Button -->
       
                        <div class='relative'>
                            <div>
                            <!-- <button class="justify-center px-2 py-1 text-xs font-small leading-none btn-link" (click)="handleAssignOffer(offer, 'REASSIGN_CONFIRM')" *ngIf="offer.status_name==='Manifested'">
                                Reassign
                            </button> -->
                            <!-- Map Button -->
                            <button class="flex items-center justify-center w-8 h-8 rounded-full text-yellow-600 hover:bg-yellow-200"  type="button" (click)="openMap(offer.pickup_full_address, offer.dropoff_full_address)" title="Open Map">
                                <img src="/assets/icons/map.png">
                            </button>
                            
                            </div>
                            
                        </div>

                        <div *ngIf="!['Cancelled', 'Delivered'].includes(offer.status_name)">
                            <button class="flex items-center justify-center w-8 h-8 rounded-full"  type="button" (click)="openConfirmStatusUpdate(offer.consignment_id, offer.carrier_consignment_id, 10)" title="Click to cancel consignment">
                                <img src="/assets/icons/timesheet/red-x-icon.svg" style="height: 23px; width: 23px;">
                            </button>
                        </div>

                        <div *ngIf="!['Cancelled', 'Delivered'].includes(offer.status_name)">
                            <button class="flex items-center justify-center w-8 h-8 rounded-full"  type="button" (click)="openConfirmStatusUpdate(offer.consignment_id, offer.carrier_consignment_id, 7)" title="Click to complete consignment">
                                <img src="/assets/icons/timesheet/green-checkmark-icon.svg" style="height: 23px; width: 23px;">
                            </button>
                        </div>
                        <!-- Change Driver Button -->
                        <button *ngIf="offer.status_name == 'Manifested'" class="flex items-center justify-center w-8 h-8 rounded-full text-yellow-600 hover:bg-yellow-200"  type="button" (click)="handleAssignOffer(offer, 'REASSIGN_CONFIRM')"  title="Re-assign to other driver">
                            <img src="/assets/icons/changedriver.png">
                        </button>
                        
                         <!-- Delete Button -->
                        <!-- <button class="flex items-center justify-center w-8 h-8 rounded-full text-yellow-600 hover:bg-yellow-200"  type="button">
                            <img src="/assets/icons/delete.png">
                           
                        </button> -->
                    </div>
                    <div class='flex justify-start items-center gap-3 box-border px-6 py-3' style="width:150px !important;" >
                        <span>
                            {{ offer.manifest_id }}
                        </span>
                    </div>
                    <div class='flex justify-start items-center gap-3 box-border px-6 py-3' style="width:150px !important;">
                        <span>
                            {{ offer.consignment_number }}<br/>
                            {{ offer.carrier_consignment_id }}
                        </span>
                    </div>
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width:200px !important;">
                        <span *ngIf="offer.status_name != 'Manifested'">{{ offer?.despatch_date_local |  date: 'dd/MM/yyyy'}}</span>
                        <input *ngIf="offer.status_name == 'Manifested'" type="date" class="form-control" value="{{ offer?.despatch_date_local | date: 'yyyy-MM-dd'}}" (change)="onChangeDespatchDate($event, offer?.carrier_consignment_id, offer.manifest_id)">
                    </div>
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width:100px !important;">
                        <span>{{ offer.pickup_header_state }}</span>
                    </div>
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width: 150px !important; ">

                            <button class="justify-center px-2 py-1 text-xs font-medium leading-none text-white bg-red-500 rounded-full" *ngIf="offer.status_name=='Manifested'"
                            style=" display: inline-block;
                              padding: 0.3em 0.7em;
                              color: white;
                              background-color: #3799e5; /* Tomato color */
                              border-radius: 12px;
                              text-align: center;">
                                {{ offer.status_name }} 
                            </button>
                            <button class="justify-center px-2 py-1 text-xs font-medium leading-none text-white bg-red-500 rounded-full" *ngIf="offer.status_name=='Picked Up' || offer.status_name=='Trip Started' || offer.status_name=='In Transit'"
                            style=" display: inline-block;
                              padding: 0.3em 0.7em;
                              color: white;
                              background-color: #efb134; /* Tomato color */
                              border-radius: 12px;
                              text-align: center;">
                                {{ offer.status_name }}
                            </button>
                            <button class="justify-center px-2 py-1 text-xs font-medium leading-none text-white bg-red-500 rounded-full" *ngIf="offer.status_name=='Delivered'"
                            style=" display: inline-block;
                              padding: 0.3em 0.7em;
                              color: white;
                              background-color: #1bd659; /* Tomato color */
                              border-radius: 12px;
                              text-align: center;">
                                {{ offer.status_name }}
                            </button>
                            <button class="justify-center px-2 py-1 text-xs font-medium leading-none text-white bg-red-500 rounded-full" *ngIf="offer.status_name=='Cancelled'"
                            style=" display: inline-block;
                              padding: 0.3em 0.7em;
                              color: white;
                              background-color: #ff735a;
                              border-radius: 12px;
                              text-align: center;">
                                {{ offer.status_name }}
                            </button>

                    </div>
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width: 200px !important">
                        <span>
                            {{ offer.driver_name }}<br>
                            {{ offer.vehicle_type_name }}<br>
                            {{ offer.driver_phone }}
                        </span>
                    </div>
                    
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width: 200px !important; ">
                        <span>{{ offer.company_name }}</span>
                    </div>
                    
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width: 200px !important">
                        <span>{{ offer.pickup_full_address }}</span>
                    </div>
                     <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width: 250px !important; word-break: break-all;">
                        <span>
                            {{ offer.pickup_contact_name }}<br>
                            {{ offer.pickup_contact_phone }}<br>
                            {{ offer.pickup_contact_email }}
                        </span>
                    </div>
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width: 200px !important; ">
                        <span>{{ offer.dropoff_full_address }}</span>
                    </div>
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width: 250px !important; word-break: break-all;">
                        <span>
                            {{ offer.dropoff_contact_name }}<br>
                            {{ offer.dropoff_contact_phone }}<br>
                            {{ offer.dropoff_contact_email }}
                        </span>
                    </div>
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width: 300px !important">
                        <div class="flex flex-col gap-1">
                            <div>
                                <strong>Trip Started:</strong>
                                <span class="ml-2" *ngIf="offer?.trip_started_at != '0000-00-00 00:00:00'">{{offer?.trip_started_at |  date: 'MM/dd/yyyy h:mm a' || '-'}}</span>
                            </div>
                            <div>
                                <strong>Picked Up:</strong>
                                <span class="ml-2" *ngIf="offer?.picked_up_at != '0000-00-00 00:00:00'">{{offer?.picked_up_at |  date: 'MM/dd/yyyy h:mm a' || '-'}}</span>
                            </div>
                            <div>
                                <strong>In Transit:</strong>
                                <span class="ml-2" *ngIf="offer?.in_transit_at != '0000-00-00 00:00:00'">{{offer?.in_transit_at |  date: 'MM/dd/yyyy h:mm a' || '-'}}</span>
                            </div>
                            <div>
                                <strong>Delivered:</strong>
                                <span class="ml-2" *ngIf="offer?.completed_date != '0000-00-00 00:00:00'">{{offer?.completed_date |  date: 'MM/dd/yyyy h:mm a' || '-'}}</span>
                            </div>
                        </div>
                    </div>
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width: 150px !important">
                        <span>${{ offer.total_cost_price  | number: '1.2-2' }}</span>
                    </div>
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width: 150px !important">
                        <span>${{ offer.total_sell_price  | number: '1.2-2' }}</span>
                    </div>
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width: 150px !important">
                        <span>${{ offer.total_sell_price - offer.total_cost_price  | number: '1.2-2' }}</span>
                    </div>
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width: 200px !important">
                        <span>{{ offer.service_names }}</span>
                    </div>
                    <div class='justify-start items-center box-border px-6 py-3' style="width: 300px !important; margin-top: 25px;">
                        <span *ngFor="let items of offer.items; let i = index">
                            <span>{{ items.item_name }} - {{ items.item_quantity }} pc</span><br/>
                            <span style="font-size:12px; color:gray ">{{ items.item_length }}cm x {{ items.item_width }}cm x {{ items.item_height }}cm x {{ items.item_weight }}kg </span><br/>
                        </span>
                    </div>
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width: 300px !important">
                        <div class="flex flex-col gap-1" *ngIf="offer.pod_receiver_name && offer.pod_receiver_name != 'ATL'">
                            <div >
                                Receiver: {{ offer.pod_receiver_name || '-'}}
                            </div>
                            <div>
                                <button class="justify-center px-2 py-1 text-xs font-small leading-none btn-link" (click)="downloadImagePOD(offer.consignment_id)" style="font-size:12px">
                                    Proof-of-Delivery Photo
                                </button> 
                            </div>
                            <div>
                                <button class="justify-center px-2 py-1 text-xs font-small leading-none btn-link" (click)="downloadImageSignature(offer.consignment_id)" style="font-size:12px">
                                    Receiver's Signature
                                </button> 
                            </div>
                        </div>

                        <div class="flex flex-col gap-1" *ngIf="offer.pod_receiver_name && offer.pod_receiver_name == 'ATL'">
                            <div>ATL</div>
                            <div>
                                <button class="justify-center px-2 py-1 text-xs font-small leading-none btn-link" (click)="downloadImagePOD(offer.consignment_id)" style="font-size:12px">
                                    ATL Photos
                                </button> 
                            </div>
                            <div>
                                <button class="justify-center px-2 py-1 text-xs font-small leading-none btn-link" (click)="downloadImageSignature(offer.consignment_id)" style="font-size:12px">
                                    Driver's Signature
                                </button> 
                            </div>
                        </div>

                    </div>
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width: 300px !important">
                        <div [class.hidden]="!offer?.comment_by">
                            <textarea [maxLength]="100" class="outline-none block p-2.5 w-full text-sm text-gray-900 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 offer_comment" id="" cols="40" rows="4" (blur)="updateTable($event, offer.flexi_job_details_id)">{{ offer?.comment }}</textarea>
                        </div>
                        <span class="cursor-pointer btn-add-comment hover:text-red-600" [class.hidden]="offer?.comment_by" (click)="openAddComment(i, $event)">Add Comment</span>
                    </div>
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width: 300px !important">{{ offer?.comment_by ? offer?.commented_by.name + ' ' + offer?.commented_by.lname : "" }}</div>
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width: 300px !important">{{ offer?.cancelled_by ? offer?.cancelled_by.name + ' ' + offer?.cancelled_by.lname : "" }}</div>
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width: 300px !important">{{ offer?.completed_by ? offer?.completed_by.name + ' ' + offer?.completed_by.lname : "" }}</div>
                    
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End page for Assigned -->       


<!-- Start page for Unssigned --> 
<div *ngIf="assignmentStatus === 'UNASSIGNED'">
    <div class='w-full box-border px-[105px] py-5 custom-height' style="min-height: 100%;">
        <div class="w-full h-full flex flex-col gap-y-2 justify-center items-center font-Inter"
            *ngIf="!(dataUnassigned.length > 0)">
            <img src="/assets/icons/Illustration.svg" alt="Illustration-icon" />
            <span class='text-base font-Inter font-medium'>No Data Found</span>
            <div class="w-full flex justify-center gap-x-2">
                <button class="custom-outlined-btn !w-fit" (click)="fetchData()">Try again</button>
                <button class="custom-solid-btn !w-fit" (click)="clearSearch()">
                    Clear Search
                </button>
            </div>
        </div>

        <div class='h-full flex flex-col border border-solid border-[#EAECF0] rounded-xl overflow-hidden shadow-lg'
            [ngClass]="{'hidden':dataUnassigned.length<1}" style="margin-top: -10px; margin-left: -90px !important; margin-right: -90px !important">
    
            <div id='scroll-container' class='h-full overflow-y-scroll overflow-x scroll-thin-style' (scroll)='onScroll($event)'>
                <!-- Table header -->
                <div class=' flex bg-[#F9FAFB] border border-solid border-[#EAECF0] text-xs font-medium text-[#475467]' style="width: 180% !important;">
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width:200px !important;">
                        <span></span>
                    </div>
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width:150px !important;">
                        <span class="flex items-center gap-2">
                            <strong>ManifestID</strong>
                            <div>
                                <img class="cursor-pointer" *ngIf="(newSort[tempAssignmentStatus].col == 'manifest_id' && newSort[tempAssignmentStatus].dir != 'asc') || (newSort[tempAssignmentStatus].col != 'manifest_id')" (click)="handleSort2('asc', 'manifest_id', 'unassigned')" src="/assets/icons/offers/chevron-vertical-up.svg" alt="">
                                <img class="cursor-pointer" *ngIf="(newSort[tempAssignmentStatus].col == 'manifest_id' && newSort[tempAssignmentStatus].dir != 'desc') || (newSort[tempAssignmentStatus].col != 'manifest_id')" (click)="handleSort2('desc', 'manifest_id', 'unassigned')" src="/assets/icons/offers/chevron-vertical-down.svg" alt="">
                            </div>
                        </span>
                    </div>

                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width:150px !important;">
                        <span class="flex items-center gap-2">
                            <strong>Consignment#</strong>
                            <div>
                                <img class="cursor-pointer" *ngIf="(newSort[tempAssignmentStatus].col == 'consignment_number' && newSort[tempAssignmentStatus].dir != 'asc') || (newSort[tempAssignmentStatus].col != 'consignment_number')" (click)="handleSort2('asc', 'consignment_number', 'unassigned')" src="/assets/icons/offers/chevron-vertical-up.svg" alt="">
                                <img class="cursor-pointer" *ngIf="(newSort[tempAssignmentStatus].col == 'consignment_number' && newSort[tempAssignmentStatus].dir != 'desc') || (newSort[tempAssignmentStatus].col != 'consignment_number')" (click)="handleSort2('desc', 'consignment_number', 'unassigned')" src="/assets/icons/offers/chevron-vertical-down.svg" alt="">
                            </div>
                        </span>
                    </div>
                    
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width:160px !important;">
                        <span class="flex items-center gap-2">
                            <strong>Despatch Date</strong>
                            <div>
                                <img class="cursor-pointer" *ngIf="(newSort[tempAssignmentStatus].col == 'despatch_date' && newSort[tempAssignmentStatus].dir != 'asc') || (newSort[tempAssignmentStatus].col != 'despatch_date')" (click)="handleSort2('asc', 'despatch_date', 'unassigned')" src="/assets/icons/offers/chevron-vertical-up.svg" alt="">
                                <img class="cursor-pointer" *ngIf="(newSort[tempAssignmentStatus].col == 'despatch_date' && newSort[tempAssignmentStatus].dir != 'desc') || (newSort[tempAssignmentStatus].col != 'despatch_date')" (click)="handleSort2('desc', 'despatch_date', 'unassigned')" src="/assets/icons/offers/chevron-vertical-down.svg" alt="">
                            </div>
                        </span>
                    </div>
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width:100px !important;">
                        <span class="flex items-center gap-2">
                            <strong>State</strong>
                            <div>
                                <img class="cursor-pointer" *ngIf="(newSort[tempAssignmentStatus].col == 'pickup_header_state' && newSort[tempAssignmentStatus].dir != 'asc') || (newSort[tempAssignmentStatus].col != 'pickup_header_state')" (click)="handleSort2('asc', 'pickup_header_state', 'unassigned')" src="/assets/icons/offers/chevron-vertical-up.svg" alt="">
                                <img class="cursor-pointer" *ngIf="(newSort[tempAssignmentStatus].col == 'pickup_header_state' && newSort[tempAssignmentStatus].dir != 'desc') || (newSort[tempAssignmentStatus].col != 'pickup_header_state')" (click)="handleSort2('desc', 'pickup_header_state', 'unassigned')" src="/assets/icons/offers/chevron-vertical-down.svg" alt="">
                            </div>
                        </span>
                    </div>
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width:200px !important;">
                        <span class="flex items-center gap-2">
                            <strong>Client</strong>
                            <div>
                                <img class="cursor-pointer" *ngIf="(newSort[tempAssignmentStatus].col == 'company_name' && newSort[tempAssignmentStatus].dir != 'asc') || (newSort[tempAssignmentStatus].col != 'company_name')" (click)="handleSort2('asc', 'company_name', 'unassigned')" src="/assets/icons/offers/chevron-vertical-up.svg" alt="">
                                <img class="cursor-pointer" *ngIf="(newSort[tempAssignmentStatus].col == 'company_name' && newSort[tempAssignmentStatus].dir != 'desc') || (newSort[tempAssignmentStatus].col != 'company_name')" (click)="handleSort2('desc', 'company_name', 'unassigned')" src="/assets/icons/offers/chevron-vertical-down.svg" alt="">
                            </div>
                        </span>
                    </div>
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width:200px !important;">
                        <span class="flex items-center gap-2">
                            <strong>Pickup Address</strong>
                            <div>
                                <img class="cursor-pointer" *ngIf="(newSort[tempAssignmentStatus].col == 'pickup_full_address' && newSort[tempAssignmentStatus].dir != 'asc') || (newSort[tempAssignmentStatus].col != 'pickup_full_address')" (click)="handleSort2('asc', 'pickup_full_address', 'unassigned')" src="/assets/icons/offers/chevron-vertical-up.svg" alt="">
                                <img class="cursor-pointer" *ngIf="(newSort[tempAssignmentStatus].col == 'pickup_full_address' && newSort[tempAssignmentStatus].dir != 'desc') || (newSort[tempAssignmentStatus].col != 'pickup_full_address')" (click)="handleSort2('desc', 'pickup_full_address', 'unassigned')" src="/assets/icons/offers/chevron-vertical-down.svg" alt="">
                            </div>
                        </span>
                    </div>
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width:250px !important;">
                        <span>Pickup Contact Information</span>
                    </div>
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width:200px !important;">
                        <span class="flex items-center gap-2">
                            <strong>Dropoff Address</strong>
                            <div>
                                <img class="cursor-pointer" *ngIf="(newSort[tempAssignmentStatus].col == 'dropoff_full_address' && newSort[tempAssignmentStatus].dir != 'asc') || (newSort[tempAssignmentStatus].col != 'dropoff_full_address')" (click)="handleSort2('asc', 'dropoff_full_address', 'unassigned')" src="/assets/icons/offers/chevron-vertical-up.svg" alt="">
                                <img class="cursor-pointer" *ngIf="(newSort[tempAssignmentStatus].col == 'dropoff_full_address' && newSort[tempAssignmentStatus].dir != 'desc') || (newSort[tempAssignmentStatus].col != 'dropoff_full_address')" (click)="handleSort2('desc', 'dropoff_full_address', 'unassigned')" src="/assets/icons/offers/chevron-vertical-down.svg" alt="">
                            </div>
                        </span>
                    </div>
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width:250px !important;">
                        <span>Dropoff Contact Information</span>
                    </div>
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width:150px !important;">
                        <span class="flex items-center gap-2">
                            <strong>Cost</strong>
                            <div>
                                <img class="cursor-pointer" *ngIf="(newSort[tempAssignmentStatus].col == 'total_cost_price' && newSort[tempAssignmentStatus].dir != 'asc') || (newSort[tempAssignmentStatus].col != 'total_cost_price')" (click)="handleSort2('asc', 'total_cost_price', 'unassigned')" src="/assets/icons/offers/chevron-vertical-up.svg" alt="">
                                <img class="cursor-pointer" *ngIf="(newSort[tempAssignmentStatus].col == 'total_cost_price' && newSort[tempAssignmentStatus].dir != 'desc') || (newSort[tempAssignmentStatus].col != 'total_cost_price')" (click)="handleSort2('desc', 'total_cost_price', 'unassigned')" src="/assets/icons/offers/chevron-vertical-down.svg" alt="">
                            </div>
                        </span>
                    </div>
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width:150px !important;">
                        <span>Sell Price</span>
                        <span class="flex items-center gap-2">
                            <strong>Cost</strong>
                            <div>
                                <img class="cursor-pointer" *ngIf="(newSort[tempAssignmentStatus].col == 'total_sell_price' && newSort[tempAssignmentStatus].dir != 'asc') || (newSort[tempAssignmentStatus].col != 'total_sell_price')" (click)="handleSort2('asc', 'total_sell_price', 'unassigned')" src="/assets/icons/offers/chevron-vertical-up.svg" alt="">
                                <img class="cursor-pointer" *ngIf="(newSort[tempAssignmentStatus].col == 'total_sell_price' && newSort[tempAssignmentStatus].dir != 'desc') || (newSort[tempAssignmentStatus].col != 'total_sell_price')" (click)="handleSort2('desc', 'total_sell_price', 'unassigned')" src="/assets/icons/offers/chevron-vertical-down.svg" alt="">
                            </div>
                        </span>
                    </div>
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width:150px !important;">
                        <span class="flex items-center gap-2">
                            <strong>Gross Profit</strong>
                            <div>
                                <img class="cursor-pointer" *ngIf="(newSort[tempAssignmentStatus].col == 'tmp_gross_profit' && newSort[tempAssignmentStatus].dir != 'asc') || (newSort[tempAssignmentStatus].col != 'tmp_gross_profit')" (click)="handleSort2('asc', 'tmp_gross_profit', 'unassigned')" src="/assets/icons/offers/chevron-vertical-up.svg" alt="">
                                <img class="cursor-pointer" *ngIf="(newSort[tempAssignmentStatus].col == 'tmp_gross_profit' && newSort[tempAssignmentStatus].dir != 'desc') || (newSort[tempAssignmentStatus].col != 'tmp_gross_profit')" (click)="handleSort2('desc', 'tmp_gross_profit', 'unassigned')" src="/assets/icons/offers/chevron-vertical-down.svg" alt="">
                            </div>
                        </span>
                    </div>
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width:200px !important;">
                        <span></span>
                        <span class="flex items-center gap-2">
                            <strong>Service Type</strong>
                            <div>
                                <img class="cursor-pointer" *ngIf="(newSort[tempAssignmentStatus].col == 'service_names' && newSort[tempAssignmentStatus].dir != 'asc') || (newSort[tempAssignmentStatus].col != 'service_names')" (click)="handleSort2('asc', 'service_names', 'unassigned')" src="/assets/icons/offers/chevron-vertical-up.svg" alt="">
                                <img class="cursor-pointer" *ngIf="(newSort[tempAssignmentStatus].col == 'service_names' && newSort[tempAssignmentStatus].dir != 'desc') || (newSort[tempAssignmentStatus].col != 'service_names')" (click)="handleSort2('desc', 'service_names', 'unassigned')" src="/assets/icons/offers/chevron-vertical-down.svg" alt="">
                            </div>
                        </span>
                    </div>
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width:300px !important;">
                        <span>Items</span>
                    </div>
                </div>
<!-- End column for Unassigned -->
<!-- if page is Unassigned -->
                <div id='scroll-content' class='flex bg-white border border-solid border-[#EAECF0] text-sm font-normal text-[#475467] scroll-thin-style' style="width: 180% !important; height: 100px !important;"
                    *ngFor="let offer of dataUnassigned; let i = index">
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width: 200px !important">
                        <!-- Map Button -->
                        <button class="flex items-center justify-center w-8 h-8 rounded-full text-yellow-600 hover:bg-yellow-200"  type="button" (click)="openMap(offer.pickup_full_address, offer.dropoff_full_address)" title="Open Map">
                            <img src="/assets/icons/map.png">
                        </button>
                        <!-- Publish btn -->
                        <button class="flex items-center justify-center w-8 h-8 rounded-full text-red-600 hover:bg-red-200" (click)="sendPushNotification(offer.manifest_id)" title="Send Notification To Eligible Drivers">
                            <img src="/assets/icons/publish.png">
                        </button>
                        <!-- Dropdown Button -->
                        <div class='relative'>
                            <button class="justify-center px-2 py-1 text-xs font-medium leading-none text-white bg-red-500 rounded-full" (click)="toggleDropdown(i)" *ngIf="offer.interested_drivers && offer.interested_drivers.length">
                                {{offer.interested_drivers.length}}
                            </button>
                            <button class="justify-center px-2 py-1 text-xs font-medium leading-none text-white bg-red-500 rounded-full" (click)="handleAssignOffer(offer, 'ASSIGN_CONFIRM')" *ngIf="!(offer.interested_drivers && offer.interested_drivers.length)">
                                {{offer.interested_drivers.length}}
                            </button>
                            <div class='dropdown-menu' [ngClass]="{'show': dropdownIndex === i}" style="min-width: 400px; margin-left: 100px; padding:10px">
                                <!-- Check if there are interested drivers -->
                                <div *ngIf="offer.interested_drivers && offer.interested_drivers.length > 0">
                                    <table class="w-full">
                                        <thead>
                                        <tr>
                                            <th class="m-2">Driver Name</th>
                                            <th class="m-2">Vehicle Type</th>
                                            <th class="m-2">Phone</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr  *ngFor="let driver of offer.interested_drivers" (click)="onRowSelect(driver, offer.flexi_job_header_id)" class="border-b border-gray-200 hover:cursor-pointer">
                                            <td class="m-2 flex flex-row">
                                                <img src="/assets/icons/offers/user.svg" alt="Driver Icon" class="w-2 h-2 mr-2" />
                                                {{ driver?.driver_name }}
                                            </td>
                                            <td class="m-2">
                                                {{ driver?.vehicle_type_name }}
                                            </td>
                                            <td class="m-2">
                                                {{ driver?.driver_phone }}
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                        
                            </div>
                        </div>
                        <!-- Assign Driver btn -->
                        <button *ngIf="offer.interested_drivers.length > 0" class="flex items-center justify-center w-8 h-8 rounded-full text-red-600 hover:bg-yellow-200" (click)="handleAssignOffer(offer, 'ASSIGN_CONFIRM')" title="Assign to a driver">
                            <img src="/assets/icons/searchdriver.png">
                        </button>
                        <ng-container *ngIf="!['Cancelled', 'Delivered'].includes(offer.status_name)">
                            <button class="flex items-center justify-center w-8 h-8 rounded-full"  type="button" (click)="openConfirmStatusUpdate(offer.consignment_id, offer.carrier_consignment_id, 10)">
                                <img src="/assets/icons/timesheet/red-x-icon.svg" style="height: 23px; width: 23px;">
                            </button>
                        </ng-container>
                    </div>
                    <div class='flex justify-start items-center gap-3 box-border px-6 py-3' style="width:150px !important;" >
                        <span>
                            {{ offer.manifest_id }}
                        </span>
                    </div>
                    <div class='flex justify-start items-center gap-3 box-border px-6 py-3' style="width:150px !important;">
                        <span>
                            {{ offer.consignment_number }}<br/>
                            {{ offer.carrier_consignment_id }}
                        </span>
                    </div>
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width:150px !important;">
                        <span>{{ offer?.despatch_date_local |  date: 'dd/MM/yyyy'}}</span>
                    </div>
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width:100px !important;">
                        <span>{{ offer.pickup_header_state }}</span>
                    </div>
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width: 200px !important; ">
                        <span>{{ offer.company_name }}</span>
                    </div>
                    
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width: 200px !important">
                        <span>{{ offer.pickup_full_address }}</span>
                    </div>
                     <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width: 250px !important; word-break: break-all;">
                        <span>
                            {{ offer.pickup_contact_name }}<br>
                            {{ offer.pickup_contact_phone }}<br>
                            {{ offer.pickup_contact_email }}
                        </span>
                    </div>
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width: 200px !important; ">
                        <span>{{ offer.dropoff_full_address }}</span>
                    </div>
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width: 250px !important; word-break: break-all;">
                        <span>
                            {{ offer.dropoff_contact_name }}<br>
                            {{ offer.dropoff_contact_phone }}<br>
                            {{ offer.dropoff_contact_email }}
                        </span>
                    </div>
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width: 150px !important">
                        <span>${{ offer.total_cost_price   | number: '1.2-2' }}</span>
                    </div>
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width: 150px !important">
                        <span>${{ offer.total_sell_price  | number: '1.2-2' }}</span>
                    </div>
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width: 150px !important">
                        <span>${{ offer.total_sell_price - offer.total_cost_price  | number: '1.2-2' }}</span>
                    </div>
                    <div class='flex justify-start items-center gap-2 box-border px-6 py-3' style="width: 200px !important">
                        <span>{{ offer.service_names }}</span>
                    </div>
                    <div class='justify-start items-center box-border px-6 py-3' style="width: 300px !important; margin-top: 25px;">
                        <span *ngFor="let items of offer.items; let i = index">
                            <span>{{ items.item_name }} - {{ items.item_quantity }} pc</span><br/>
                            <span style="font-size:12px; color:gray ">{{ items.item_length }}cm x {{ items.item_width }}cm x {{ items.item_height }}cm x {{ items.item_weight }}kg </span><br/>
                        </span>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Confirmation modal to update consignment status to cancel -->
    <div [class.hidden]="selectedConsignmentId == 0" class="fixed h-[100%] bg-[#cfcfcf] w-[100%] z-10" style="font-family: 'Helvetica Neue'">
        <div class="justify-items-center p-[2rem] w-fit m-auto bg-white relative top-[5rem] rounded-[10px]">
            <div class="flex gap-[2rem] w-[100%]">
                <div class="flex-[1] text-center pl-[55px] text-[25px] font-bold">{{ confirmationStatusUpdateTextTitle }}</div>
                <div class="flex-[0_0_5%] cursor-pointer"><img src="/assets/icons/x-close.svg" alt="close" (click)="confirmUpdateStatus(0)"/></div>
            </div>
            <div class="my-[2rem] mx-0">
                <span>{{ confirmationStatusUpdateText }}</span>
            </div>
            <div class="flex gap-[1rem]">
                <button class="text-[15px] bg-[#f1f1f1] py-[10px] px-[35px] rounded-[10px] text-zinc-700" (click)="confirmUpdateStatus(0)">Cancel</button>
                <button class="text-[15px] bg-[#2a6af6] py-[10px] px-[35px] rounded-[10px] text-white" (click)="confirmUpdateStatus(1,selectedConsignmentId, selectedCStatus)">Submit</button>
            </div>
        </div>
    </div>

</div>
<!-- End page for Unassigned -->       

<app-modal-body *ngIf="modalStatus!=='NONE'">
    <ng-container [ngTemplateOutlet]="confirm_template"
        *ngIf="modalStatus==='REASSIGN_CONFIRM' || modalStatus==='ASSIGN_CONFIRM'"></ng-container>
</app-modal-body>
<ng-template #confirm_template>
    <div class='w-[600px] flex flex-col items-center box-border relative gap-y-8' style="height: 500px;">
        <div class='absolute right-0 top-0 w-fit hover:cursor-pointer' (click)="closeModal()">
            <img src="/assets/icons/x-close.svg" alt="close-icon" />
        </div>
        <div class='w-full flex flex-col gap-y-4'>
            <!-- <div class='w-full flex justify-center items-center'>
                <img src="/assets/icons/offers/success.svg" alt="success-icon" *ngIf="modalStatus==='REASSIGN_CONFIRM' || modalStatus==='ASSIGN_CONFIRM'" />
            </div> -->
            <div class='w-full flex flex-col gap-y-[10px]' style="height: 100%">
                <div class='w-full flex justify-center text-center font-Inter text-lg font-semibold leading-7'>
                    <ng-container *ngIf="modalStatus==='REASSIGN_CONFIRM' ">
                        Re-assign the job to other driver <br/> (Manifest ID: {{assigneeDetail.manifest_id}})
                    </ng-container>
                    <ng-container *ngIf="modalStatus==='ASSIGN_CONFIRM' ">
                        Assign driver for the job <br/>(Manifest ID: {{assigneeDetail.manifest_id}})
                    </ng-container>
                </div>
                <div class='w-full text-center text-sm text-[#475467] font-Inter font-normal'>Please select a driver you want to assign this job</div>
                <!-- body here -->
                <div class="align_center" style="width:100%">
                    <div class="rounded_input_wrap" > 
                        <img src="/assets/icons/search.svg" alt="search-icon" />
                        <input class="none_border_outline" [ngModel]="query.filters!.search"
                            (ngModelChange)="searcherDriverInput($event)" style="border: none; outline: none;"
                            placeholder="Search" />
                    </div>
                    <span style="color: #2a6af6">
                        <strong>Assign To:</strong> {{selectedDriver?.driver_name}} - {{selectedDriver?.vehicle_type_name}}
                    </span>
                </div>
                <div style="height: 280px; overflow: scroll; border-right: solid #d6dbe2; font-size: 14px;">
                    <table class="w-full">
                        <thead>
                            <tr>
                                <th class="m-4"></th>
                                <th class="m-2">Payroll Code</th>
                                <th class="m-2">Driver Name</th>
                                <th class="m-2">Phone No</th>
                                <th class="m-2">Vehicle Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngIf="eligibleDrivers.length <1">
                                <td>Loading ...</td>
                            </tr>
                            <tr *ngFor="let driver of eligibleDrivers; let i = index" class="border-b border-gray-500 hover:cursor-pointer" style="text-align: center;  height: 50px;" (click)="selectDriver(i, driver)">
                                <td class="m-4">
                                    <mat-checkbox color="primary" [checked]="selectedDriverIndex===i"
                                          (click)="selectDriver(i, driver)"></mat-checkbox>
                                </td>
                                <td class="m-2">
                                    {{driver.payrollCode || '-'}}
                                </td>
                                <td class="m-2">
                                    {{driver.driver_name || '-'}}
                                </td>
                                <td class="m-2">
                                    {{driver.driver_phone || '-'}}
                                </td>
                                <td class="m-2">
                                    {{driver.vehicle_type_name || '-'}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!--  body here -->
            </div>
        </div>
        <div class='w-full flex items-center gap-2'>
            <button class='custom-outlined-btn' (click)="closeModal()">Cancel</button>
            <button class='custom-solid-btn' (click)="assignToDriver(selectedDriver, assigneeDetail.flexi_job_header_id)"> Assign</button>
        </div>
    </div>
</ng-template>






<app-loader-v2 [loading]="loading"></app-loader-v2>
