import {
  Component,
  ElementRef,
  Injectable,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TableColumn } from 'src/app/core/table/table.component';
import { exportAsExcel, exportCSV } from 'src/app/export-excel/Export2Excel';
import { CodedResponseModel } from 'src/app/model/CodedResponseModel';
import { IndexQuery } from 'src/app/model/IndexQuery';
import { DepartmentType } from 'src/app/model/custm/JobShiftOffer';
import {
  LoadingRatesType,
  MappingDataType,
  loading_charges,
} from 'src/app/model/custm/Setting';
import {
  BulkUpdateForamt,
  COLUMN_WIDTH_DEFS,
  COMPARISON_FIELDS,
  ClientChargingType,
  ClientInvoiceADHoc,
  ClientInvoiceType,
  ClientLoadingRateType,
  Department2UploadType,
  DriverInvoiceADHoc,
  DriverInvoiceType,
  EXPORT_COLUMNS,
  EXPORT_MODEL,
  FilterType,
  getHoursMins,
  getHours,
  formatUSD,
  HolidayType,
  InvoiceRowType,
  REGIONALS,
  SHIFT_DEFINITIONS,
  SHIFT_DEF_MATCHES,
  SHIFT_TYPE,
  STATUSES,
  ShiftSummaryType,
  TOLLS,
  TOLL_MATCHES,
  TimePairType,
  Timesheet,
  TimesheetSortObj,
  getInvoiceExportColumns,
  getShiftDef,
  getFLEXIQuantity,
} from 'src/app/model/custm/Timesheet';
import { NotificatorPartial } from 'src/app/partials/notificator/notificator.component';
import { TimesheetAPIService } from 'src/app/services/custm/timesheet.service';
import { LoginService } from 'src/app/services/login.service';
import * as XLSX from 'xlsx-js-style';
import { MappingType } from '../settings/settings.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-timesheet',
  templateUrl: './timesheet.component.html',
  styleUrls: ['./timesheet.component.scss'],
})
export class TimesheetComponent implements OnInit, OnDestroy {
  @ViewChild('fileInput', { static: true }) fileInput!: ElementRef;
  public offerDate: Date = new Date();
  public sortObj: TimesheetSortObj;
  public sortOrder: (keyof TimesheetSortObj)[];
  public sortStringObj = {
    checked: 'checked',
    Paid: 'Paid',
    AuthorisedBy: 'AuthoriserName',
    LastEditBy: 'LastEditorName',
    ShiftType: 'ShiftType',
    road_tolls: 'COALESCE(Tolls,"ZZZ")',
    regional: 'COALESCE(Regional,"ZZZ")',
    Shift_Definition: 'Shift_Definition',
    Summary: 'SummaryContainsSummary',
    pick_up:
      'CAST(IFNULL(JSON_UNQUOTE(JSON_EXTRACT(Summary_Data, "$.pick_up")),"0") AS SIGNED)',
    Attempted_Deliveries_Paid: 'Attempted_Deliveries_Paid',
    Attempted_Deliveries:
      'CAST(IFNULL(JSON_UNQUOTE(JSON_EXTRACT(Summary_Data, "$.stops_loaded")),"0") AS SIGNED) - CAST(IFNULL(JSON_UNQUOTE(JSON_EXTRACT(Summary_Data, "$.skipped")),"0") AS SIGNED)',
    NetMinutes: 'NetMinutes',
    Breaks: 'Breaks',
    GrossMinutes: 'GrossMinutes',
    PayEndTime: 'PayEndTime',
    PayStartTime: 'PayStartTime',
    OutCheck: 'OutCheck',
    InCheck: 'InCheck',
    OutTIme: 'OutTIme',
    InTime: 'InTime',
    StartTime: 'StartTime',
    Role: 'Role',
    Department: 'Department',
    Location: 'Location',
    Employee: 'Employee',
    Date: 'DATE(StartTime)',
    GrossProfit: 'GrossProfit',
    invoiced: 'invoiced'
  };

  public filterOptions: { label: string; key: FilterType }[] = [
    {
      label: 'In Check',
      key: 'clockInCheck',
    },
    {
      label: 'Out Check',
      key: 'clockOutCheck',
    },
    {
      label: 'Shift Definition',
      key: 'shiftDefinition',
    },
    {
      label: 'Regional',
      key: 'regional',
    },
    {
      label: 'Tolls',
      key: 'tolls',
    },
    {
      label: 'Paid?',
      key: 'paid',
    },
    {
      label: 'Checked',
      key: 'checked',
    },
    {
      label: 'Actions',
      key: 'actions',
    },
  ];

  public loading: boolean = false;
  public data: Timesheet[] = [];
  public tempData: Timesheet[] = [];
  public searchModel: string = '';
  public useSavedFilter: boolean = true;
  public savedCollapsedColumns: number[] = [];
  public savedToggleObj: { [key: string]: boolean } = { NetHours: false };
  public isExpanded = {
    state: false,
    department: false,
    clockInCheck: false,
    clockOutCheck: false,
    actions: false,
    shiftDefinition: false,
    regional: false,
    tolls: false,
    paid: false,
    checked: false,
    drawer: false,
  };

  private autoRefresher: any;
  public deletingTimesheet!: Timesheet;
  public originalData!: { location: string; department: string }[];
  public toExport: boolean = false;
  public range = new FormGroup({
    start: new FormControl(null),
    end: new FormControl(null),
  });
  public searchCooldown: any;
  public defaultDay: string = 'yesterday';
  public query: IndexQuery = {
    page: 1,
    perPage: 20,
    sortBy: 'created_at',
    sortDir: 'desc',
    filters: {
      search: '',
      tz: String(new Date().getTimezoneOffset()),
    },
  };

  // Add a flag to indicate if data is currently being loaded
  public total: number = 0;
  public filtered: number = 0;
  public authorised: number = 0;
  public departmentSearch: string = '';
  public quickSearch: string = '';
  public vehicleSearch: string = '';
  public totalNetHours: string = '0.00';
  public totalCharge: string = '0.00';
  public totalPayment: string = '0.00';
  public totalGross: string = '0.00';

  public departments2Upload: Department2UploadType[] = [];
  public seekedDepartments: Department2UploadType[] = [];
  public excelData: MappingType['data'] = [];
  public columnIndexes: { [key: string]: number } = {};
  public excelDataErrors: { row: number; column: string }[] = [];
  public importCompletion: { total: number; success: number; error: number } = {
    total: 0,
    success: 0,
    error: 0,
  };

  public businessDetails: {
    state: DepartmentType[];
    department: DepartmentType[];
    clockInCheck: DepartmentType[];
    clockOutCheck: DepartmentType[];
    shiftDefinition: DepartmentType[];
    regional: DepartmentType[];
    tolls: DepartmentType[];
    paid: DepartmentType[];
    checked: DepartmentType[];
    actions: DepartmentType[];
  } = {
    state: [],
    department: [],
    clockInCheck: [],
    clockOutCheck: [],
    shiftDefinition: [],
    regional: [],
    tolls: [],
    paid: [],
    checked: [],
    actions: [],
  };

  filteredData: {
    state: { label: string }[];
    department: { label: string }[];
    clockInCheck: { label: string }[];
    clockOutCheck: { label: string }[];
    actions: { label: string }[];
    shiftDefinition: { label: string }[];
    regional: { label: string }[];
    tolls: { label: string }[];
    paid: { label: string }[];
    checked: { label: string }[];
  } = {
    state: [],
    department: [],
    clockInCheck: [],
    clockOutCheck: [],
    actions: [],
    shiftDefinition: [],
    regional: [],
    tolls: [],
    paid: [],
    checked: [],
  };

  public filterOptionValues: { [keyValue: string]: any } = {
    clockInCheck: {
      flagged: 'Flagged for checking',
      not_flagged: 'Not flagged',
    },
    clockOutCheck: {
      flagged: 'Flagged for checking',
      not_flagged: 'Not flagged',
    },
    regional: { yes: 'Yes', no: 'No' },
    tolls: { one_way: 'One-way', return: 'Return', no: 'No' },
    actions: {
      authorized: 'Authorized',
      unauthorized: 'Unauthorized',
      with_comments: 'With Comments',
      without_comments: 'No Comments',
    },
    shiftDefinition: {
      set_run: 'Set Run',
      sprint: 'Sprint',
      marathon: 'Marathon',
      flexi: 'FLEXI',
    },
    paid: {
      yes: 'Yes',
      no: 'No',
    },
    checked: {
      yes: 'Yes',
      no: 'No',
    },
  };

  public actions: any = {
    authorized: 'Authorized',
    unauthorized: 'Unauthorized',
    with_comments: 'With Comments',
    without_comments: 'Without Comments',
  };
  public shiftDefinitions: any = {
    set_run: 'Set Run',
    sprint: 'Sprint',
    marathon: 'Marathon',
    flexi: 'FLEXI',
  };

  public maxDate: Date = new Date();
  public payRange: { startRange: Date | null; endRange: Date | null } = {
    startRange: null,
    endRange: null,
  };
  public modalStatus: string = 'NONE';
  public seletedDriverId: number = 0;

  private loading_rates: {
    name: string;
    loading_rates: LoadingRatesType[] | null;
  }[] = [];
  private holidays: HolidayType[] = [];
  private loading_charge: MappingDataType[] = [];
  private driver_roles: MappingDataType[] = [];

  public columnDef: TableColumn[] = [
    {
      label: 'Date',
      slug: 'Date',
      keyValue: 'Date',
      sortable: true,
      globalDisable: (row: Timesheet) => row.Authorised,
      accessor: (i: Timesheet) => i.Date,
    },
    {
      label: 'Driver',
      slug: 'Driver',
      keyValue: 'Employee',
      sortable: true,
      accent: true,
      accessor: (i: Timesheet) => i.Driver,
    },
    {
      label: 'Location',
      slug: 'Location',
      keyValue: 'Location',
      sortable: true,
      accessor: (i: Timesheet) => i.Location,
    },
    {
      label: 'Department',
      slug: 'Department',
      keyValue: 'Department',
      sortable: true,
      accessor: (i: Timesheet) => i.Department,
    },
    {
      label: 'Role',
      slug: 'Role',
      keyValue: 'Role',
      sortable: true,
      accessor: (i: Timesheet) => i.Role,
    },
    {
      label: 'Job Start',
      slug: 'JobStart',
      keyValue: 'StartTime',
      sortable: true,
      accessor: (i: Timesheet) => i.JobStart,
    },
    {
      label: 'In Time',
      slug: 'InTime',
      keyValue: 'InTime',
      sortable: true,
      accessor: (i: Timesheet) => i.InTime,
    },
    {
      label: 'Out Time',
      slug: 'OutTime',
      keyValue: 'OutTIme',
      sortable: true,
      accessor: (i: Timesheet) => i.OutTime,
    },
    {
      label: 'In Check',
      slug: 'InCheck',
      keyValue: 'InCheck',
      icon: true,
      alignCenter: true,
      sortable: true,
      accessor: (i: Timesheet) => i.InCheck,
      toggleAction: (i: Timesheet) => !!i.clocked_coords?.in,
      onClick: (i: Timesheet) => {
        if (!!i.clocked_coords?.in)
          return window.open(
            `location-data?user_id=${i.User_ID}&date=${i.StartTime}&focus=in&Shift_ID=${i.Shift_ID}`,
            '_blank'
          );
        else return false;
      },
    },
    {
      label: 'Out Check',
      slug: 'OutCheck',
      keyValue: 'OutCheck',
      icon: true,
      alignCenter: true,
      sortable: true,
      accessor: (i: Timesheet) => i.OutCheck,
      toggleAction: (i: Timesheet) => !!i.clocked_coords?.out,
      onClick: (i: Timesheet) => {
        if (!!i.clocked_coords?.out)
          return window.open(
            `location-data?user_id=${i.User_ID}&date=${i.StartTime}&focus=out&Shift_ID=${i.Shift_ID}`,
            '_blank'
          );
        else return false;
      },
    },
    {
      label: 'Pay In',
      slug: 'PayIn',
      keyValue: 'PayStartTime',
      editable: true,
      inputstyle: 'width:64px;text-align:center;',
      inputmask: 'Hh:m0',
      placeholder: '--:--',
      invoker: (rowNumber: number, row: any) => {
        if (this.tempData[rowNumber].PayIn === row.PayIn) return;
        const GrossMinutes = this.getDiffsInMinutes(row.PayIn, row.PayOut, "hh:mm", row.StartTime);
        const Breaks = this.getBreaks(GrossMinutes);
        const NetMinutes = GrossMinutes - Breaks;
        const updatedDate = this.getUpdatedTime(row.Date, row.PayIn);
        this.editTimesheet(rowNumber, row.id, {
          PayStartTime: updatedDate,
          ...(row.PayOut ? { GrossMinutes, Breaks, NetMinutes } : {}),
        });
        const GrossHours = getHoursMins(GrossMinutes);
        const NetHours = getHoursMins(NetMinutes, this.savedToggleObj.NetHours);
        this.data[rowNumber] = {
          ...row,
          Breaks,
          GrossHours,
          NetHours,
          GrossMinutes,
          NetMinutes,
        };
        this.tempData[rowNumber] = { ...this.data[rowNumber] } as Timesheet;
      },
      disableRule: (row: Timesheet) => row.IsDisabled,
      // validator: (i: Timesheet) => {
      //   let isValid = true;
      //   isValid = isValid && i.PayIn?.length === 4;
      //   if ((i.PayOut ?? '0').length === 4)
      //     isValid = isValid && Number(i.PayIn ?? '0') < Number(i.PayOut ?? '0');
      //   return isValid;
      // },
      touchable: () =>
        this.authService.hasPermissions([
          'full:timesheets',
          'edit:timesheets',
          'edit-pay-in:timesheets',
        ]),
      alignCenter: true,
      sortable: true,
      accessor: (i: Timesheet) => i.PayIn,
    },
    {
      label: 'Pay Out',
      slug: 'PayOut',
      keyValue: 'PayEndTime',
      editable: true,
      inputstyle: 'width:64px;text-align:center;',
      inputmask: 'Hh:m0',
      placeholder: '--:--',
      invoker: (rowNumber: number, row: any) => {
        if (this.tempData[rowNumber].PayOut === row.PayOut) return;
        const GrossMinutes = this.getDiffsInMinutes(row.PayIn, row.PayOut, "hh:mm", row.StartTime);
        const Breaks = this.getBreaks(GrossMinutes);
        const NetMinutes = GrossMinutes - Breaks;
        let updatedDate = this.getUpdatedTime(row.Date, row.PayOut);
        if (row.PayIn > row.PayOut) {
          /* Add 1 day to pay end time */
          updatedDate = moment(updatedDate).add(1, 'days').format('YYYY-MM-DDTHH:mm:ss');
        }
        this.editTimesheet(rowNumber, row.id, {
          PayEndTime: updatedDate,
          GrossMinutes,
          Breaks,
          NetMinutes,
        });
        const GrossHours = getHoursMins(GrossMinutes);
        const NetHours = getHoursMins(NetMinutes, this.savedToggleObj.NetHours);
        this.data[rowNumber] = {
          ...row,
          Breaks,
          GrossHours,
          NetHours,
          GrossMinutes,
          NetMinutes,
        };
        this.tempData[rowNumber] = { ...this.data[rowNumber] } as Timesheet;
      },
      disableRule: (row: Timesheet) => row.IsDisabled,
      // validator: (i: Timesheet) => {
      //   let isValid = true;
      //   isValid = isValid && i.PayOut?.length === 4;
      //   isValid = isValid && Boolean(i.PayIn);
      //   isValid = isValid && Number(i.PayIn ?? '0') < Number(i.PayOut ?? '0');
      //   return isValid;
      // },
      touchable: () =>
        this.authService.hasPermissions([
          'full:timesheets',
          'edit:timesheets',
          'edit-pay-out:timesheets',
        ]),
      alignCenter: true,
      sortable: true,
      accessor: (i: Timesheet) => i.PayOut,
    },
    {
      label: 'Gross Hours',
      slug: 'GrossHours',
      keyValue: 'GrossMinutes',
      alignCenter: true,
      sortable: true,
      attachableValidator: (i: Timesheet) => {
        return i.GrossMinutes >= 720 ? 'Critical' : 'Standard';
      },
      accessor: (i: Timesheet) => i.GrossHours,
    },
    {
      label: 'Breaks',
      slug: 'Breaks',
      keyValue: 'Breaks',
      editable: true,
      inputstyle: 'width:60px;text-align:center;',
      inputmask: '99',
      suffix: 'm',
      placeholder: '0m',
      invoker: (rowNumber: number, row: any) => {
        if (this.tempData[rowNumber].Breaks === row.Breaks) return;
        const GrossMinutes = this.getDiffsInMinutes(row.PayIn, row.PayOut);
        const Breaks = row.Breaks > 60 ? 60 : row.Breaks;
        if (Breaks > GrossMinutes) {
          this.data[rowNumber].Breaks = this.tempData[rowNumber].Breaks;
          return NotificatorPartial.push({
            type: 'error',
            message: 'Breaks time is greater than Gross hours',
            timeout: 3000,
          });
        }
        const NetMinutes = GrossMinutes - Breaks;
        this.editTimesheet(rowNumber, row.id, {
          Breaks,
          GrossMinutes,
          NetMinutes,
        });
        const GrossHours = getHoursMins(GrossMinutes);
        const NetHours = getHoursMins(NetMinutes, this.savedToggleObj.NetHours);
        this.data[rowNumber] = {
          ...row,
          Breaks,
          GrossHours,
          NetHours,
          GrossMinutes,
          NetMinutes,
        };
        this.tempData[rowNumber] = { ...this.data[rowNumber] } as Timesheet;
      },
      disableRule: (row: Timesheet) => {
        return !(Boolean(row.PayIn) && Boolean(row.PayOut));
      },
      touchable: () =>
        this.authService.hasPermissions([
          'full:timesheets',
          'edit:timesheets',
          'edit-breaks:timesheets',
        ]),
      alignCenter: true,
      sortable: true,
      accessor: (i: Timesheet) => i.Breaks,
    },
    {
      label: 'Net Hours',
      slug: 'NetHours',
      keyValue: 'NetMinutes',
      alignCenter: true,
      sortable: true,
      toggle: true,
      toggleAction: (row: any, justHours: boolean) => {
        return getHoursMins(row.NetMinutes, justHours);
      },
      attachableValidator: (i: Timesheet) => {
        if (i.NetMinutes > 0) {
          if (i.ShiftDefinition === 'Marathon') {
            if (i.NetMinutes < (i.MarathonHours - 2) * 60) return 'Critical';
            if (i.NetMinutes < i.MarathonHours * 60) return 'Warning';
          }
          if (i.ShiftDefinition === 'Sprint') {
            if (i.NetMinutes < (i.SprintHours - 2) * 60) return 'Critical';
            if (i.NetMinutes < i.SprintHours * 60) return 'Warning';
          }
        }
        return 'Standard';
      },
      accessor: (i: Timesheet) => i.NetHours,
    },
    {
      label: 'Attempted Deliveries',
      slug: 'AttemptedDeliveries',
      keyValue: 'Attempted_Deliveries',
      invoker: (
        rowNumber: number,
        row: any,
        updatedData: ShiftSummaryType['data']
      ) => {
        if (!Boolean(row.PayOut))
          return NotificatorPartial.push({
            type: 'error',
            message: 'PayOut time is required',
            timeout: 3000,
          });
        const newSummaryData = { ...updatedData, pick_up: row.Pickups };
        if (_.isEqual(this.tempData[rowNumber].SummaryData, newSummaryData))
          return;
        const Attempted_Deliveries_Paid =
          updatedData.stops_loaded - updatedData.skipped;
        this.editTimesheet(rowNumber, row.id, {
          field: 'data',
          data: newSummaryData,
          Summary_ID: row.Summary_ID,
        });
        this.editTimesheet(rowNumber, row.id, { Attempted_Deliveries_Paid });
        this.data[rowNumber] = {
          ...row,
          SummaryData: newSummaryData,
          AttemptedDeliveries: Attempted_Deliveries_Paid,
          AttemptedDeliveriesPaid: Attempted_Deliveries_Paid,
        };
        this.tempData[rowNumber] = { ...this.data[rowNumber] } as Timesheet;

        // if (row.Summary_ID > -1) {} else {
        //   NotificatorPartial.push({
        //     type: 'error',
        //     message: 'Shift summary does not exist.',
        //     timeout: 3000,
        //   });
        // }
      },
      poppable: true,
      popupicon: '/assets/icons/timesheet/pen.svg',
      popupdata: (i: Timesheet) => i.SummaryData,
      popupvalidator: (data: ShiftSummaryType['data']) => {
        if (data.stops_loaded > 300) {
          NotificatorPartial.push({
            type: 'error',
            message:
              'Whoops, looks like you made a mistake. More than 300 stops in a single shift is too many for you to complete a shift safety',
            timeout: 3000,
          });
          return false;
        }
        if (data.stops_loaded < 1) {
          NotificatorPartial.push({
            type: 'error',
            message:
              'Whoops, looks like you made a mistake. Stops Loaded cannot be 0',
            timeout: 3000,
          });
          return false;
        }
        if (
          data.stops_loaded -
            data.cards_left -
            data.check_address -
            data.rejections -
            data.skipped <
          0
        ) {
          NotificatorPartial.push({
            type: 'error',
            message:
              'Whoops, looks like you made a mistake. Make sure your Stops Loaded are greater than or equal to the total of Cards left, Check Address, Rejections and Skipped',
            timeout: 3000,
          });
          return false;
        }

        return true;
      },
      touchable: () =>
        this.authService.hasPermissions([
          'full:timesheets',
          'edit:timesheets',
          'edit-attempted-deliveries:timesheets',
        ]),
      alignCenter: true,
      sortable: true,
      accessor: (i: Timesheet) => i.AttemptedDeliveries,
    },
    {
      label: 'Attempted Deliveries Paid',
      slug: 'AttemptedDeliveriesPaid',
      keyValue: 'Attempted_Deliveries_Paid',
      editable: true,
      inputstyle: 'width:50px;text-align:center;',
      inputmask: '999',
      placeholder: '0',
      attachableValidator: (i: Timesheet) => {
        if (i.ShiftDefinition === 'FLEXI' && i.Role.includes('FLEXI')) {
          const inputString = i.Role;
          const regex = /\d+/; // Matches one or more digits
          const match = inputString.match(regex);
          const target = Number(match?.[0] ?? '0');
          return i.AttemptedDeliveriesPaid < target ? 'Critical' : 'Standard';
        }
        return 'Standard';
      },
      invoker: (rowNumber: number, row: any) => {
        if (
          this.tempData[rowNumber].AttemptedDeliveriesPaid ===
          row.AttemptedDeliveriesPaid
        )
          return;
        this.editTimesheet(rowNumber, row.id, {
          Attempted_Deliveries_Paid: row.AttemptedDeliveriesPaid,
        });
        this.data[rowNumber] = { ...row };
        this.tempData[rowNumber] = { ...this.data[rowNumber] } as Timesheet;
      },
      touchable: () =>
        this.authService.hasPermissions([
          'full:timesheets',
          'edit:timesheets',
          'edit-attempted-deliveries-paid:timesheets',
        ]),
      alignCenter: true,
      sortable: true,
      accessor: (i: Timesheet) => i.AttemptedDeliveriesPaid,
    },
    {
      label: 'Pickups',
      slug: 'Pickups',
      keyValue: 'pick_up',
      editable: true,
      inputstyle: 'width:60px;text-align:center;',
      inputmask: '999',
      placeholder: '0',
      invoker: (rowNumber: number, row: any) => {
        if (this.tempData[rowNumber].Pickups === row.Pickups) return;
        const newSummaryData = { ...row.SummaryData, pick_up: row.Pickups };
        this.editTimesheet(rowNumber, row.id, {
          field: 'data',
          data: newSummaryData,
          Summary_ID: row.Summary_ID,
        });
        this.data[rowNumber] = {
          ...row,
          SummaryData: newSummaryData,
          Pickups: row.Pickups,
        };

        this.tempData[rowNumber] = { ...this.data[rowNumber] } as Timesheet;
        // if (row.Summary_ID > -1) {} else {
        //   NotificatorPartial.push({
        //     type: 'error',
        //     message: 'Shift summary does not exist.',
        //     timeout: 3000,
        //   });
        // }
      },
      validator: (data: Timesheet) => {
        if (!Boolean(data.PayOut))
          NotificatorPartial.push({
            type: 'error',
            message: 'PayOut time is required',
            timeout: 3000,
          });
        return Boolean(data.PayOut);
      },
      touchable: () =>
        this.authService.hasPermissions([
          'full:timesheets',
          'edit:timesheets',
          'edit-pickups:timesheets',
        ]),
      alignCenter: true,
      sortable: true,
      accessor: (i: Timesheet) => i.Pickups,
    },
    {
      label: 'Summary',
      slug: 'Summary',
      keyValue: 'Summary',
      outlink: true,
      alignCenter: true,
      sortable: true,
      accessor: (i: Timesheet) => i.Summary,
    },
    {
      label: 'Shift Definition',
      slug: 'ShiftDefinition',
      keyValue: 'Shift_Definition',
      poppable: true,
      popupicon: '/assets/icons/offers/chevron-vertical.svg',
      popupdata: (i: Timesheet) => i.Avaialble_Shift_Definitions,
      invoker: (rowNumber: number, row: any, updatedData: string) => {
        this.editTimesheet(rowNumber, row.id, {
          Shift_Definition: updatedData,
        });
        this.data[rowNumber].Avaialble_Shift_Definitions = this.data[
          rowNumber
        ].Avaialble_Shift_Definitions.map((item) => ({
          ...item,
          isSelected: item.item === updatedData,
        }));
        this.data[rowNumber] = {
          ...row,
          ShiftDefinition: updatedData,
        };
        this.tempData[rowNumber] = { ...this.data[rowNumber] } as Timesheet;
      },
      touchable: () =>
        this.authService.hasPermissions([
          'full:timesheets',
          'edit:timesheets',
          'edit-shift-definition:timesheets',
        ]),
      alignCenter: true,
      sortable: true,
      accessor: (i: Timesheet) => i.ShiftDefinition,
    },
    {
      label: 'Regional',
      slug: 'Regional',
      keyValue: 'regional',

      hybrid: true,

      inputstyle: 'width:70px;text-align:center;',
      inputmask: '9999',
      placeholder: '0000',
      invoker: (rowNumber: number, row: any) => {
        if (!Boolean(row.PayOut))
          return NotificatorPartial.push({
            type: 'error',
            message: 'PayOut time is required. Updated value is not saved',
            timeout: 3000,
          });
        if (this.tempData[rowNumber].Regional === row.Regional) return;
        this.editTimesheet(rowNumber, row.id, {
          field: 'regional',
          data: row.Regional === 'No' ? 'ZZZ' : row.Regional,
          Summary_ID: row.Summary_ID,
        });
        this.data[rowNumber] = {
          ...row,
        };

        this.tempData[rowNumber] = { ...this.data[rowNumber] } as Timesheet;
        // if (row.Summary_ID > -1) {} else {
        //   NotificatorPartial.push({
        //     type: 'error',
        //     message: 'Shift summary does not exist.',
        //     timeout: 3000,
        //   });
        // }
      },

      popupicon: '/assets/icons/offers/chevron-vertical.svg',
      validator: (i: Timesheet) => {
        return i.Regional.length === 4;
      },
      popupdata: (i: Timesheet) =>
        REGIONALS.map((item) => ({ item, isSelected: item === i.Regional })),
      touchable: () =>
        this.authService.hasPermissions([
          'full:timesheets',
          'edit:timesheets',
          'edit-regional:timesheets',
        ]),
      alignCenter: true,
      sortable: true,
      accessor: (i: Timesheet) => i.Regional,
    },
    {
      label: 'Tolls',
      slug: 'Tolls',
      keyValue: 'road_tolls',
      poppable: true,
      popupicon: '/assets/icons/offers/chevron-vertical.svg',
      popupdata: (i: Timesheet) =>
        TOLLS.map((item) => ({ item, isSelected: item === i.Tolls })),
      invoker: (rowNumber: number, row: any, updatedData: string) => {
        if (!Boolean(row.PayOut))
          return NotificatorPartial.push({
            type: 'error',
            message: 'PayOut time is required',
            timeout: 3000,
          });
        this.editTimesheet(rowNumber, row.id, {
          field: 'road_tolls',
          data: updatedData === 'No' ? 'ZZZ' : updatedData,
          Summary_ID: row.Summary_ID,
        });
        this.data[rowNumber] = {
          ...row,
          Tolls: updatedData,
        };
        this.tempData[rowNumber] = { ...this.data[rowNumber] } as Timesheet;

        // if (row.Summary_ID > -1) {} else {
        //   NotificatorPartial.push({
        //     type: 'error',
        //     message: 'Shift summary does not exist.',
        //     timeout: 3000,
        //   });
        // }
      },
      touchable: () =>
        this.authService.hasPermissions([
          'full:timesheets',
          'edit:timesheets',
          'edit-tolls:timesheets',
        ]),
      alignCenter: true,
      sortable: true,
      accessor: (i: Timesheet) => i.Tolls,
    },
    {
      label: 'Status',
      slug: 'Status',
      keyValue: 'ShiftType',
      poppable: true,
      popupicon: '/assets/icons/offers/chevron-vertical.svg',
      popupdata: (i: Timesheet) =>
        STATUSES.map((item) => ({ item, isSelected: item === i.Status })),
      invoker: (rowNumber: number, row: any, updatedData: string) => {
        this.editTimesheet(rowNumber, row.id, {
          ShiftType: updatedData,
        });
        this.data[rowNumber] = {
          ...row,
          Status: updatedData,
        };
        this.tempData[rowNumber] = { ...this.data[rowNumber] } as Timesheet;
      },
      touchable: () =>
        this.authService.hasPermissions([
          'full:timesheets',
          'edit:timesheets',
          'edit-status:timesheets',
        ]),
      alignCenter: true,
      sortable: true,
      accessor: (i: Timesheet) => i.Status,
    },
    {
      label: 'Last Edit',
      slug: 'LastEdit',
      keyValue: 'LastEditBy',
      alignCenter: true,
      sortable: true,
      accessor: (i: Timesheet) => i.LastEdit,
    },
    {
      label: 'Authorized By',
      slug: 'AuthorisedBy',
      keyValue: 'AuthorisedBy',
      alignCenter: true,
      sortable: true,
      accessor: (i: Timesheet) => i.AuthorisedBy,
    },
    {
      label: 'Paid?',
      slug: 'Paid',
      keyValue: 'Paid',
      alignCenter: true,
      sortable: true,
      icon: true,
      iconUrl: (i: Timesheet) =>
        i.Paid
          ? '/assets/icons/timesheet/green-checkmark-icon.svg'
          : '/assets/icons/timesheet/red-x-icon.svg',
    },
    {
      label: 'Invoiced?',
      slug: 'Invoiced',
      keyValue: 'invoiced',
      alignCenter: true,
      sortable: true,
      icon: true,
      iconUrl: (i: Timesheet) =>
        i.invoiced
          ? '/assets/icons/timesheet/green-checkmark-icon.svg'
          : '/assets/icons/timesheet/red-x-icon.svg',
    },
    {
      label: 'Gross Profit',
      slug: 'GrossProfit',
      keyValue: 'GrossProfit',
      alignCenter: true,
      sortable: true,
      accessor: (i: Timesheet) => i.GrossProfit,
      htmlData: true,
      conditionalClass: (i: any) => !isNaN(parseInt(i.GrossProfit)) && Math.sign(parseInt(i.GrossProfit)) < 0 ? 'text-danger' : ''
    },
    {
      label: 'Checked?',
      slug: 'checked',
      keyValue: 'checked',
      alignCenter: true,
      sortable: true,
      dataType: 'actions',
      actions: [
        {
          label: '',
          type: 'checkbox',
          action: (row: Timesheet) => {
            this.timesheetApi
              .edit(row.id, { checked: row.checked })
              .subscribe();
          },
        },
      ],
    },
    {
      label: 'Actions',
      slug: 'Actions',
      keyValue: 'Actions',

      multiAction: true,
      multiActions: [
        {
          label: 'Authorised',
          slug: 'Authorised',
          keyValue: 'Authorised',

          validator: (row: any) => {
            /* Allow user to authorize if pickups has value regardless attempted deliveries is 0 */
            return row.Pickups >= 0 || row.AttemptedDeliveries >=  0;
          },
          touchable: (authStatus: string) => {
            if (authStatus === 'authorise')
              return this.authService.hasPermissions([
                'full:timesheets',
                'edit:timesheets',
                'edit-authorise:timesheets',
              ]);
            if (authStatus === 'unauthorise')
              return this.authService.hasPermissions([
                'full:timesheets',
                'edit:timesheets',
                'edit-unauthorise:timesheets',
              ]);
            return false;
          },
          accessor: (i: Timesheet) => i.Authorised,
          onClick: (rowNumber: number, row: any, value: boolean) => {
            this.editTimesheet(rowNumber, row.id, { Authorised: value });
            this.data[rowNumber] = {
              ...row,
              Authorised: value,
            };
          },
        },
        {
          label: 'Route',
          slug: 'Routes',
          keyValue: 'Route',
          outlink: true,
          accessor: (i: Timesheet) => !!i.Routes.length,
          onClick: (i: Timesheet) => {
            i.Routes.reduce((promise, route) => {
              return promise.then(
                () =>
                  new Promise<void>((resolve) => {
                    const newWindow = window.open(route, '_blank');
                    if (newWindow) {
                      resolve();
                    } else {
                      alert(
                        'Pop-up blocked! Please allow pop-ups for this site.'
                      );
                      resolve();
                    }
                  })
              );
            }, Promise.resolve());
          },
        },
        {
          label: 'Comments',
          slug: 'Comments',
          keyValue: 'Comments',
          poppable: true,
          popupicon: '/assets/icons/offers/chevron-vertical.svg',
          popupdata: (i: Timesheet) => ({
            ...i.Comments,
            isRoster: this.authService.hasPermissions([
              'full:timesheets',
              'edit:timesheets',
              'edit-add/edit-roster-comment:timesheets',
            ]),
            isTimesheet: this.authService.hasPermissions([
              'full:timesheets',
              'edit:timesheets',
              'edit-add/edit-timesheet-comment:timesheets',
            ]),
          }),
          invoker: (rowNumber: number, row: any, updatedData: any) => {
            if (
              !this.data[rowNumber].Viewed &&
              updatedData === 'Reviewed' &&
              row.CommentExist
            ) {
              this.readComments(row.id);
            }
            if (updatedData?.Roster || updatedData?.Timesheet) {
              if (
                this.data[rowNumber].Comments?.Roster !== updatedData.Roster ||
                this.data[rowNumber].Comments?.Timesheet !==
                  updatedData.Timesheet
              ) {
                this.editTimesheet(rowNumber, row.id, {
                  Comments: updatedData,
                });
              }
              if (!this.data[rowNumber].Viewed) this.readComments(row.id);
              this.data[rowNumber] = {
                ...row,
                Viewed: true,
                Comments: { ...updatedData },
                CommentExist: true,
              };
            }
          },
          touchable: (authStatus: string) =>
            this.authService.hasPermissions([
              'full:timesheets',
              'edit:timesheets',
              'edit-add/edit-roster-comment:timesheets',
              'edit-add/edit-timesheet-comment:timesheets',
            ]),
        },
        {
          label: 'Delete',
          slug: 'Delete',
          keyValue: 'Delete',
          poppable: true,
          popupicon: '/assets/icons/timesheet/dots-vertical.svg',
          popupdata: (i: Timesheet) => {},
          invoker: (rowNumber: number, row: any, updatedData: string) => {
            this.deletingTimesheet = row;
            this.modalStatus = 'DELETE_CONFIRM';
          },
          touchable: () =>
            this.authService.hasPermissions([
              'full:timesheets',
              'edit:timesheets',
              'edit-delete-timesheet:timesheets',
            ]),
        },
      ],

      alignCenter: true,
      // dataType: 'actions',
      // sortable: true,
      // accessor: (i: Timesheet) => i.Authorised,
    },
  ];

  public sortedColumn: { columnName: string } = {
    columnName: 'date',
  };
  public timesheetCreationForm!: FormGroup;
  public payintime: string;
  public payouttime: string;
  public fromDate: Date = new Date();
  public toDate: Date = new Date();
  public selectedWeekRange: string = '';

  constructor(
    private timesheetApi: TimesheetAPIService,
    private authService: LoginService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {
    this.fromDate.setDate(this.fromDate.getDate() - 1);
    this.toDate.setDate(this.toDate.getDate() - 1);
    this.payintime = '06:00 AM';
    this.payouttime = '09:00 PM';
    this.getPastWeekRange();
    let temp = {} as TimesheetSortObj;
    Object.keys(this.sortStringObj).forEach(
      (item) =>
        (temp = {
          ...temp,
          [item]: item === 'Date' ? false : true,
        })
    );
    this.sortObj = temp;
    this.sortOrder = Object.keys(
      this.sortStringObj
    ) as (keyof TimesheetSortObj)[];
  }

  public perpageDropdown: boolean = false;
  public perPages: number[] = [20, 50, 100, 200];
  public departments$!: Observable<string[]>;
  public roles$!: Observable<string[]>;
  public drivers!: any[];

  ngOnInit(): void {
    this.initializeForm();
    this.loading = true;
    this.timesheetApi.getBusinessDetails().subscribe((res) => {
      const { data } = res;
      this.originalData = [...data];
      const states = _.groupBy(data, 'location');
      const departments = _.groupBy(data, 'department');
      const values = {
        state: Object.keys(states),
        department: Object.keys(departments),
        clockInCheck: ['flagged', 'not_flagged'],
        clockOutCheck: ['flagged', 'not_flagged'],
        shiftDefinition: ['set_run', 'sprint', 'marathon', 'flexi'],
        paid: ['yes', 'no'],
        checked: ['yes', 'no'],
        regional: ['yes', 'no'],
        tolls: ['one_way', 'return', 'no'],
        actions: [
          'authorized',
          'unauthorized',
          'with_comments',
          'without_comments',
        ],
      };
      Object.keys(this.businessDetails).forEach((keyValue: any) => {
        this.businessDetails[keyValue as FilterType] = values[
          keyValue as FilterType
        ].map(
          (item) =>
            ({
              [keyValue]: item,
              isChecked: false,
              isVisible: true,
            } as unknown as DepartmentType)
        );
      });

      this.fetchData();
    });

    this.autoRefresher = setInterval(() => {
      this.fetchData();
    }, 300000);
  }

  ngOnDestroy(): void {
    clearInterval(this.autoRefresher);
  }

  initializeForm() {
    this.timesheetCreationForm = this.formBuilder.group({
      driver: ['', [Validators.required]],
      date: ['', [Validators.required]],
      payin: ['06:00 AM', [Validators.required]],
      payout: ['09:00 PM', [Validators.required]],
      location: ['', [Validators.required]],
      department: ['', [Validators.required]],
      role: ['', [Validators.required]],
      timesheet_comments: '',
    });

    this.timesheetCreationForm
      ?.get('location')
      ?.valueChanges.subscribe((value) => {
        this.timesheetCreationForm.get('department')?.setValue('');
        this.timesheetCreationForm.get('role')?.setValue('');
        this.departments$ = this.timesheetApi
          .getDepartments(value)
          .pipe(map((res) => (res?.data || []) as string[]));
      });

    this.timesheetCreationForm
      ?.get('department')
      ?.valueChanges.subscribe((value) => {
        this.timesheetCreationForm.get('role')?.setValue('');

        this.roles$ = this.timesheetApi
          .getRoles(this.timesheetCreationForm.get('location')?.value, value)
          .pipe(map((res) => (res?.data || []) as string[]));
      });

    this.timesheetApi.getDrivers().subscribe((res) => {
      this.drivers = (res.data ?? [])
        .map((item: any) => ({
          ...item,
          FullName: `${item.FirstName} ${item.LastName}`,
        }))
        .filter(
          (item: any) => Boolean(item.FirstName) || Boolean(item.LastName)
        );
    });
  }

  isInvalidControl(controlName: string): boolean {
    const control = this.timesheetCreationForm.get(controlName) as FormControl;
    return control.invalid && (control.dirty || control.touched);
  }

  public handlePerpageChange(perPage: number) {
    this.query.perPage = perPage;
    this.perpageDropdown = false;
    this.loading = true;
    this.fetchData();
  }

  public openPerpageDropdown() {
    this.perpageDropdown = !this.perpageDropdown;
  }

  calcDayDiffs(date1: Date, date2: Date) {
    const timeDiff = Math.abs(date2.getTime() - date1.getTime());
    const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
    return daysDiff;
  }

  public handleOfferDateChange(e: string) {
    const offerDate = moment(e).format('DD/MM/YYYY');
    this.timesheetCreationForm.get('date')?.setValue(offerDate);
  }

  public handleHoursMinsClockChange(e: any, keyValue: 'payin' | 'payout') {
    this.timesheetCreationForm.get(keyValue)?.setValue(e);
  }

  public filterDrivers(drivers: any[]) {
    return drivers.filter((item) =>
      item.FullName.toLowerCase().includes(
        this.timesheetCreationForm.controls.driver.value.toLowerCase()
      )
    );
  }

  public handleSelectDriver(value: any) {
    this.timesheetCreationForm.get('location')?.setValue(value.DefaultLocation);
    this.timesheetCreationForm
      .get('department')
      ?.setValue(value.DefaultDepartment);
    this.timesheetCreationForm.get('role')?.setValue(value.DefaultRole);
    this.seletedDriverId = value.id;
  }

  public createTimesheet() {
    const formValues = this.timesheetCreationForm.value;

    if (this.timesheetCreationForm.valid) {
      this.loading = true;

      const StartTime = moment(
        `${formValues.date} 09:00 AM`,
        'DD/MM/YYYY h:mm A'
      ).format('YYYY-MM-DDTHH:mm:ss');
      const PayStartTime = moment(
        `${formValues.date} ${formValues.payin}`,
        'DD/MM/YYYY h:mm A'
      ).format('YYYY-MM-DDTHH:mm:ss');
      const PayEndTime = moment(
        `${formValues.date} ${formValues.payout}`,
        'DD/MM/YYYY h:mm A'
      ).format('YYYY-MM-DDTHH:mm:ss');

      const formData = {
        id: this.seletedDriverId,
        StartTime,
        PayStartTime,
        PayEndTime,
        Location: formValues.location,
        Department: formValues.department,
        Role: formValues.role,
        Comments: formValues.timesheet_comments,
      };

      this.timesheetApi.create(formData).subscribe(
        (res) => {
          this.loading = false;
          this.fetchData();
          this.closeModal();
        },
        (err) => {
          this.loading = false;
          NotificatorPartial.push({
            type: 'error',
            message: 'Timesheet creation failed.',
            timeout: 3000,
          });
        }
      );
    }
  }

  public editTimesheet(rowNumber: number, id: number, data: any) {
    this.loading = true;
    this.timesheetApi.edit(id, data).subscribe((res) => {
      // const dataObject = Timesheet.mapTimesheet(
      //   res.data,
      //   false,
      //   this.savedToggleObj.NetHours
      // );
      // this.data[rowNumber] = { ...dataObject } as Timesheet;
      // this.tempData[rowNumber] = { ...dataObject } as Timesheet;
      this.fetchData();
    });
  }

  downloadData() {
    if (!this.data.length)
      return NotificatorPartial.push({
        type: 'error',
        message: 'No data exist for current selection',
        timeout: 3000,
      });
    this.loading = true;
    this.fetchData(true);
  }

  uploadData(event: any) {
    const REQUIRED_COLUMNS = [
      'ID',
      'Date',
      'Department',
      'Pay In',
      'Pay Out',
      'Breaks (mins)',
      'Attempted Deliveries Paid',
      'Pickups',
      'Shift Definition',
      'Regional',
      'Tolls',
      'Authorised',
    ];

    const file = event.target.files[0];
    const reader = new FileReader();

    const validExtensions = ['.csv', '.xlsx'];

    if (file) {
      const fileName = file.name;
      const fileExt = fileName
        .substring(fileName.lastIndexOf('.'))
        .toLowerCase();
      if (!validExtensions.includes(fileExt)) {
        NotificatorPartial.push({
          type: 'error',
          message: 'File formation is incorrect.',
          timeout: 3000,
        });
        return false;
      }
    }

    // this.loading = true;

    reader.onload = (e: any) => {
      const workbook = XLSX.read(e.target.result, {
        type: 'binary',
        raw: true,
      });

      // Access the content of the first sheet
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const excelData = XLSX.utils.sheet_to_json(worksheet, {
        header: 1,
        blankrows: false,
      }) as MappingType['data'];

      this.fileInput.nativeElement.value = null;
      if (!(excelData.length > 1))
        return NotificatorPartial.push({
          type: 'error',
          message: 'Invalid or Empty file',
          timeout: 3000,
        });

      if (!this.checkArrayContainsAll(excelData[0], REQUIRED_COLUMNS))
        return NotificatorPartial.push({
          type: 'error',
          message: 'Invalid file',
          timeout: 3000,
        });

      REQUIRED_COLUMNS.forEach((column) => {
        const index = excelData[0].findIndex((item) => item === column);
        this.columnIndexes = { ...this.columnIndexes, [column]: index };
        return true;
      });
      this.excelData = excelData.slice(1);

      const departmentIndex = excelData[0].findIndex(
        (item) => item === 'Department'
      );

      const departments = _.uniq(
        excelData.slice(1).map((row) => row[departmentIndex])
      );

      this.departments2Upload = departments.map((deparment, index) => ({
        ID: index,
        Department: deparment,
        isVisible: true,
        isChecked: false,
      }));
      this.modalStatus = 'SELECT_FILE';

      return false;
    };

    reader.readAsArrayBuffer(file);
    return false;
  }

  public async handleSelectFile() {
    const indexes = this.columnIndexes;
    const filteredDepartments = this.departments2Upload.flatMap((item) => {
      if (item.isChecked) {
        return [item.Department];
      }
      return [];
    });

    if (!filteredDepartments.length)
      return NotificatorPartial.push({
        type: 'error',
        message: 'No departments are selected',
        timeout: 3000,
      });

    const filteredExcelData = this.excelData.flatMap((row, rowIndex) => {
      if (filteredDepartments.includes(row[indexes['Department']])) {
        return [{ row, rowIndex: rowIndex + 2 }];
      }
      return [];
    });

    this.importCompletion.total = filteredExcelData.length;

    const _validatedData: BulkUpdateForamt[] = [];

    filteredExcelData.forEach((data) => {
      let isValid = true;
      const { row, rowIndex } = data;
      if (isNaN(Number(row[indexes['ID']]))) {
        this.excelDataErrors.push({ row: rowIndex, column: 'ID' });
        isValid = false;
      }
      const isValidPayin = this.isValidTimeFormat(
        String(row[indexes['Pay In']]),
        'H:mm'
      );
      const isValidPayout = this.isValidTimeFormat(
        String(row[indexes['Pay Out']]),
        'H:mm'
      );
      if (!isValidPayin && String(row[indexes['Pay In']]) !== '--:--') {
        this.excelDataErrors.push({ row: rowIndex, column: 'Pay In' });
        isValid = false;
      }
      if (!isValidPayout && String(row[indexes['Pay Out']]) !== '--:--') {
        this.excelDataErrors.push({ row: rowIndex, column: 'Pay Out' });
        isValid = false;
      }
      
      const newPayOut = (String(row[indexes['Pay In']]) > String(row[indexes['Pay Out']])) ? moment(row[indexes['Date']]).add(1, 'days').format('YYYY-MM-DD') : row[indexes['Date']];
      if (
        isValidPayin &&
        isValidPayout &&
        !this.isSecondTimeAfterFirst(
          moment(String(row[indexes['Date']]) + " " + String(row[indexes['Pay In']]), 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm'),
          moment(String(newPayOut) + " " + String(row[indexes['Pay Out']]), 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm'),
          'YYYY-MM-DD H:mm'
        )
      ) {
        this.excelDataErrors.push({
          row: data.rowIndex,
          column: 'Pay Out should be past of Pay In',
        });
        isValid = false;
      }
      ['Breaks (mins)', 'Attempted Deliveries Paid', 'Pickups'].forEach(
        (item) => {
          if (isNaN(Number(row[indexes[item]]))) {
            this.excelDataErrors.push({ row: rowIndex, column: item });
            isValid = false;
          } else if (
            Number(row[indexes[item]]) < 0 ||
            !Number.isInteger(Number(row[indexes[item]]))
          ) {
            this.excelDataErrors.push({ row: rowIndex, column: item });
            isValid = false;
          } else if (
            item === 'Breaks (mins)' &&
            Number(row[indexes[item]]) > 60
          ) {
            this.excelDataErrors.push({ row: rowIndex, column: item });
            isValid = false;
          }
        }
      );
      if (
        !['true', 'false'].includes(
          String(row[indexes['Authorised']]).toLowerCase()
        )
      ) {
        this.excelDataErrors.push({ row: rowIndex, column: 'Authorised' });
        isValid = false;
      }
      if (
        !['sprint', 'marathon', 'flexi', 'set run', ''].includes(
          String(row[indexes['Shift Definition']]).toLowerCase()
        )
      ) {
        this.excelDataErrors.push({
          row: rowIndex,
          column: 'Shift Definition',
        });
        isValid = false;
      }
      if (
        'no' !== String(row[indexes['Regional']]).toLowerCase() &&
        isNaN(Number(row[indexes['Regional']]))
      ) {
        this.excelDataErrors.push({ row: rowIndex, column: 'Regional' });
        isValid = false;
      }
      if (
        !isNaN(Number(row[indexes['Regional']])) &&
        row[indexes['Regional']].length !== 4
      ) {
        this.excelDataErrors.push({ row: rowIndex, column: 'Regional' });
        isValid = false;
      }
      if (
        !['no', 'one-way', 'return'].includes(
          String(row[indexes['Tolls']]).toLowerCase()
        )
      ) {
        this.excelDataErrors.push({ row: rowIndex, column: 'Tolls' });
        isValid = false;
      }
      if (isValid)
        _validatedData.push({
          id: Number(row[indexes['ID']]),
          rowIndex,
          PayIn: isValidPayin
            ? moment(String(row[indexes['Pay In']]), 'H:mm').format('HH:mm')
            : String(row[indexes['Pay In']]),
          PayOut: isValidPayout
            ? moment(String(row[indexes['Pay Out']]), 'H:mm').format('HH:mm')
            : String(row[indexes['Pay Out']]),
          Breaks:
            Number(row[indexes['Breaks (mins)']]) > 60
              ? 60
              : Number(row[indexes['Breaks (mins)']]),
          AttemptedDeliveriesPaid: Number(
            row[indexes['Attempted Deliveries Paid']]
          ),
          Pickups: Number(row[indexes['Pickups']]),
          ShiftDefinition: String(
            row[indexes['Shift Definition']]
          ).toLowerCase(),
          Regional: String(row[indexes['Regional']]).toLowerCase(),
          Tolls: String(row[indexes['Tolls']]).toLowerCase(),
          Authorised:
            String(row[indexes['Authorised']]).toLowerCase() === 'true'
              ? true
              : false,
        });
    });

    const response = await this.timesheetApi.bulkFetch(
      _validatedData.map((item) => item.id)
    );

    let bulkData: Timesheet[] = [];
    if (response?.success)
      bulkData = response.data.map((s: any) => Timesheet.mapTimesheet(s, true));
    else
      return NotificatorPartial.push({
        type: 'error',
        message: 'Something went wrong',
        timeout: 3000,
      });

    const updatedTimesheets: {
      originTimesheet: Timesheet;
      updatedTimesheet: BulkUpdateForamt;
    }[] = [];

    _validatedData.forEach((data) => {
      const corr = bulkData.find((item) => item.id === data.id);
      if (!corr) return true;
      corr.Regional = corr.Regional.toLowerCase();
      corr.Tolls = corr.Tolls.toLowerCase();
      const isEqual = _.isEqual(
        _.pick(corr, COMPARISON_FIELDS),
        _.pick(data, COMPARISON_FIELDS)
      );

      if (!isEqual)
        updatedTimesheets.push({
          originTimesheet: corr,
          updatedTimesheet: data,
        });

      return true;
    });
    const final_updates: any[] = [];

    updatedTimesheets.forEach((timesheets) => {
      const { originTimesheet, updatedTimesheet } = timesheets;
      if (!originTimesheet.Existing_User) {
        this.excelDataErrors.push({
          row: updatedTimesheet.rowIndex,
          column: 'Cannot update, inactive user row',
        });
        return true;
      }
      if (originTimesheet.IsDisabled) {
        this.excelDataErrors.push({
          row: updatedTimesheet.rowIndex,
          column: 'Timesheet is still active',
        });
        return true;
      }

      if (
        originTimesheet.ShiftDefinition.toLowerCase() !==
        updatedTimesheet.ShiftDefinition
      ) {
        const valid = originTimesheet.Avaialble_Shift_Definitions.map(
          (definition) => String(definition.item).toLowerCase()
        ).includes(updatedTimesheet.ShiftDefinition);
        if (originTimesheet.Avaialble_Shift_Definitions.length) {
          if (!valid) {
            this.excelDataErrors.push({
              row: updatedTimesheet.rowIndex,
              column: 'Shift definition',
            });
            return true;
          }
        } else {
          if (updatedTimesheet.ShiftDefinition !== '') {
            this.excelDataErrors.push({
              row: updatedTimesheet.rowIndex,
              column: 'Shift definition',
            });
            return true;
          }
        }
      }

      let updates: { [key: string]: any } = {};

      if (originTimesheet.PayIn !== updatedTimesheet.PayIn) {
        if (updatedTimesheet.PayIn === '--:--') {
          this.excelDataErrors.push({
            row: updatedTimesheet.rowIndex,
            column: 'Pay In',
          });
          return true;
        } else
          updates = {
            ...updates,
            PayStartTime: this.getUpdatedTime(
              originTimesheet.Date,
              updatedTimesheet.PayIn,
              true
            ),
          };
      }

      if (originTimesheet.PayOut !== updatedTimesheet.PayOut) {
        let newPayEndTime = this.getUpdatedTime(originTimesheet.Date,updatedTimesheet.PayOut,true)
        if (
          updatedTimesheet.PayOut === '--:--' ||
          updatedTimesheet.PayIn === '--:--'
        ) {
          this.excelDataErrors.push({
            row: updatedTimesheet.rowIndex,
            column: 'Pay Out',
          });
          return true;
        } else
          if (updatedTimesheet.PayIn > updatedTimesheet.PayOut) {
            /* Add 1 day to pay end time */
            newPayEndTime = moment(newPayEndTime).add(1, 'days').format('YYYY-MM-DDTHH:mm:ss');
          }
          updates = {
            ...updates,
            PayEndTime: newPayEndTime
          };
      }

      const isEqual = _.isEqual(
        _.pick(originTimesheet, COMPARISON_FIELDS.slice(3)),
        _.pick(updatedTimesheet, COMPARISON_FIELDS.slice(3))
      );

      if (updatedTimesheet.PayOut === '--:--' && !isEqual) {
        let message = '';
        if (originTimesheet.Authorised !== updatedTimesheet.Authorised)
          message = 'Cannot authorise, without Payout';
        else message = 'Cannot update, without Payout';
        this.excelDataErrors.push({
          row: updatedTimesheet.rowIndex,
          column: message,
        });
        return true;
      }

      if (originTimesheet.Breaks !== updatedTimesheet.Breaks) {
        if (updatedTimesheet.PayOut !== '--:--') {
          const GrossMinutes = this.getDiffsInMinutes(
            updatedTimesheet.PayIn,
            updatedTimesheet.PayOut,
            'H:mm',
            originTimesheet.StartTime
          );
          if (updatedTimesheet.Breaks > GrossMinutes) {
            this.excelDataErrors.push({
              row: updatedTimesheet.rowIndex,
              column: 'Breaks',
            });
            return true;
          }
          const NetMinutes = GrossMinutes - updatedTimesheet.Breaks;
          updates = {
            ...updates,
            Breaks: updatedTimesheet.Breaks,
            GrossMinutes,
            NetMinutes,
            GrossMinutes_Delta:
              originTimesheet.GrossMinutes_Delta +
              originTimesheet.GrossMinutes -
              GrossMinutes,

            NetMinutes_Delta:
              originTimesheet.NetMinutes_Delta +
              originTimesheet.NetMinutes -
              NetMinutes,
          };
        } else {
          this.excelDataErrors.push({
            row: updatedTimesheet.rowIndex,
            column: 'Breaks',
          });
          return true;
        }
      } else {
        if (updatedTimesheet.PayOut !== '--:--') {
          const GrossMinutes = this.getDiffsInMinutes(
            updatedTimesheet.PayIn,
            updatedTimesheet.PayOut,
            'H:mm',
            originTimesheet.StartTime
          );
          const Breaks = this.getBreaks(GrossMinutes);
          const NetMinutes = GrossMinutes - Breaks;
          if (
            originTimesheet.Breaks !== Breaks &&
            (updatedTimesheet.PayIn !== originTimesheet.PayIn ||
              updatedTimesheet.PayOut !== originTimesheet.PayOut)
          ) {
            updates = {
              ...updates,
              Breaks,
              GrossMinutes,
              GrossMinutes_Delta:
                originTimesheet.GrossMinutes_Delta +
                originTimesheet.GrossMinutes -
                GrossMinutes,
              NetMinutes,
              NetMinutes_Delta:
                originTimesheet.NetMinutes_Delta +
                originTimesheet.NetMinutes -
                NetMinutes,
            };
          }
        }
      }

      if (
        originTimesheet.AttemptedDeliveriesPaid !==
        updatedTimesheet.AttemptedDeliveriesPaid
      ) {
        updates = {
          ...updates,
          Attempted_Deliveries_Paid: updatedTimesheet.AttemptedDeliveriesPaid,
        };
      }
      if (
        originTimesheet.ShiftDefinition.toLowerCase() !==
        updatedTimesheet.ShiftDefinition
      ) {
        updates = {
          ...updates,
          Shift_Definition: SHIFT_DEF_MATCHES[updatedTimesheet.ShiftDefinition],
        };
      }
      if (originTimesheet.Pickups !== updatedTimesheet.Pickups) {
        updates = {
          ...updates,
          summary: {
            data: {
              ...originTimesheet.SummaryData,
              pick_up: updatedTimesheet.Pickups,
            },
          },
        };
      }
      if (
        originTimesheet.Regional.toLowerCase() !== updatedTimesheet.Regional
      ) {
        updates = {
          ...updates,
          summary: {
            ...updates.summary,
            ...(updates.summary?.data
              ? {}
              : { data: originTimesheet.SummaryData }),
            regional:
              updatedTimesheet.Regional === 'no'
                ? 'ZZZ'
                : updatedTimesheet.Regional,
          },
        };
      }
      if (originTimesheet.Tolls.toLowerCase() !== updatedTimesheet.Tolls) {
        updates = {
          ...updates,
          summary: {
            ...updates.summary,
            ...(updates.summary?.data
              ? {}
              : { data: originTimesheet.SummaryData }),
            road_tolls: TOLL_MATCHES[updatedTimesheet.Tolls],
          },
        };
      }
      if (originTimesheet.Authorised !== updatedTimesheet.Authorised) {
        updates = {
          ...updates,
          Authorised: updatedTimesheet.Authorised,
        };
      }
      if (Object.keys(updates).length)
        final_updates.push({ ...updates, id: updatedTimesheet.id });
      return true;
    });

    this.importCompletion.error = this.excelDataErrors.length;
    this.loading = true;
    this.timesheetApi.bulkUpdate(final_updates).subscribe(
      (res) => {
        this.fetchData();
        this.importCompletion.success = final_updates.length;
        this.modalStatus = 'IMPORT_CONFIRMATION';
      },
      (err) => {
        this.loading = false;
        return NotificatorPartial.push({
          type: 'error',
          message: err.error.message,
          timeout: 3000,
        });
      }
    );
  }

  public isValidTimeFormat(timeString: string, format: string) {
    const timeMoment = moment(timeString, format, true); // Setting strict parsing mode

    return timeMoment.isValid();
  }

  public isSecondTimeAfterFirst(
    firstTime: string,
    secondTime: string,
    format: string
  ) {
    const firstMoment = moment(firstTime, format);
    const secondMoment = moment(secondTime, format);

    return secondMoment.isAfter(firstMoment);
  }

  public getQuickSearchResult(data: Department2UploadType[]) {
    return data.filter((item) => item.isVisible);
  }

  public quickSearchDepartment(value: string, dataKey: string) {
    const data = this?.[
      dataKey as keyof this
    ] as unknown as Department2UploadType[];

    data.forEach((item, itemIndex) => {
      if (item.Department.toLowerCase().includes(value.toLowerCase())) {
        data[itemIndex].isVisible = true;
      } else {
        data[itemIndex].isVisible = false;
      }
    });
  }

  public setDepartment2UploadCheck(
    checked: boolean,
    row: Department2UploadType,
    dataKey: string
  ) {
    const data = this?.[
      dataKey as keyof this
    ] as unknown as Department2UploadType[];

    const index = data.findIndex((item) => item.ID === row.ID);
    data[index].isChecked = checked;
  }

  public readComments(id: number) {
    this.timesheetApi.markAsRead(id).subscribe((res) => {});
  }

  public deleteTimesheet(id: number) {
    this.loading = true;
    this.closeModal();
    this.timesheetApi.delete(id).subscribe((res) => {
      this.loading = false;
      this.fetchData();
    });
  }

  public selectDefaultDay(day: string) {
    if (this.defaultDay !== day) {
      this.defaultDay = day;
      this.fromDate = new Date();
      this.toDate = new Date();
      this.getFromToDates(this.defaultDay);
      this.fetchData();
    }
  }

  public saveFilters() {
    const location = this.filteredData.state.map((item) => item.label);
    const department = this.filteredData.department.map((item) => item.label);

    this.timesheetApi
      .saveFilters({
        page: 'timesheets',
        data: {
          location,
          department,
          defaultDay: this.defaultDay,
          perPage: this.query.perPage,
          collapsedColumns: this.savedCollapsedColumns,
          toggleObj: this.savedToggleObj,
        },
      })
      .subscribe((res) => {
        return NotificatorPartial.push({
          type: 'success',
          message: 'Current filter settings are saved.',
          timeout: 3000,
        });
      });
  }

  public previouspage() {
    if (this.query.page && this.query.page > 1) {
      this.query.page = this.query.page - 1;
      this.loading = true;
      this.fetchData();
    }
  }
  public nextpage() {
    if (this.query.page && this.maxPage > this.query.page) {
      this.query.page = this.query.page + 1;
      this.loading = true;
      this.fetchData();
    }
  }

  public getUpdatedTime(
    dateString: string,
    timeString: string,
    optional = false
  ) {
    const date = moment(dateString, 'DD/MM/YYYY').format('YYYY-MM-DD');
    if (optional) return `${date}T${timeString}:00`;
    const hours = timeString.slice(0, 2);
    const minutes = timeString.slice(2, 4);
    return `${date}T${hours}:${minutes}:00`;
  }

  public fetchData(isExport = false, isPayment = false, isInvoice = false, colSortValue = "") {
    // Make it loading status
    // if (!isRefresh) this.loading = true;

    // Set the start date buy 0 o'clock and end date to 23:59 of the dates.
    this.fromDate.setHours(0);
    this.fromDate.setMinutes(0);
    this.toDate.setHours(23);
    this.toDate.setMinutes(59);
    this.payRange.startRange?.setHours(0);
    this.payRange.startRange?.setMinutes(0);
    this.payRange.endRange?.setHours(23);
    this.payRange.endRange?.setMinutes(59);

    // Convert dates to utc format
    const utcFromDate = moment(
      isPayment ? this.payRange.startRange : this.fromDate
    ).format('YYYY-MM-DDTHH:mm:ss');
    const utcToDate = moment(
      isPayment ? this.payRange.endRange : this.toDate
    ).format('YYYY-MM-DDTHH:mm:ss');

    // Get POD data by passing search keyword and filter options

    const orderArr = this.sortOrder.map(
      (item) =>
        `${this.sortStringObj[item]} ${this.sortObj[item] ? 'ASC' : 'DESC'}`
    );
    const authorisedAction: boolean[] = [];
    const commentsActions: boolean[] = [];

    this.filteredData.actions.forEach((item) => {
      switch (item.label) {
        case 'authorized':
          authorisedAction.push(true);
          break;
        case 'unauthorized':
          authorisedAction.push(false);
          break;
        case 'with_comments':
          commentsActions.push(true);
          break;
        case 'without_comments':
          commentsActions.push(false);
          break;

        default:
          break;
      }
      return true;
    });

    this.timesheetApi
      .index(
        this.query,
        // String('2023-12-06 13:00:00'),
        String(utcFromDate),
        String(utcToDate),
        this.filteredData.state.map((item) => item.label),
        isInvoice
          ? this.seekedDepartments.flatMap((item) => {
              if (item.isChecked) return [item.Department];
              return [];
            })
          : this.filteredData.department.map((item) => item.label),
        this.filteredData.clockInCheck.map((item) => item.label),
        this.filteredData.clockOutCheck.map((item) => item.label),
        authorisedAction,
        commentsActions,
        this.filteredData.shiftDefinition.map(
          (item) => this.shiftDefinitions[item.label]
        ),
        this.filteredData.tolls.map((item) =>
          this.filterOptionValues['tolls'][item.label] === 'No'
            ? 'ZZZ'
            : this.filterOptionValues['tolls'][item.label]
        ),
        this.filteredData.regional.map((item) =>
          this.filterOptionValues['regional'][item.label] === 'No'
            ? 'ZZZ'
            : this.filterOptionValues['regional'][item.label]
        ),
        this.filteredData.paid.map((item) => item.label),
        this.filteredData.checked.map((item) => item.label),
        orderArr,
        this.useSavedFilter,
        isExport,
        isPayment,
        isInvoice
      )
      .subscribe(
        (r) => {
          let res = CodedResponseModel.decode(r);
          this.holidays = res.holidays ?? [];
          this.loading = false;
          if (isExport) {
            const _exportData = res.data.map((s: any) =>
              Timesheet.mapTimesheet(s, isExport, this.savedToggleObj.NetHours)
            );
            let row = 1;
            const exportData = _exportData.map((item: Timesheet, index: any) => {
                row++;
                return EXPORT_COLUMNS.map((key) => {
                  let format = '@';
                  let formula = '';
                  let value = String(item[key]);
                  if (key == 'Date') {
                    format = '@';
                    value = moment(item[key], "DD-MM-YYYY").format('YYYY-MM-DD');
                  } else if (key == 'Breaks') {
                    formula = `=TEXT(AC${row}/24,"hh:mm")*1440`;
                  } else if (key == 'GrossHours') {
                    formula = `=HOUR(TEXT((((IF(L${row}>M${row},TEXT(B${row}+1, "YYYY-MM-DD"), B${row}) & " " & TEXT(M${row}, "HH:MM AM/PM"))-(TEXT(B${row}, "YYYY-MM-DD") & " " & TEXT(L${row}, "HH:MM AM/PM")))*24)/24, "hh:mm")) & "h " & MINUTE(TEXT((((IF(L${row}>M${row},TEXT(B${row}+1, "YYYY-MM-DD"), B${row}) & " " & TEXT(M${row}, "HH:MM AM/PM"))-(TEXT(B${row}, "YYYY-MM-DD") & " " & TEXT(L${row}, "HH:MM AM/PM")))*24)/24, "hh:mm")) & "m"`;
                  } else if (key == 'GrossHoursNumber') {
                    formula = `=(((IF(L${row}>M${row},TEXT(B${row}+1, "YYYY-MM-DD"), B${row}) & " " & TEXT(M${row}, "HH:MM AM/PM"))-(TEXT(B${row}, "YYYY-MM-DD") & " " & TEXT(L${row}, "HH:MM AM/PM")))*24)`;
                    format = '0.0';
                  } else if (key == 'BreaksNumber') {
                    formula = `=IF(AND(AB${row}>=5.5,AB${row}<8),0.25,IF(AND(AB${row}>=8,AB${row}<11),0.5,IF(AB${row}>=11,1,0)))`;
                  }
                  return {
                    v: value,
                    f: formula,
                    z: format,
                    s: { alignment: { vertical: 'top', horizontal: 'left' } },
                  }
                });
              }
            );
            exportAsExcel(
              [EXPORT_MODEL, ...exportData],
              COLUMN_WIDTH_DEFS,
              'Timesheets'
            );
            return;
          }
          if (isPayment) {
            const timesheets: Timesheet[] = res.data.map((s: any) =>
              Timesheet.mapTimesheet(s, true, true)
            );
            this.loading_rates = res.loading_rates ?? [];
            this.loading_charge = res.loading_charges ?? [];
            this.driver_roles = res.driver_roles ?? [];
            const invoice_number = res.invoice_number;
            const { sunday } = this.getWeekRange(
              this.payRange.startRange as Date
            );
            const formattedSunday = moment(sunday).format('DD/MM/YYYY');
            if (isInvoice) {
              const groupedByDepartment = _.groupBy(timesheets, 'Department');
              const clientInvoices = Object.keys(groupedByDepartment)
                .map((department, index) => {
                  const newInvoiceNumber = invoice_number + index + 1;
                  return groupedByDepartment[department]
                    .map((timesheet) =>
                      this.generateInvoice_v2(
                        timesheet,
                        newInvoiceNumber,
                        formattedSunday
                      )
                    )
                    .flat();
                })
                .flat();

              const loadingCharge = clientInvoices
                .map((invoice) => this.applyLoadingCharge_v2(invoice))
                .flat();
              const grouped = _.groupBy(
                [...clientInvoices, ...loadingCharge],
                'Department'
              );
              const fuelSurcharges = Object.keys(grouped)
                .map((keyValue) => {
                  const _grouped = _.groupBy(grouped[keyValue], 'Role');
                  return Object.keys(_grouped)
                    .map((_keyValue) =>
                      this.getFuelSurcharge(_grouped?.[_keyValue])
                    )
                    .flat();
                })
                .flat();
              const export_data = _.orderBy(
                [
                  ..._.orderBy(
                    [...clientInvoices, ...loadingCharge],
                    ['InvoiceNumber', '_Date', 'JobStart', 'id', 'shiftDef'],
                    ['asc', 'asc', 'asc', 'asc', 'asc']
                  ),
                  ...fuelSurcharges,
                ],
                ['InvoiceNumber', 'type', '_Date', 'JobStart', 'Role'],
                ['asc', 'asc', 'asc', 'asc', 'asc']
              );

              if (!export_data.length)
                return NotificatorPartial.push({
                  type: 'warning',
                  message: 'No data to be processed',
                  timeout: 3000,
                });
              const columns = getInvoiceExportColumns('Client');
              const exportData = _.map(export_data, (item) =>
                _.pick(item, columns)
              );
              exportCSV(exportData, 'Invoice');
              this.fetchData(); /* Re-fetch to get latest records */
              return NotificatorPartial.push({
                type: 'success',
                message: 'Successfully processed',
                timeout: 3000,
              });
            }
            const result = this.getPayments(timesheets, false);
            const invoices = result.invoices ?? [];
            const loadingCharge = result.loadingCharge ?? [];
            const fuelBonuses = result.fuelBonuses ?? [];
            const adminFees = result.adminFees ?? [];

            const export_data = _.orderBy(
              [
                ..._.orderBy(
                  [...invoices, ...loadingCharge],
                  ['ContactName', '_Date', 'JobStart', 'id', 'shiftDef'],
                  ['asc', 'asc', 'asc', 'asc', 'asc']
                ),
                ...fuelBonuses,
                ...adminFees,
              ],
              ['ContactName', 'type', '_Date', 'JobStart', 'Role'],
              ['asc', 'asc', 'asc', 'asc', 'asc']
            );

            if (!export_data.length)
              return NotificatorPartial.push({
                type: 'warning',
                message: 'No data to be processed',
                timeout: 3000,
              });
            const processedOnes: number[] = [];
            const columns = getInvoiceExportColumns('Driver');

            const exportData = _.map(export_data, (item) => {
              processedOnes.push(item.id as number);
              return _.pick(item, columns);
            });
            exportCSV(exportData, 'Processed Payments');

            this.timesheetApi.processPayments(processedOnes).subscribe(
              (r) => {
                return NotificatorPartial.push({
                  type: 'success',
                  message: 'Successfully processed',
                  timeout: 3000,
                });
              },
              (e) => {
                return NotificatorPartial.push({
                  type: 'error',
                  message: e.error.message,
                  timeout: 3000,
                });
              }
            );
            return;
          }
          this.data = [];
          this.tempData = [];
          this.filtered = res.filtered;
          this.authorised = res.authorised;
          this.totalNetHours = '0.00';
          this.totalCharge = '0.00';
          this.totalPayment = '0.00';
          this.totalGross = '0.00';
          let totalNetMinutes: number = 0;
          let totalCharges: number = 0;
          this.total = res.total;
          if (res?.saved_filter) {
            const savedFilters = res?.saved_filter;
            this.filteredData.state = savedFilters.location.map(
              (item: any) => ({
                label: item,
              })
            );
            this.filteredData.department = savedFilters.department.map(
              (item: any) => ({
                label: item,
              })
            );
            this.fromDate = new Date();
            this.toDate = new Date();
            this.getFromToDates(savedFilters.defaultDay);
            this.query.perPage = savedFilters.perPage;
            this.defaultDay = savedFilters.defaultDay;

            this.businessDetails.state = this.businessDetails.state.map(
              (item) => ({
                ...item,
                isChecked: savedFilters.location.includes(item.state),
              })
            );
            this.businessDetails.department =
              this.businessDetails.department.map((item) => ({
                ...item,
                isChecked: savedFilters.department.includes(item.department),
              }));
            this.useSavedFilter = false;
            this.savedCollapsedColumns = savedFilters?.collapsedColumns ?? [];
            if (savedFilters?.toggleObj)
              this.savedToggleObj = savedFilters.toggleObj;
          }
          this.loading_rates = res.loading_rates ?? [];
          this.loading_charge = res.loading_charges ?? [];
          this.driver_roles = res.driver_roles ?? [];
          const timesheets: Timesheet[] = res.data.map((s: any) =>
            Timesheet.mapTimesheet(s, true, true)
          );          
          const result = this.getPayments(timesheets, true);
          const totalPayments =
            [...result.invoices, ...result.loadingCharge, ...result.fuelBonuses, ...result.adminFees].reduce(
              (total, currentValue) =>
                (total +=
                  Number(currentValue.Quantity) *
                  Number(currentValue.UnitAmount)),
              0
            ) || 0;
          // Initialize table data
          
          res.data.forEach((s: any) => {
            const dataObject = Timesheet.mapTimesheet(
              s,
              false,
              this.savedToggleObj.NetHours
            );
            totalNetMinutes += Number(dataObject?.NetMinutes);
            dataObject.GrossProfit = '0';
            if(dataObject.Paid || !dataObject.Paid) {
              const result = this.getPayments([dataObject], false);
              const dInvoices = result.invoices;
              if (dInvoices.length) {
                const dLoadingCharges = result.loadingCharge;
                const dFuelBonuses = result.fuelBonuses;
                const adminFees = result.adminFees;
                const driverPayingAmount =
                  [...dInvoices, ...dLoadingCharges, ...dFuelBonuses, ...adminFees].reduce(
                    (total, currentValue) =>
                      (total +=
                        Number(currentValue.Quantity) *
                        Number(currentValue.UnitAmount)),
                    0
                  ) || 0;
                const cInvoices = this.generateInvoice_v2(dataObject, 0, '#');
                const cLoadingCharges = cInvoices
                  .map((item) => this.applyLoadingCharge_v2(item))
                  .flat();
                const cFuelSurcharges = this.getFuelSurcharge([
                  ...cInvoices,
                  ...cLoadingCharges,
                ]);
                const clientChargingAmount = [
                  ...cInvoices,
                  ...cLoadingCharges,
                  ...cFuelSurcharges,
                ].reduce(
                  (total, currentValue) =>
                    (total +=
                      Number(currentValue.Quantity) *
                      Number(currentValue.UnitAmount)),
                  0
                ) || 0;
                dataObject.GrossProfit = (
                  clientChargingAmount - driverPayingAmount
                ).toFixed(2);
                totalCharges += clientChargingAmount;
              }
            }
            this.data.push({ ...dataObject } as Timesheet);
            this.tempData.push({ ...dataObject } as Timesheet);
            return true;
          });

          /* Sort column after finalizing the value */
          if (colSortValue == 'GrossProfit') {
            const grossProftIdx = orderArr.findIndex(col => col.includes('GrossProfit'));
            if (orderArr[grossProftIdx].includes('DESC')) {
              this.data.sort((a,b) => a.GrossProfit.localeCompare(b.GrossProfit, undefined, { numeric: true, sensitivity: 'base'})).reverse();
            } else {
              this.data.sort((a,b) => a.GrossProfit.localeCompare(b.GrossProfit, undefined, { numeric: true, sensitivity: 'base'}));

            }
          }


          this.totalNetHours = getHours(totalNetMinutes);
          this.totalCharge = formatUSD(totalCharges);
          this.totalPayment = formatUSD(totalPayments);
          this.totalGross = formatUSD(totalCharges - totalPayments);
        },
        (e) => {
          return NotificatorPartial.push({
            type: 'error',
            message: e.error.message,
            timeout: 3000,
          });
        }
      );
  }

  // Remove inputs in search driver field
  public clearSearch() {
    this.query.filters!.search = '';
    this.reset();
    this.fetchData();
  }

  // Define action for fromdate change
  public handleFromDateChange(e: string) {
    const fromDate = new Date(e);
    const toDate = new Date(this.toDate);

    // If the user select past date of todate for fromdate, it will automatically set the same date for todate with fromdate
    if (fromDate.getTime() > toDate.getTime()) this.toDate = fromDate;
    this.reset();
    this.loading = true;
    this.fetchData();
  }

  // Define action for fromdate change
  public handleToDateChange(e: string) {
    const fromDate = new Date(this.fromDate);
    const toDate = new Date(e);

    // If the user select previous date of fromdate for todate, it will automatically set the same date for fromdate with todate
    if (fromDate.getTime() > toDate.getTime()) this.fromDate = toDate;
    this.reset();
    this.loading = true;
    this.fetchData();
  }

  public fromPayrangeChange(e: string) {
    const fromDate = new Date(e);
    const weekRange = this.getWeekRange(fromDate);

    if (this.payRange.endRange) {
      if (
        fromDate.getTime() > this.payRange.endRange.getTime() ||
        weekRange.sunday.getTime() < this.payRange.endRange.getTime()
      )
        this.payRange.endRange = null;
    }
    if (!!this.payRange.endRange) {
      this.seekEligible();
    }
  }

  public toPayrangeChange(e: string) {
    const toDate = new Date(e);
    const weekRange = this.getWeekRange(toDate);

    if (this.payRange.startRange)
      if (
        this.payRange.startRange.getTime() > toDate.getTime() ||
        weekRange.monday.getTime() > this.payRange.startRange.getTime()
      )
        this.payRange.startRange = null;

    if (!!this.payRange.startRange) {
      this.seekEligible();
    }
  }

  seekEligible() {
    const { startRange, endRange } = this.payRange;
    startRange?.setHours(0);
    startRange?.setMinutes(0);
    endRange?.setHours(23);
    endRange?.setMinutes(59);

    const startDate = moment(startRange).format('YYYY-MM-DDTHH:mm:ss');
    const endDate = moment(endRange).format('YYYY-MM-DDTHH:mm:ss');
    this.timesheetApi.seekInvoiceEligible(startDate, endDate).subscribe((r) => {
      let res = CodedResponseModel.decode(r);
      this.seekedDepartments = (res?.data ?? []).map(
        (item: any, itemIndex: number) => ({
          ...item,
          ID: itemIndex,
          isVisible: true,
          isChecked: false,
        })
      );
    });
  }

  // Returns formated time with DD/MM/YY format
  getFormattedTime(value: Date | null) {
    if (!value) return '';
    return moment(value).format('DD/MM/YY');
  }

  public getFormattedClocktime(time: Date) {
    const hours = time.getHours();
    const minutes = time.getMinutes();
    const isAfternoon = hours >= 12;
    return `${
      isAfternoon ? (hours === 12 ? 12 : hours - 12) : hours
    }:${minutes} ${isAfternoon ? 'PM' : 'AM'}`;
  }

  public handleVisivility(
    key: FilterType | 'drawer',
    value: boolean | null = null
  ) {
    // If the user close filter window without apply, it will ignore what the user newly checked.
    if (key === 'drawer' && value === false) {
      Object.keys(this.businessDetails).forEach((item) => {
        this.businessDetails[item as FilterType].forEach(
          (subItem, subItemIndex) => {
            this.businessDetails[item as FilterType][subItemIndex].isChecked =
              this.filteredData[item as FilterType]
                .map((anotherSub) => anotherSub.label)
                .includes(subItem[item as FilterType]);
          }
        );
      });
    }

    // It does expand action for state, department in filter and filter modal.
    if (value !== null) {
      this.isExpanded[key] = value;
      return;
    }
    this.isExpanded[key] = !this.isExpanded[key];
  }

  // This function define actions when user click apply button in filter modal.
  public handleFilterApply() {
    this.isExpanded.drawer = false;
    this.filteredData = {
      state: [],
      department: [],
      clockInCheck: [],
      clockOutCheck: [],
      actions: [],
      shiftDefinition: [],
      regional: [],
      tolls: [],
      paid: [],
      checked: [],
    };
    const filteredstates: string[] = [];

    // Stored filtered states data.
    this.businessDetails.state.forEach((item) => {
      if (item.isChecked) {
        filteredstates.push(item.state);
      }
    });

    // If the filtered states data is not empty, it will also fiter departments data which are related to filtered states.
    if (filteredstates.length) {
      const departments = this.originalData
        .filter((item) => filteredstates.includes(item.location))
        .map((item) => item.department);

      this.businessDetails.department = this.businessDetails.department.map(
        (item) => ({
          ...item,
          isChecked: departments.includes(item.department)
            ? item.isChecked
            : false,
        })
      );
    }

    Object.keys(this.businessDetails).forEach((keyValue) => {
      this.businessDetails[keyValue as FilterType].forEach((item) => {
        if (item.isChecked) {
          this.filteredData[keyValue as FilterType].push({
            label: item[keyValue as FilterType],
          });
        }
        return true;
      });
      return true;
    });

    this.reset();
    this.loading = true;
    this.fetchData();
  }

  // Check data and returns its boolean value
  public isChecked(value: boolean | undefined | null) {
    return Boolean(value);
  }

  // Difine actions for user inputs in Quick serach in filter modal.
  public searchFilterOption(value: string, keyValue: 'state' | 'department') {
    this.businessDetails[keyValue].forEach((item, itemIndex) => {
      if (`${item[keyValue]}`.toLowerCase().includes(value.toLowerCase())) {
        this.businessDetails[keyValue][itemIndex].isVisible = true;
      } else {
        this.businessDetails[keyValue][itemIndex].isVisible = false;
      }
    });
  }

  // Returns departments data by filtering based on selected states and search keyword.
  public filterDepartments(arr: DepartmentType[]) {
    const checkedstates = this.businessDetails.state.filter(
      (item) => item.isChecked
    );

    if (checkedstates.length) {
      const checkedStateList = checkedstates.map((subItem) => subItem.state);
      const departments = this.originalData
        .filter((item) => checkedStateList.includes(item.location))
        .map((item) => item.department);
      return arr.filter(
        (item) =>
          (item?.isVisible === undefined || item?.isVisible === true) &&
          departments.includes(item.department)
      );
    } else
      return arr.filter(
        (item) => item?.isVisible === undefined || item?.isVisible === true
      );
  }

  // Define action for user inputs in Search Driver field.
  public searcherInput(value: string) {
    this.query.filters!.search = value;

    // It fetech data after 0.5 seconds when user input stops
    if (this.searchCooldown) clearTimeout(this.searchCooldown);
    this.searchCooldown = setTimeout(() => {
      this.reset();
      this.loading = true;
      this.fetchData();
    }, 500);
  }

  // Remove all filters including search keyword in Search Driver field.
  public handleClearAllFilters() {
    this.filteredData = {
      state: [],
      department: [],
      clockInCheck: [],
      clockOutCheck: [],
      actions: [],
      shiftDefinition: [],
      regional: [],
      tolls: [],
      paid: [],
      checked: [],
    };
    this.query.filters!.search = '';

    Object.keys(this.businessDetails).forEach((item) => {
      this.businessDetails[item as FilterType] = this.businessDetails[
        item as FilterType
      ].map((item: any) => ({
        ...item,
        isChecked: false,
      }));
    });

    this.fromDate = new Date();
    this.toDate = new Date();
    this.getFromToDates(this.defaultDay);
    this.reset();
    this.loading = true;
    this.fetchData();
  }

  // Clear all checks from filter modal
  public handleClearAllChecked() {
    Object.keys(this.businessDetails).forEach((item) => {
      this.businessDetails[item as FilterType] = this.businessDetails[
        item as FilterType
      ].map((item: any) => ({
        ...item,
        isChecked: false,
      }));
    });
  }

  // Set isChecked value based on user control in filter modal for state and department checkboxes.
  public setFilters(checked: boolean, key: FilterType, value: DepartmentType) {
    const selectedIndex = this.businessDetails[key].findIndex(
      (item) => item[key] === value[key]
    );
    this.businessDetails[key][selectedIndex].isChecked = checked;
  }

  public checkForFilter() {
    let clearFilter = false;

    Object.keys(this.filteredData).forEach((keyValue) => {
      clearFilter =
        Boolean(this.filteredData[keyValue as FilterType].length) ||
        clearFilter;

      return true;
    });
    clearFilter = clearFilter || Boolean(this.query.filters!.search);
    return clearFilter;
  }

  public handleCreateNewTimesheet() {
    this.modalStatus = 'OPEN_CREATION';
    this.initializeForm();

    const offerDate = moment(new Date()).format('DD/MM/YYYY');
    this.timesheetCreationForm.get('date')?.setValue(offerDate);
  }

  public handleSort(colValue: any) {
    this.sortObj[this.sortedColumn.columnName as keyof TimesheetSortObj] =
      !this.sortObj[this.sortedColumn.columnName as keyof TimesheetSortObj];

    const index = this.sortOrder.findIndex(
      (item) => item === this.sortedColumn.columnName
    );
    this.sortOrder = [
      ...this.sortOrder.slice(0, index),
      ...this.sortOrder.slice(index + 1),
    ];
    this.sortOrder.push(this.sortedColumn.columnName as keyof TimesheetSortObj);
    this.fetchData(false,false,false,colValue);
  }

  public closeModal() {
    this.modalStatus = 'NONE';
    this.excelDataErrors = [];
    this.seekedDepartments = [];
    this.importCompletion = { error: 0, success: 0, total: 0 };
  }

  public reset() {
    this.query.page = 1;
  }

  public replaceEmplyee(text: string, replaceText: string) {
    return text.replace(/employee/gi, replaceText);
  }

  public getBreaks(diffs: number) {
    if (diffs >= 660) return 60;
    if (diffs >= 480) return 30;
    if (diffs >= 330) return 15;
    return 0;
  }

  public getDiffsInMinutes(
    timestring1: string,
    timestring2: string,
    format = 'HH:mm',
    payStart: string = ""
  ) {
    const time1 = moment(timestring1, format);
    const time2 = moment(timestring2, format);

    if (payStart) {
      /* Graveyard shift only */
      const date = moment(payStart).format('YYYY-MM-DD');
      const ps = moment(date + ' ' + time1.format('HH:mm'));
      let pe = moment(date + ' ' + time2.format('HH:mm'));
      console.log(payStart);
      if (moment(ps).isBefore(pe) == false) {
        /* Add 1 day if Pay In is greater than Pay Out */
        const payEnd = moment(pe.add(1, 'days').format('YYYY-MM-DD HH:mm'));
        const diff2 = payEnd.diff(ps, 'minutes');
        return diff2;
      }
      /* End of Graveyard shift only */
    }

    const diffs = time2.diff(time1, 'minutes');
    return diffs;
  }

  public isEditable(timeString: string, format: string, givenOffset: number) {
    const momentObj = moment(timeString, format);
    const currentTime = moment();
    momentObj
      .subtract(givenOffset - moment().utcOffset(), 'minutes')
      .add(14, 'hours');
    return momentObj.isBefore(currentTime);
  }

  checkArrayContainsAll(array1: string[], array2: string[]) {
    return _.every(array2, (item) => _.includes(array1, item));
  }

  getFromToDates(givenDay: string) {
    switch (givenDay) {
      case 'yesterday':
        this.fromDate.setDate(this.fromDate.getDate() - 1);
        this.toDate.setDate(this.toDate.getDate() - 1);
        break;
      case 'last_week':
        const currentDate = moment();
        currentDate.isoWeekday(1);

        // Subtract 1 week to get the previous Monday
        const previousMonday = currentDate.clone().subtract(1, 'week');

        // Add 6 days to get the previous Sunday
        const previousSunday = previousMonday.clone().add(6, 'days');
        this.fromDate = previousMonday.toDate();
        this.toDate = previousSunday.toDate();
        break;

      default:
        break;
    }
  }

  openPaymentConfirmation() {
    this.getPastWeekRange();
    this.modalStatus = 'PAYMENT_CONFIRMATION';
  }
  processPayments() {
    this.loading = true;
    this.fetchData(false, true);
    this.closeModal();
  }

  generateInvoice(timesheet: Timesheet) {
    const {
      id,
      CON,
      Date: timesheet_date,
      Department,
      JobStart,
      Driver,
      Role,
      PayIn,
      PayOut,
      Breaks,
      AttemptedDeliveriesPaid,
      Pickups,
      Location,
      User_ID,
      Payrates,
      Status,
      ClientLoadingRates,
      ShiftDefinition,
      Regional,
      ClientDetail,
    } = timesheet;

    const shiftDef = getShiftDef(
      timesheet.ShiftDefinition as SHIFT_DEFINITIONS
    );

    const invoiceRow: DriverInvoiceType & {
      FuelBonus: number;
      _Date: string;
      shiftDef: string;
      type: string;
      _type: string;
    } = {
      ContactName: CON,
      DriverName: Driver,
      InvoiceNumber: '',
      InvoiceDate: '',
      DueDate: '',
      Description: '',
      Quantity: 0,
      UnitAmount: 0,
      AccountCode: ClientDetail.account_code,
      ClientDetail,
      TaxType: 'GST On Expenses',
      TrackingName: 'Outside Hire',
      TrackingOption: Location,
      Currency: 'AUD',
      User_ID,
      Role,
      Date: timesheet_date,
      _Date: moment(timesheet_date, 'DD/MM/YYYY').format('YYYY/MM/DD'),
      JobStart,
      ShiftDefinition,
      shiftDef,
      id,
      Location,
      Status,
      Department,
      Payrates,
      Regional,
      PayIn,
      PayOut,
      ClientLoadingRates,
      FuelBonus: 0,
      type: 'A',
      _type: 'A',
    };

    invoiceRow.InvoiceDate = moment(this.payRange.endRange).format(
      'DD/MM/YYYY'
    );
    invoiceRow.DueDate = invoiceRow.InvoiceDate;
    invoiceRow.InvoiceNumber = invoiceRow.InvoiceDate + invoiceRow.ContactName;
    const entities = [];

    const netHours = (parseFloat(timesheet.NetHours));
    if (Status === SHIFT_TYPE.Normal || Status === SHIFT_TYPE.Unpaid) {
      switch (shiftDef) {
        case 'dedi':
          const dddriverSprint = (ClientDetail?.dedi?.dedi_minimums?.driver_sprint) ? ClientDetail.dedi.dedi_minimums.driver_sprint : 0;
          const dddriverMarathon = (ClientDetail?.dedi?.dedi_minimums?.driver_marathon) ? ClientDetail.dedi.dedi_minimums.driver_marathon : 0;
          const ddminimumDriverPayTime = (timesheet.ShiftDefinition.toLowerCase() == "sprint") ? dddriverSprint : dddriverMarathon;
          entities.push({
            Description: `${timesheet_date} Services provided to ${Department} in a ${Role} from ${PayIn} to ${PayOut} with ${
              (Breaks ?? 0) > 0 ? `${Breaks} minutes break.` : 'no break.'
            }`,
            Quantity: ((netHours) < ddminimumDriverPayTime) ? ddminimumDriverPayTime : netHours,
            UnitAmount: Number(
              Payrates?.overridden?.dedi_rate ??
                Payrates?.default?.dedi_rate ??
                0
            ).toFixed(3),
          });
          break;
        case 'setRun':
          entities.push({
            Description: `${timesheet_date} Set Run services provided to ${Department} in a ${Role}`,
            Quantity: 1,
            UnitAmount: '0.000',
          });
          break;
        case 'flexi':
          var flexiRole = timesheet.Role;
          var toCheck40 = /(?:^|\W)FLEXI 40(?:$|\W)/;
          var toCheck60 = /(?:^|\W)FLEXI 60(?:$|\W)/;
          const flexiMinimumSprint = ClientDetail?.flexi.flexi_minimums?.driver_sprint ? ClientDetail.flexi.flexi_minimums.driver_sprint : 0;
          const flexiMinimumMarathon = ClientDetail?.flexi.flexi_minimums?.driver_marathon ? ClientDetail.flexi.flexi_minimums.driver_marathon : 0;
          const fxminimumDriverPayTime = (toCheck40.test(flexiRole)) ? flexiMinimumSprint : (toCheck60.test(flexiRole) ? flexiMinimumMarathon : 0);
          entities.push({
            Description: `${timesheet_date} Services provided to ${Department} in a ${Role}, Deliveries Attempted`,
            Quantity: (AttemptedDeliveriesPaid < fxminimumDriverPayTime) ? fxminimumDriverPayTime : AttemptedDeliveriesPaid,
            UnitAmount: Number(
              Payrates?.overridden?.flexi_delivery ??
                Payrates?.default?.flexi_delivery ??
                0
            ).toFixed(3),
          });
          if (Pickups > 0)
            entities.push({
              Description: `${timesheet_date} Services provided to ${Department} in a ${Role}, Pickups`,
              Quantity: Pickups,
              UnitAmount: Number(
                Payrates?.overridden?.flexi_pickup ??
                  Payrates?.default?.flexi_pickup ??
                  0
              ).toFixed(3),
            });
          break;

        default:
          break;
      }
    }
    if (Status === SHIFT_TYPE.Cancelled) {
      switch (shiftDef) {
        case 'dedi':
          entities.push({
            Description: `${timesheet_date} Cancellation of work at ${Department} in a ${Role}`,
            Quantity: parseFloat(timesheet.NetHours) || 0,
            UnitAmount: Number(
              Payrates?.overridden?.dedi_rate ??
                Payrates?.default?.dedi_rate ??
                0
            ).toFixed(3),
          });
          break;
        case 'setRun':
          entities.push({
            Description: `${timesheet_date} Cancellation of Set Run services at ${Department} in a ${Role}`,
            Quantity: 1,
            UnitAmount: '0.000',
          });
          break;
        case 'flexi':
          entities.push({
            Description: `${timesheet_date} Cancellation of work at ${Department} in a ${Role}`,
            Quantity: getFLEXIQuantity(Role) / 2,
            UnitAmount: Number(
              Payrates?.overridden?.flexi_delivery ??
                Payrates?.default?.flexi_delivery ??
                0
            ).toFixed(3),
          });
          break;

        default:
          break;
      }
    }
    return entities.map((entity) => ({ ...invoiceRow, ...entity }));
  }

  public getFuelBonus(
    data: DriverInvoiceType[]
  ): (DriverInvoiceType & { type: string })[] {
    if(data?.[0]?.ShiftDefinition === "FLEXI" || data?.[0]?.ShiftDefinition === "SET_RUN") {
      return [];
    }
    const grouped = _.groupBy(data, 'Department');
    return Object.keys(grouped).flatMap((group) => {
      const subGrouped = _.groupBy(grouped[group], '_type');
      const { Payrates } = grouped[group][0];
      let UnitAmount = 0;
      if (subGrouped?.['A']?.length)
        UnitAmount =
          UnitAmount +
          (_.sumBy(subGrouped['A'], 'Quantity') *
            (Number(Payrates?.default?.fuel_levy ?? 0) *
              Number(Payrates?.default?.dedi_rate ?? 0))) /
            100;
      if (subGrouped?.['B']?.length)
        UnitAmount =
          UnitAmount +
          (_.sumBy(subGrouped['B'], 'UnitAmount') *
            Number(Payrates?.default?.fuel_levy ?? 0)) /
            100;
      if (UnitAmount > 0)
        return [
          {
            ...grouped[group][0],
            Description: `Fuel Bonus for ${grouped[group][0].Role} at ${grouped[group][0].Department}`,
            Quantity: 1,
            UnitAmount: UnitAmount.toFixed(3),
            type: 'C',
          },
        ];
      return [];
    });
  }

  public applyLoadingCharge(data: DriverInvoiceType) {
    const shiftDef = getShiftDef(
      data.ShiftDefinition as SHIFT_DEFINITIONS
    );
    const { Date, Department } = data;
    const applyLoadingCharges: (DriverInvoiceType & { _type: string })[] = [];
    if (data.ClientLoadingRates?.[shiftDef]) {
      const clientLoadingRate = data.ClientLoadingRates?.[
        shiftDef
      ] as ClientLoadingRateType;
      loading_charges.forEach((loadingType) => {
        const charge4Client = this.getLoadingChargeForClient(
          loadingType,
          clientLoadingRate
        );
        const isApplicable = this.loadingChargeApplicable(data, loadingType);
        if (isApplicable && !!charge4Client) {
          const { type } = charge4Client;
          const charge4Location = this.getLoadingChargeForLocation(
            loadingType,
            data.Location as string
          );
          if (!charge4Location || !charge4Location?.[type]) return true;
          if ((charge4Client?.[type] as number) >= charge4Location[type]) {
            const _charge4Location = charge4Location[type];
            const _description = this.getLoadingDescription(
              type,
              _charge4Location
            );
            let UnitAmount = 0;
            let _Quantity = Number(data.Quantity);
            if (loadingType === 'After Hours') {
              _Quantity = this.getOverlappedHours(
                {
                  from: charge4Client.from,
                  to: charge4Client.to,
                },
                {
                  from: data.PayIn as string,
                  to: data.PayOut as string,
                },
                'hh:mm a',
                'HH:mm',
                true
              ).hours;
              if (_Quantity <= 0) return true;
            }
            if (type === 'percentage') {
              UnitAmount =
                (_Quantity * Number(data.UnitAmount) * _charge4Location) / 100;
            } else {
              UnitAmount = _Quantity * _charge4Location;
            }
            const invoice: any = {
              Description: `${Date} ${loadingType} Loading of ${_description} at ${Department}`,
              Quantity: 1,
              UnitAmount: Number(UnitAmount.toFixed(3)),
            };
            applyLoadingCharges.push({
              ...data,
              ...invoice,
              type: 'A',
              _type: 'B',
            });
          }
        }
        return true;
      });
    }
    return applyLoadingCharges;
  }

  public applyLoadingCharge_v2(data: ClientInvoiceType) {
    const shiftDef = getShiftDef(
      data.ShiftDefinition as SHIFT_DEFINITIONS
    );
    const { Date, Department } = data;
    const applyLoadingCharges: (ClientInvoiceType & {
      _Date: string;
      type: string;
      _type: string;
      shiftDef: string;
      charging_rate?: ClientChargingType;
    })[] = [];
    if (data.ClientLoadingRates?.[shiftDef]) {
      const clientLoadingRate = data.ClientLoadingRates?.[
        shiftDef
      ] as ClientLoadingRateType;
      loading_charges.forEach((loadingType) => {
        const charge4Client = this.getLoadingChargeForClient(
          loadingType,
          clientLoadingRate
        );
        const isApplicable = this.loadingChargeApplicable(data, loadingType);
        if (isApplicable && !!charge4Client) {
          const { type } = charge4Client;
          if (!!charge4Client?.[type]) {
            const _charge4Client = charge4Client[type] as number;
            const _description = this.getLoadingDescription(
              type,
              _charge4Client
            );
            let UnitAmount = 0;
            let _Quantity = Number(data.Quantity);
            if (loadingType === 'After Hours') {
              _Quantity = this.getOverlappedHours(
                {
                  from: charge4Client.from,
                  to: charge4Client.to,
                },
                {
                  from: data.PayIn as string,
                  to: data.PayOut as string,
                },
                'hh:mm a',
                'HH:mm',
                true
              ).hours;
              if (_Quantity <= 0) return true;
            }
            if (type === 'percentage') {
              UnitAmount =
                (_Quantity * Number(data.UnitAmount) * _charge4Client) / 100;
            } else {
              UnitAmount = _Quantity * _charge4Client;
            }
            let InventoryItemCode = '';
            const found = this.loading_charge.find(
              (item) => item.name === loadingType
            );
            InventoryItemCode = found?.inventory_code ?? '';

            const invoice: any = {
              Description: `${data.ClientDetail.invoice_prefix}${Date} ${loadingType} Loading of ${_description} at ${Department}`,
              Quantity: 1,
              UnitAmount: Number(UnitAmount.toFixed(3)),
              InventoryItemCode,
            };
            applyLoadingCharges.push({
              ...data,
              ...invoice,
              type: 'A',
              _type: 'B',
            });
          }
        }
        return true;
      });
    }
    return applyLoadingCharges;
  }

  public getOverlappedHours(
    time1: TimePairType,
    time2: TimePairType,
    format1: string,
    format2: string,
    justHours = false
  ) {
    const from1 = moment(time1.from, format1);
    const to1 = moment(time1.to, format1);
    const from2 = moment(time2.from, format2);
    const to2 = moment(time2.to, format2);

    if (to1.isBefore(from1)) {
      to1.add(1, 'day'); // Add one day to "to" time if it's before "from" time
    }

    const overlapFrom = moment.max(from1, from2);
    const overlapTo = moment.min(to1, to2);

    const overlapDuration = moment.duration(overlapTo.diff(overlapFrom));
    const overlapHours = overlapDuration.hours();
    const overlapMinutes = overlapDuration.minutes();

    return {
      hours: justHours
        ? overlapHours + Number((overlapMinutes / 60).toFixed(2))
        : overlapHours,
      minutes: overlapMinutes,
    };
  }

  private getLoadingDescription(type: string, value: number) {
    return type === 'percentage' ? `${value}%` : `$${value} per hour`;
  }

  private loadingChargeApplicable(
    data: DriverInvoiceType | ClientInvoiceType,
    loadingType: (typeof loading_charges)[number]
  ) {
    switch (loadingType) {
      case 'Regional':
        return data.Regional !== 'No';
      case 'Saturday':
        return this.getDayOfWeek(data.Date as string) === 'Saturday';
      case 'Sunday':
        return this.getDayOfWeek(data.Date as string) === 'Sunday';
      case 'Public Holiday':
        return this.holidayCheck(data.Date as string, data.Location as string);
      case 'Tailgate':
        return data.Role?.includes(' TG ');
      case 'After Hours':
        return true;
    }
  }

  private getLoadingChargeForClient(
    loadingType: (typeof loading_charges)[number],
    clientRate: ClientLoadingRateType
  ) {
    switch (loadingType) {
      case 'Regional':
        return this.judgeChargingType(clientRate[0]);
      case 'Saturday':
        return this.judgeChargingType(clientRate[1]);
      case 'Sunday':
        return this.judgeChargingType(clientRate[2]);
      case 'Public Holiday':
        return this.judgeChargingType(clientRate[3]);
      case 'Tailgate':
        return this.judgeChargingType(clientRate[4]);
      case 'After Hours':
        return this.judgeChargingType(clientRate[5]);
      default:
        return null;
    }
  }

  private judgeChargingType(
    rate: Partial<LoadingRatesType> & {
      from: string;
      to: string;
    }
  ):
    | (Partial<LoadingRatesType> & {
        from: string;
        to: string;
        type: 'percentage' | 'fixed_rate';
      })
    | null {
    if (rate?.percentage) return { ...rate, type: 'percentage' };
    if (rate?.fixed_rate) return { ...rate, type: 'fixed_rate' };
    return null;
  }

  private getLoadingChargeForLocation(
    loadingType: (typeof loading_charges)[number],
    location: string
  ) {
    const theOne = this.loading_rates.find((item) => item.name === location);
    if (theOne) {
      const theChargeFor = theOne.loading_rates?.find(
        (rate) => rate.loading_charge === loadingType
      );
      if (theChargeFor) return theChargeFor;
    }
    return false;
  }

  public handleGenerateInvoice() {
    this.modalStatus = 'GENERATE_INVOICE_FILE';
    this.payRange.startRange = null;
    this.payRange.endRange = null;
  }

  private generateInvoice_v2(
    timesheet: Timesheet,
    invoiceNumber: number,
    sunday: string
  ) {
    const {
      id,
      CON,
      Driver,
      Date: timesheet_date,
      Department,
      JobStart,
      Role,
      PayIn,
      PayOut,
      Breaks,
      AttemptedDeliveriesPaid,
      Pickups,
      Location,
      User_ID,
      Payrates,
      Status,
      ClientLoadingRates,
      ShiftDefinition,
      Regional,
      ClientDetail,
      ClientChargingRates,
      SprintHours,
      MarathonHours,
    } = timesheet;

    const shiftDef = getShiftDef(
      timesheet.ShiftDefinition as SHIFT_DEFINITIONS
    );

    const invoiceRow: ClientInvoiceType & {
      _Date: string;
      type: string;
      _type: string;
      shiftDef: string;
      charging_rate?: ClientChargingType;
    } = {
      ContactName: ClientDetail.entity_name || ClientDetail.name,
      DriverName: Driver,
      InvoiceNumber: `INV-${invoiceNumber}`,
      InvoiceDate: '',
      DueDate: '',
      Description: '',
      Quantity: 0,
      UnitAmount: 0,
      AccountCode: ClientDetail.invoice_code,
      ClientDetail,
      TaxType: 'GST On Income',
      Reference: '',
      InventoryItemCode: '',
      TrackingName1: 'Business Segment',
      TrackingOption1: 'Outside Hire',
      TrackingName2: 'Geography',
      TrackingOption2: Location,
      Currency: 'AUD',
      User_ID,
      Date: timesheet_date,
      Role,
      _Date: moment(timesheet_date, 'DD/MM/YYYY').format('YYYY/MM/DD'),
      JobStart,
      ShiftDefinition,
      shiftDef,
      id,
      Location,
      Status,
      Department,
      Payrates,
      Regional,
      PayIn,
      PayOut,
      ClientLoadingRates,
      ClientChargingRates,
      type: 'A',
      _type: 'A',
    };

    invoiceRow.InvoiceDate = moment().format('DD/MM/YYYY');
    invoiceRow.DueDate = moment(invoiceRow.InvoiceDate, 'DD/MM/YYYY')
      .add(Number(ClientDetail.payment_terms) ?? 0, 'days')
      .format('DD/MM/YYYY');
    // invoiceRow.InvoiceNumber = invoiceRow.InvoiceDate + invoiceRow.ContactName;
    // !
    invoiceRow.Reference = `WE ${sunday} - ${Department}`;

    // !
    const driver_role = this.driver_roles.find((item) => item.name === Role);
    invoiceRow.InventoryItemCode = driver_role?.inventory_code ?? '';

    const entities = [];

    const charging_rate = ClientChargingRates[shiftDef]?.find(
      (item) => item.role === Role
    );

    invoiceRow.charging_rate = charging_rate;

    ClientDetail.invoice_prefix = !!ClientDetail.invoice_prefix
      ? `${ClientDetail.invoice_prefix} `
      : '';

      if (Status === SHIFT_TYPE.Normal || Status === SHIFT_TYPE.Unpaid) {
      switch (shiftDef) {
        case 'dedi':
          let minimum = 0;
          if (timesheet.ShiftDefinition === SHIFT_DEFINITIONS.SPRINT)
            minimum = SprintHours;
          else minimum = MarathonHours;
          let netHours = parseFloat(timesheet.NetHours) || 0;
          entities.push({
            Description: `${
              ClientDetail.invoice_prefix
            }${timesheet_date} Services provided to ${Department} by ${Driver} in a ${Role} from ${PayIn} to ${PayOut} with ${
              (Breaks ?? 0) > 0 ? `${Breaks} minutes break.` : 'no break.'
            }`,
            Quantity: minimum < netHours ? netHours : minimum,
            UnitAmount: Number(charging_rate?.rate ?? 0),
          });
          break;
        case 'setRun':
          entities.push({
            Description: `${ClientDetail.invoice_prefix}${timesheet_date} Set Run services provided to ${Department} by ${Driver} in a ${Role}`,
            Quantity: 1,
            UnitAmount: Number(charging_rate?.rate ?? 0),
          });
          break;
        case 'flexi':
          entities.push({
            Description: `${ClientDetail.invoice_prefix}${timesheet_date} Services provided to ${Department} by ${Driver} in a ${Role}, Deliveries Attempted`,
            Quantity: AttemptedDeliveriesPaid,
            UnitAmount: Number(charging_rate?.rate ?? 0),
            shiftDef: 'flexi_d',
          });
          if (Pickups > 0)
            entities.push({
              Description: `${ClientDetail.invoice_prefix}${timesheet_date} Services provided to ${Department} by ${Driver} in a ${Role}, Pickups`,
              Quantity: Pickups,
              UnitAmount: Number(charging_rate?.rate2 ?? 0),
              shiftDef: 'flexi_p',
            });
          break;

        default:
          break;
      }
    }
    if (Status === SHIFT_TYPE.Cancelled) {
      switch (shiftDef) {
        case 'dedi':
          entities.push({
            Description: `${ClientDetail.invoice_prefix}${timesheet_date} Cancellation of work at ${Department}, ${Driver} in a ${Role}`,
            Quantity: 4,
            UnitAmount: Number(charging_rate?.rate ?? 0),
          });
          break;
        case 'setRun':
          entities.push({
            Description: `${ClientDetail.invoice_prefix}${timesheet_date} Cancellation of Set Run services at ${Department}, ${Driver} in a ${Role}`,
            Quantity: 1,
            UnitAmount: Number(charging_rate?.rate ?? 0),
          });
          break;
        case 'flexi':
          entities.push({
            Description: `${ClientDetail.invoice_prefix}${timesheet_date} Cancellation of work at ${Department}, ${Driver} in a ${Role}`,
            Quantity: getFLEXIQuantity(Role) / 2,
            UnitAmount: Number(charging_rate?.rate ?? 0),
            shiftDef: 'flexi_d',
          });
          break;

        default:
          break;
      }
    }
    return entities.map((entity) => ({ ...invoiceRow, ...entity }));
  }

  private getFuelSurcharge(
    data: (ClientInvoiceType & { charging_rate?: ClientChargingType })[]
  ): (ClientInvoiceType & { type: string })[] {
    if(data?.[0]?.ShiftDefinition === "FLEXI" || data?.[0]?.ShiftDefinition === "SET_RUN") {
      return [];
    }
    const { charging_rate, ClientDetail } = data[0];
    if (!!charging_rate?.fuel) {
      const grouped = _.groupBy(data, '_type');
      let UnitAmount = 0;
      if (grouped?.['A']?.length)
        UnitAmount =
          UnitAmount +
          (_.sumBy(grouped['A'], 'Quantity') *
            (Number(charging_rate?.rate ?? 0) *
              Number(charging_rate?.fuel ?? 0))) /
            100;
      if (grouped?.['B']?.length)
        UnitAmount =
          UnitAmount +
          (_.sumBy(grouped['B'], 'UnitAmount') *
            Number(charging_rate?.fuel ?? 0)) /
            100;

      return [
        {
          ...data[0],
          Description: `${
            ClientDetail.invoice_prefix
          }Fuel Surcharge of ${Number(charging_rate?.fuel).toFixed(2)}% for ${
            data[0].Role
          } at ${data[0].Department}`,
          Quantity: 1,
          InventoryItemCode: 'FSC',
          UnitAmount: UnitAmount.toFixed(3),
          type: 'C',
        },
      ];
    }
    return [];
  }

  generateClientInvoice() {
    const checkedOnes = this.seekedDepartments.filter((item) => item.isChecked);
    if (!checkedOnes.length)
      return NotificatorPartial.push({
        type: 'error',
        message: 'Need to select 1 department at least',
        timeout: 3000,
      });
    this.loading = true;
    this.fetchData(false, true, true);
    this.closeModal();
  }

  private getDayOfWeek(dateString: string) {
    return moment(dateString, 'DD/MM/YYYY').format('dddd');
  }

  private holidayCheck(dateString: string, location: string) {
    const formattedDate = moment(dateString, 'DD/MM/YYYY').format('MM/DD/YYYY');
    const holiday = this.holidays.find(
      (_holiday) =>
        _holiday.date === formattedDate &&
        (_holiday.type === 'National' ||
          (_holiday.type === 'Local holiday' &&
            _holiday._location.includes(location)))
    );
    return Boolean(holiday);
  }

  public getWeekRange(inputDate: Date) {
    const selectedDate = moment(inputDate);
    const monday = selectedDate.isoWeekday(1).toDate();
    const sunday = selectedDate.isoWeekday(7).toDate();
    return { monday, sunday };
  }

  public getPastWeekRange() {
    const currentDate = moment();
    currentDate.isoWeekday(1);

    // Subtract 1 week to get the previous Monday
    const previousMonday = currentDate.clone().subtract(1, 'week');

    // Add 6 days to get the previous Sunday
    const previousSunday = previousMonday.clone().add(6, 'days');
    this.payRange.startRange = previousMonday.toDate();
    this.payRange.endRange = previousSunday.toDate();
  }

  public isAfterToday(date: Date | null) {
    const today = moment().endOf('day');
    const givenDay = moment(date).add(7, 'days');
    return givenDay.isAfter(today);
  }

  public rangeDescriptionGen() {
    const start = moment(this.payRange.startRange);
    const end = moment(this.payRange.endRange);
    const _month = start.format('MMMM'); // Month
    const _date = start.date(); // Day of the month (1-31)
    const _year = start.year();
    const month = end.format('MMMM'); // Month
    const date = end.date(); // Day of the month (1-31)
    const year = end.year();
    return `${_date} ${_month === month ? '' : _month} ${
      _year === year ? '' : _year
    } - ${date} ${month} ${year}`;
  }

  public previousWeek() {
    this.payRange.startRange = moment(this.payRange.startRange)
      .subtract(1, 'week')
      .toDate();
    this.payRange.endRange = moment(this.payRange.endRange)
      .subtract(1, 'week')
      .toDate();
  }
  public nextWeek() {
    this.payRange.startRange = moment(this.payRange.startRange)
      .add(1, 'week')
      .toDate();
    this.payRange.endRange = moment(this.payRange.endRange)
      .add(1, 'week')
      .toDate();
  }

  public get maxPage() {
    return Math.ceil(this.total / (this.query.perPage ?? 20));
  }

  public setLastWeek(
    checked: boolean,    
  ) {
    if(checked) {
      this.getPastWeekRange();
      this.seekEligible();
    } else {
      this.payRange.startRange = null;
      this.payRange.endRange = null;
      this.seekedDepartments = [];
    }
  }

  getPayments(timesheets: Timesheet[], flag: boolean) {
    const invoices = timesheets
      .flatMap((timesheet) => {
        if (!flag && timesheet.Authorised)
          return [this.generateInvoice(timesheet)];
        if (flag && timesheet.Paid && timesheet.Authorised)
          return [this.generateInvoice(timesheet)];
        return [];
      })
      .flat();
    const normal_dedi_invoices: typeof invoices = [];
    const dedi_invoices = invoices.flatMap((invoice) => {
      if (invoice.shiftDef === 'dedi') {
        if (invoice.Status === SHIFT_TYPE.Normal)
          normal_dedi_invoices.push(invoice);
        return [invoice];
      }
      return [];
    });

    const loadingCharge = invoices
      .map((invoice) => this.applyLoadingCharge(invoice))
      .flat();
    const grouped = _.groupBy(
      [...dedi_invoices, ...loadingCharge],
      'ContactName'
    );
    const fuelBonuses = Object.keys(grouped)
      .map((keyValue) => {
        const _grouped = _.groupBy(grouped[keyValue], 'Role');
        return Object.keys(_grouped)
          .map((_keyValue) => this.getFuelBonus(_grouped?.[_keyValue]))
          .flat();
      })
      .flat();

    const _grouped = _.groupBy(normal_dedi_invoices, 'ContactName');
    const _fuelBonuses = Object.keys(_grouped)
      .map((keyValue) => {
        const __grouped = _.groupBy(_grouped[keyValue], 'Role');
        return Object.keys(__grouped)
          .map((_keyValue) => this.getFuelBonus(__grouped?.[_keyValue]))
          .flat();
      })
      .flat();
    const grouped4AdminFee = _.groupBy(
      [
        ...[...invoices, ...loadingCharge].filter(
          (invoice) => invoice.Status === SHIFT_TYPE.Normal
        ),
        ..._fuelBonuses,
      ],
      'ContactName'
    );

    const adminFees = Object.keys(grouped4AdminFee)
      .map((groupKey) => {
        const groupedByDepartment = _.groupBy(
          grouped4AdminFee[groupKey],
          'Department'
        );
        return Object.keys(groupedByDepartment).map((department) => {
          const fee = groupedByDepartment[department].reduce(
            (total, currentValue) =>
              (total -=
                Number(currentValue.Quantity) *
                Number(currentValue.UnitAmount)),
            0
          );
          return {
            ...groupedByDepartment[department][0],
            Description: `Administration Fee for ${groupedByDepartment[department][0].Department}`,
            // Quantity: quantity,
            Quantity: 1,
            UnitAmount: (fee * 0.015).toFixed(3),
            // UnitAmount: -0.67,
            type: 'D',
          };
        });
      })
      .flat();
    return {
      invoices: invoices,
      loadingCharge: loadingCharge,
      fuelBonuses: fuelBonuses,
      adminFees: adminFees
    }
  }
}
